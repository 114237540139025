import React from "react";

const CreateJob = React.lazy(() => import('../screens/Company/CreateJob'))
const SuccessJob = React.lazy(() => import('../screens/Company/SuccessJob'))
const EmployeeProfile = React.lazy(() => import('../screens/Company/Dashboard/EmployeeProfile'))
const CompanySetting = React.lazy(() => import('../screens/Company/Setting'))
const EmployeeChats = React.lazy(() => import('../screens/Employee/Chats/Chats'))
const EmployeeSetting = React.lazy(() => import('../screens/Employee/Setting'))
const Video = React.lazy(() => import('../screens/Employee/Video'))
const ViewJob = React.lazy(() => import('../screens/Employee/ViewJob'))
const EmployeeTab = React.lazy(() => import('../screens/Employee/Candidates/EmployeeTab'))
const CompanyTab = React.lazy(() => import('../screens/Company/Candidates/CompanyTab'))
const ScheduleInterview = React.lazy(() => import('../screens/Company/Interviews/ScheduleInterview'))
const Jobs = React.lazy(() => import('../screens/Company/Jobs/Jobs'))
const Job = React.lazy(() => import('../screens/Company/Jobs/Job'))
const JobDetails = React.lazy(() => import('../screens/Company/Jobs/JobDetails'))
const Chats = React.lazy(() => import('../screens/Company/Chats/Chats'))

const Dashboard = React.lazy(() => import('../screens/Company/Dashboard/Dashboard'))
const EmployeeDashboard = React.lazy(() => import('../screens/Employee/Dashboard'))

export const homeRoutesCompany = [
    // {
    //     path: '/register',
    //     component: CompanyRegister,
    // },
    {
        path: '/:shareId?',
        component: Dashboard,
    },
    {
        path: '/setting',
        component: CompanySetting,
    },
    {
        path: '/employee-profile/:id',
        component: EmployeeProfile,
    },
    {
        path: '/create-job',
        component: CreateJob,
    },
    {
        path: '/success-job',
        component: SuccessJob,
    },
    {
        path: '/candidates',
        component: CompanyTab,
    },
    {
        path: '/schedule-interview',
        component: ScheduleInterview,
    },
    {
        path: '/jobs',
        component: Jobs,
    },
    {
        path: '/jobs/:id',
        component: Job,
    },
    {
        path: '/job-details/:id',
        component: JobDetails,
    },
    {
        path: '/chats',
        component: Chats,
    },
]

export const homeRoutesEmployee = [

    {
        path: '/:shareId?',
        component: EmployeeDashboard,
    },
    {
        path: '/job',
        component: ViewJob,
    },
    {
        path: '/setting',
        component: EmployeeSetting,
    },
    {
        path: '/candidates',
        component: EmployeeTab,
    },
    {
        path: '/video',
        component: Video,
    },
    {
        path: '/chats',
        component: EmployeeChats,
    },

]