import React, { useRef } from 'react';
import Button from './Button';
import { icClose, icLocation, icTime } from '../assets/images/images';
import { useMediaQuery } from 'react-responsive';
import Sheet from 'react-modal-sheet';
import moment from 'moment';

export default function InterviewDialog({ interview, firstButtonText, firstButtonHandler, onClose, secondButtonHandler, secondButtonText, cancelButtonText, cancelButtonHandler }) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
    const ref = useRef();
    return (
        <>
            <div className="mask" />
            {isTabletOrMobile ?

                <Sheet
                    ref={ref}
                    detent="content-height"
                    onBlur={() => onClose()}
                    isOpen={true}
                    onClose={() => onClose()}>
                    <Sheet.Container style={{ direction: 'rtl', height: '50vh', borderTopRightRadius: 8, borderTopLeftRadius: 8 }}>
                        <Sheet.Content>
                            <Sheet.Scroller>
                                <div>
                                    <img style={{  position: 'absolute', left: 12, }} alt="" width="24" height="24" onClick={onClose} src={icClose} />
                                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <span style={{ marginTop: 12, fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}>{'נשלח אלייך מועד לראיון!'}</span>
                                        <div style={{ alignSelf: 'center', alignItems: "flex-start" }} className='cards'>
                                            <div style={{ textAlign: 'right', }} className='d-flex flex-row align-items-start'>
                                                <div>
                                                    <img alt="icon" style={{ width: 20, height: 20 }} src={icTime} />
                                                </div>
                                                <div style={{ paddingRight: 12 }} className='d-flex flex-column'>
                                                    <span style={{ fontSize: 20 }}>{moment(interview?.date).format('HH:mm')}</span>
                                                    <span style={{ fontSize: 16, color: '#827aa5' }}>{new Date(interview?.date)?.toLocaleDateString('he-IL', { weekday: 'long', month: 'long', day: 'numeric' })}</span>
                                                    <span style={{ textDecoration: 'underline', fontSize: 14, color: '#1f0039' }}>{interview?.location?.substring(0, 45)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '95%', marginTop: 40, position: 'relative', justifyContent: 'center' }} className='d-flex row'>
                                            {firstButtonText && <Button style={{ maxWidth: '95%', width: isTabletOrMobile ? '95%' : 160, }} onPress={firstButtonHandler} text={firstButtonText} />}
                                            {secondButtonText && <Button onPress={secondButtonHandler} style={{ maxWidth: '95%', width: isTabletOrMobile ? '95%' : 160, backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} text={secondButtonText} />}
                                        </div>
                                        <span onClick={cancelButtonHandler} style={{ cursor: 'pointer', color: '#4f35be', marginTop: 22, fontSize: 16, textAlign: 'center' }}>{cancelButtonText}</span>

                                    </div>
                                </div>
                            </Sheet.Scroller>
                        </Sheet.Content>
                    </Sheet.Container>
                </Sheet>
                :
                <div className='dialog'>
                    <div style={{ width: '95%' }}>
                        <img style={{cursor:"pointer", position: 'absolute', left: 24, }} alt="" width="24" height="24" onClick={onClose} src={icClose} />
                        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span style={{ marginTop: 12, fontSize: isTabletOrMobile ? 24 : 28, fontWeight: 'bold', textAlign: 'center' }}>{'נשלח אלייך מועד לראיון!'}</span>
                            <div style={{ padding: '12px', width: '200px', height: '130px', alignSelf: 'center' }} className='cards'>
                                <div style={{ textAlign: 'right', }} className='d-flex flex-row align-items-start justify-content-start'>
                                    <div>
                                        <img alt="icon" style={{ width: 20, height: 20 }} src={icTime} />
                                    </div>
                                    <div style={{ paddingRight: 12 }} className='d-flex flex-column'>
                                        <span style={{ fontSize: 18 }}>{moment(interview?.date).format('HH:mm')}</span>
                                        <span style={{ fontSize: 14, color: '#827aa5' }}>{new Date(interview?.date)?.toLocaleDateString('he-IL', { weekday: 'long', month: 'long', day: 'numeric' })}</span>
                                    </div>
                                </div>
                                <div style={{ textAlign: 'right', }} className='d-flex flex-row align-items-start justify-content-start'>
                                    <div style={{ position: 'relative' }}>
                                        <img alt="icon" style={{ width: 20, height: 20 }} src={icLocation} />
                                    </div>
                                    <div style={{ paddingRight: 12 }} className='d-flex flex-column'>
                                        <span style={{ fontSize: 18 }}>{interview?.meetingType?.type}</span>
                                        <span style={{ fontSize: 14, color: '#827aa5' }}>{interview?.location?.substring(0, 45)}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '95%', marginTop: 40, position: 'relative', justifyContent: 'center' }} className='d-flex row'>
                                {firstButtonText && <Button style={{ maxWidth: '95%', width: isTabletOrMobile ? '95%' : 160, }} onPress={firstButtonHandler} text={firstButtonText} />}
                                {secondButtonText && <Button onPress={secondButtonHandler} style={{ maxWidth: '95%', width: isTabletOrMobile ? '95%' : 160, backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} text={secondButtonText} />}
                            </div>
                            <span onClick={cancelButtonHandler} style={{ cursor: 'pointer', color: '#4f35be', marginTop: 22, fontSize: 16, textAlign: 'center' }}>{cancelButtonText}</span>

                        </div>
                    </div>
                </div >

            }
        </>
    );

}
