// axiosInstance.js
import axios from 'axios';
import store from '../store/store';

// Create an Axios instance
const axiosInstance = axios.create({});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors
    const { dispatch } = store;
    const state = store.getState();
    if (error.response && error.response.data.message === 'Invalid token.') {
      dispatch({ type: 'signOut' });
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = state.userData?.type?.id === 3 ? '/company-login' : '/employee-login';
    }
    throw error;
  }
);

export default axiosInstance;
