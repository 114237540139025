import React from 'react';

export default function TextInput({ pattern, type, maxLength, style, value, onChange, label, security, className, onBlur }) {
    return (
        <input
            onBlur={onBlur}
            pattern={pattern}
            type={type !== undefined ? type : security ? "password" : "text"}
            style={style}
            maxLength={maxLength !== undefined ? maxLength : ""}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={className}
            placeholder={label} />
    );
}

