import React, { useEffect, useState } from 'react';
import { Header, MobileHeader } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { icClose, icCoverFlip, icJobCard, icJobCover, icStepper } from '../../../assets/images/images';
import CompanyDetails from './CompanyDetails';
import CompanyUploads from './CompanyUploads';
import { useDispatch, useSelector } from 'react-redux';
import useFetchList from '../../../hooks/useFetchList';

export default function CompanyRegister() {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const showCover = useMediaQuery({ query: '(max-width: 1007px)' });
  const currentUser = useSelector((state) => state.userData);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState(null);
  const dispatch = useDispatch();
  useFetchList();

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <CompanyDetails
            data={info}
            handleNext={(data) => {
              const tmpData = {
                ...info,
                ...data,
              };
              setInfo(tmpData);
              setStep(2);
            }}
          />
        );
      case 2:
        return (
          <CompanyUploads
            location={location}
            data={info}
            handleNext={(data) => {
              const tmpData = {
                ...info,
                ...data,
              };
              setInfo(tmpData);
              setStep(2);
            }}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    if (currentUser?.type?.id === 2) {
      dispatch({ type: 'signOut' });
    }
  });

  return (
    <div>
      {isTabletOrMobile ? <MobileHeader type={'login'} /> : <Header />}
      <div className='d-flex justify-content-start'>
        <img
          alt=''
          width='20'
          height='20'
          style={{ cursor: 'pointer', left: 27, top: 90, position: 'absolute' }}
          onClick={() => navigate('/company')}
          src={icClose}
        />
        {!showCover && (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 700,
                backgroundImage: `url(${icJobCover})`,
                backgroundRepeat: 'repeat',
              }}
            >
              {/* <div style={{  width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute' }}> */}
              <img alt='' style={{ minWidth: '100%', paddingLeft: 50 }} src={step === 1 ? icJobCard : icCoverFlip} />
            </div>
          </>
        )}
        <div className='job-screen'>
          <div className='step d-flex align-items-center'>
            {isTabletOrMobile ? (
              <img alt='' src={icStepper} />
            ) : (
              <>
                <div className='circle'>
                  <span style={{ margin: '0 auto', color: '#f7f5ff', fontWeight: 500 }}>1</span>
                </div>
                <span style={{ color: '#2f2072', fontSize: 16 }}>הרשמה</span>
                <div style={{ width: '34px' }} className='hyphen' />
                <div style={{ backgroundColor: '#f7f5ff', border: '2.2px solid #4f35be' }} className='circle'>
                  <span style={{ margin: '0 auto', color: '#4f35be', fontWeight: 500 }}>2</span>
                </div>
                <span style={{ color: '#2f2072', fontSize: 16 }}>יצירת משרה</span>
              </>
            )}
          </div>
          {renderSteps()}
        </div>
      </div>
    </div>
  );
}
