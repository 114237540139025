import React from 'react';
import Button from './Button';
import { icClose } from '../assets/images/images';
import { useMediaQuery } from 'react-responsive';
export default function Dialog({ title, text, firstButtonText, firstButtonHandler, image, onClose, subText, secondButtonHandler, secondButtonText }) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
    return (
        <>
            <div className="mask" />
            <div className='dialog'>
                <div style={{ width: '95%' }}>
                    <img style={{ cursor: "pointer", position: 'absolute', left: 24, }} alt="" width="24" height="24" onClick={onClose} src={icClose} />
                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {image && <img alt="" style={{ display: 'block', margin: '0 auto' }} src={image} />}
                        <span style={{ marginTop: 12, fontSize: isTabletOrMobile ? 24 : 28, fontWeight: 'bold', textAlign: 'center' }}>{title}</span>
                        {text && <span style={{ marginTop: 12, fontSize: isTabletOrMobile ? 18 : 20, textAlign: 'center' }}>{text}</span>}
                        {subText && <span style={{ marginTop: 8, color: '#827aa5', fontSize: isTabletOrMobile ? 18 : 18, textAlign: 'center' }}>{subText}</span>}
                        <div style={{ width: '95%', marginTop: 40, position: 'relative', justifyContent: 'center' }} className='d-flex row'>
                            {firstButtonText && <Button style={{ maxWidth: '95%', width: isTabletOrMobile ? '95%' : firstButtonText === 'אני מעוניין במועדים אחרים' ? 220 : 160, }} onPress={firstButtonHandler} text={firstButtonText} />}
                            {secondButtonText && <Button onPress={secondButtonHandler} style={{ maxWidth: '95%', width: isTabletOrMobile ? '95%' : 160, backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} text={secondButtonText} />}
                        </div>
                    </div>
                </div>
            </div >
        </>
    );

}
