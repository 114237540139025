import React from 'react';


export default function Checkbox({className, label, onChange, checked, handleClick }) {


    return (
        <div className="checkbox-wrapper">
            <div style={{display:'flex'}}>
                <input style={{border:'1px solid'}} className={checked ? "checked" : ""}
                    onChange={onChange} type="checkbox" checked={checked} />
                <span className={className} onClick={handleClick} style={{marginRight:10, fontSize:14, textAlign:'right'}}>{label}</span>
            </div>
        </div>
    );
};
