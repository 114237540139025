import React, { useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { getUserDetails, twoFactorAuth, updateUserDevice } from '../store/actions';
import Button from './Button';
import { getOS } from '../utils';
import { icClose, icError } from '../assets/images/images';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
export default function TwoFactor({ email, password, uniq, setSuccess, onClose, checked, handleSend }) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })


    const dispatch = useDispatch()
    const [cookies, setCookie] = useCookies(['userData']);

    const [code1, setCode1] = useState("");
    const [code2, setCode2] = useState("");
    const [code3, setCode3] = useState("");
    const [code4, setCode4] = useState("");
    const [code5, setCode5] = useState("");
    const [code6, setCode6] = useState("");

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()

    const [validCode, setValidCode] = useState(false)


    const handleLogin = () => {
        const code = code1 + code2 + code3 + code4 + code5 + code6
        dispatch(twoFactorAuth(code, email, password, uniq)).then(async res => {
            if (res.status === 200) {
                setCookie('userToken', res.data.result.accessToken, { path: '/', sameSite: 'None', secure: true, expires: new Date(Date.now() + 12096e5) });
                const response = await axios.get('https://api.my-ip.io/v2/ip.json')
                const formData = new FormData()
                formData.append('os', getOS())
                formData.append('ip', response.data.ip)
                formData.append('deviceId', "1")
                formData.append('pushToken', 'sometoken')
                dispatch(updateUserDevice(formData, res.data.result?.accessToken))
                dispatch(getUserDetails(res.data.result?.id, res.data.result?.accessToken)).then(response => {
                    const user = {
                        ...response.data.result,
                        token: res.data.result?.accessToken
                    }
                    checked ? localStorage.setItem('userData', JSON.stringify(user))
                        : sessionStorage.setItem('userData', JSON.stringify(user))

                }).catch(err => console.log(err))
                setSuccess(true)
            }
        }).catch(err => {
            console.log("err -->", err);
            setValidCode(true)
            setTimeout(() => {
                setValidCode(false)
            }, 3000);

        })
    }

    return (

        <div className='popup'>
            <div style={{ width: '580px' }}>
                <img alt="" style={{cursor:'pointer',}} width="24" height="24" onClick={onClose} src={icClose} />
                <p style={{ fontSize: isTabletOrMobile ? 24 : 28, fontWeight: 'bold', textAlign: 'center' }}>אימות דו-שלבי</p>
                <p style={{ fontSize: isTabletOrMobile ? 16 : 20, textAlign: 'center' }}>{`נשלח אליך מייל עם קוד אימות אל ${email}`}</p>
                <p style={{ color: '#827aa5', fontSize: isTabletOrMobile ? 14 : 18, textAlign: 'center' }}>הזן את הקוד</p>
                <div className='justify-content-center d-flex margin-0 auto align-items-center' dir='ltr'>
                    <input
                        className='two-factor'
                        ref={ref1}
                        style={{
                            border: '1px solid #d4daf8',
                            height: isTabletOrMobile ? 36 : 60,
                            width: isTabletOrMobile ? 36 : 60,
                            textAlign: 'center',
                            margin: 5,
                            borderRadius: 4, fontSize: isTabletOrMobile ? 16 : 24
                        }}
                        onChange={(e) => {
                            setCode1(e.target.value)
                        }}
                        type={"text"}
                        onKeyUp={(e) => {
                            if (e.keyCode === 8) {
                                setCode1("")
                            }
                            else
                                ref2?.current?.focus();

                        }}
                        value={code1}
                        maxLength="1"
                    />
                    <input
                        className='two-factor'
                        ref={ref2}
                        style={{
                            border: '1px solid #d4daf8',
                            height: isTabletOrMobile ? 36 : 60,
                            width: isTabletOrMobile ? 36 : 60,
                            textAlign: 'center',
                            margin: 5,
                            borderRadius: 4, fontSize: isTabletOrMobile ? 16 : 24
                        }}
                        onChange={(e) => {
                            setCode2(e.target.value)
                        }}
                        type="text"
                        onKeyUp={(e) => {
                            if (e.keyCode === 8) {
                                ref1?.current?.focus();
                                setCode2("")
                            }
                            else ref3?.current?.focus()
                        }}
                        value={code2}
                        maxLength="1"
                    />
                    <input
                        id="input3"
                        className='two-factor'
                        ref={ref3}
                        style={{
                            border: '1px solid #d4daf8',
                            height: isTabletOrMobile ? 36 : 60,
                            width: isTabletOrMobile ? 36 : 60,
                            textAlign: 'center',
                            margin: 5, borderRadius: 4, fontSize: isTabletOrMobile ? 16 : 24
                        }}
                        type="text"
                        onChange={(e) => {
                            setCode3(e.target.value)
                        }}
                        onKeyUp={(e) => {
                            if (e.keyCode === 8) {
                                setCode3("")
                                ref2?.current?.focus();
                            }
                            else ref4?.current?.focus();

                        }}
                        value={code3}
                        maxLength="1"
                    />
                    <span className='hyphen' />
                    <input
                        id="input4"
                        className='two-factor'
                        ref={ref4}
                        style={{
                            border: '1px solid #d4daf8',
                            height: isTabletOrMobile ? 36 : 60,
                            width: isTabletOrMobile ? 36 : 60,
                            textAlign: 'center',
                            margin: 5, borderRadius: 4, fontSize: isTabletOrMobile ? 16 : 24
                        }}
                        onChange={(e) => {
                            setCode4(e.target.value)
                        }}
                        type="text"
                        onKeyUp={(e) => {
                            if (e.keyCode === 8) {
                                setCode4("")
                                ref3?.current?.focus();
                            }
                            else ref5?.current?.focus();

                        }}
                        value={code4}
                        maxLength="1"
                    />
                    <input
                        id="input5"

                        className='two-factor'
                        ref={ref5}
                        style={{
                            border: '1px solid #d4daf8',
                            height: isTabletOrMobile ? 36 : 60,
                            width: isTabletOrMobile ? 36 : 60,
                            textAlign: 'center',
                            margin: 5,
                            borderRadius: 4, fontSize: isTabletOrMobile ? 16 : 24
                        }}
                        onChange={(e) => {
                            setCode5(e.target.value)
                        }}
                        type="text"
                        onKeyUp={(e) => {
                            if (e.keyCode === 8) {
                                setCode5("")
                                ref4?.current?.focus();
                            }
                            else ref6?.current?.focus();

                        }}
                        value={code5}
                        maxLength="1"
                    />
                    <input
                        id="input6"
                        className='two-factor'
                        ref={ref6}
                        style={{
                            border: '1px solid #d4daf8',
                            height: isTabletOrMobile ? 36 : 60,
                            width: isTabletOrMobile ? 36 : 60,
                            textAlign: 'center',
                            margin: 5, borderRadius: 4, fontSize: isTabletOrMobile ? 16 : 24
                        }}
                        type="text"
                        onChange={(e) => {
                            setCode6(e.target.value)
                        }}
                        onKeyUp={(e) => {
                            if (e.keyCode === 8) {
                                setCode6("")
                                ref5?.current?.focus();
                            }
                        }}
                        value={code6}
                        maxLength="1"
                    />
                </div>
                {validCode && <div
                    style={{ marginTop: 10, fontWeight: '500', alignItems: 'center', textAlign: 'center', width: '100%', color: '#ff2525', border: '1px solid', borderColor: '#ff2525', borderRadius: 4, padding: '10px 12px' }}>
                    <img alt="" src={icError} />
                    <span style={{ paddingRight: 10 }}>הקוד שהקלדת שגוי. נסה שוב או בקש קוד חדש.</span>
                </div>}
                <div style={{ fontSize: isTabletOrMobile ? 12 : 14, textAlign: 'center', marginTop: 16 }}>
                    לא קיבלת?&nbsp;
                    <span onClick={handleSend} style={{cursor:'pointer', color: '#827aa5', fontWeight: 500, textDecoration: 'underline' }}>שלח שוב</span>
                </div>
                <div style={{ marginTop: 20, display:'flex',  justifyContent:'center' }}>
                    <Button style={{ margin: '0 auto', marginTop: 30, width: isTabletOrMobile ? window.innerWidth - 100 : 160 }} onPress={handleLogin} text={'הבא'} />
                </div>
            </div>
        </div>

    );

}
