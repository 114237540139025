import toast, { ToastBar, Toaster } from "react-hot-toast";
import { icClose, icLikeGreen } from "../assets/images/images";

export default function ToastModal() {
    return (<Toaster
        toastOptions={{
            className: '',
            duration: 5000,
            style: {
                display: 'flex',
                fontSize: 16,
                height: 70,
                width: 350,
                fontWeight: 'bold'
            },

        }}
        position='bottom-left'
    >
        {(t) => (
            <ToastBar toast={t}>
                {({ icon, message }) => (
                    <>
                        <div style={{
                            position: 'absolute',
                            right: 0,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            top: 0,
                            width: '10px',
                            height: '100%',
                            backgroundColor: '#36c0b0'
                        }} />
                        <img alt="icon" style={{ marginRight: 12, }} src={icLikeGreen} />
                        <span>{message}</span>
                        {t.type !== 'loading' && (
                            <img onClick={()=> toast.dismiss(t.id)}style={{cursor:'pointer', position: 'absolute', top: 10, left: 10 }} alt="icon" src={icClose} />
                        )}

                    </>
                )}
            </ToastBar>
        )}
    </Toaster>)
}
