import React from 'react';
import Button from './Button';
import {icClose} from '../assets/images/images';
import { useMediaQuery } from 'react-responsive';
export default function Tags({ title, data, firstButtonText, firstButtonHandler, onClose, onDelete, secondButtonText, secondButtonHandler }) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })

    return (
        <>
            <div style={{ zIndex: 1 }} className="mask" />
            <div style={{ zIndex: 1, height:'auto' }} className='dialog'>
                <div style={{ width: '580px' }}>
                    <img alt="" width="24" height="24" onClick={onClose} src={icClose} />
                    <p style={{ marginTop: 12, fontSize: 28, fontWeight: 'bold', textAlign: 'center' }}>{title}</p>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }} className='justify-content-center'>
                        {data.map((item, index) =>
                            <div
                                style={{ marginTop: 12 }}
                                className='tag'>
                                {item.label}
                                <img alt="" style={{ marginRight: 12 }} width="20" height="20" onClick={() => onDelete(item, index)} src={icClose} />

                            </div>
                        )}
                    </div>
                    <div style={{ marginTop: 40 }} className='d-flex justify-content-center'>
                        <Button style={{ width: isTabletOrMobile ? window.innerWidth - 100 : 160, }} onPress={firstButtonHandler} text={firstButtonText} />
                        <Button style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} onPress={secondButtonHandler} text={secondButtonText} />
                    </div>
                </div>
            </div>
        </>
    );

}
