import React, { useState } from 'react';
import Button from './Button';
import { icClose, icDelete, icEditColor, icUpload, icUserDefault } from '../assets/images/images';
import { useMediaQuery } from 'react-responsive';
export default function ProfileImageDialog({ title, handleSave, onClose, subText, image }) {
    const [files, setFiles] = useState(image ?? null)

    const handleDrop = (event) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        console.log(files);
        if (files.length > 0) {
            setFiles(files[0]);
        }
    }

    const handleDragOver = (event) => {
        event.preventDefault()
    }


    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
    return (
        <>
            <div className="mask" />
            <div style={{ width: 405 }} className='dialog'>
                <div style={{ width: '95%' }}>
                    <img style={{ cursor: 'pointer', position: 'absolute', left: 24, }} alt="" width="24" height="24" onClick={() => { onClose(files) }} src={icClose} />
                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ marginTop: 12, fontSize: isTabletOrMobile ? 24 : 24, fontWeight: 'bold', textAlign: 'center' }}>{title}</span>
                        {subText && <span style={{ marginTop: 8, color: '#827aa5', fontSize: isTabletOrMobile ? 18 : 18, textAlign: 'center' }}>{subText}</span>}
                        <div style={{ height: 1, backgroundColor: '#d4daf8', width: '95%', margin: '23px 0px 0px 0px' }} />
                        {
                            files ?
                                <div style={{ marginTop: 37 }} className='position-relative'>
                                    <img alt="icon" width="337" height="337" style={{ borderRadius: '50%', objectFit: 'cover' }} src={typeof files?.url === 'string' ? files?.url : URL.createObjectURL(files)} />

                                </div>

                                :
                                <div
                                    onDrop={handleDrop} onDragOver={handleDragOver}
                                    style={{ width: 337, height: 337 }} className='d-flex align-items-center flex-column'>
                                    <img alt="icon" width="164" style={{ marginTop: 50 }} src={icUserDefault} />
                                    <span style={{ color: '#827aa5', marginTop: 12, fontSize: isTabletOrMobile ? 24 : 24, fontWeight: '500', textAlign: 'center' }}>{'גרור לתמונה לכאן'}</span>
                                </div>}
                        {!files && <div className='d-flex align-items-center'>
                            <div style={{ height: 1, backgroundColor: '#8690a2', width: 100, margin: '0px 10px 0px 10px' }} />
                            <span style={{ color: '#827aa5', fontSize: isTabletOrMobile ? 18 : 18, textAlign: 'center' }}>{'או'}</span>
                            <div style={{ height: 1, backgroundColor: '#8690a2', width: 100, margin: '0px 10px 0px 10px' }} />
                        </div>}
                        <div style={{ marginTop: 50, }} className='d-flex'>
                            {typeof files?.url === 'string' ?
                                <div
                                    onClick={() => { setFiles(null); }}
                                    style={{
                                        cursor: 'pointer',
                                        flexDirection: 'row-reverse',
                                        borderColor: '#4f35be',
                                        padding: '8px 16px',
                                        marginLeft: !files ? 0 : 12,
                                        justifyContent: 'space-between', width: !files ? '200px' : '172px', height: '44px'
                                    }} className='image-frame'>


                                    <img width="20" height="20" alt="upload" src={icDelete} />
                                    <span style={{ paddingRight: 10, fontSize: 16, color: '#4f35be' }}>{'מחק תמונה'}</span>
                                </div>


                                :
                                files && <Button onPress={() => handleSave(files)} style={{ width: 172 }} text={'שמור תמונה'} />
                            }
                            <div
                                style={{
                                    flexDirection: 'row-reverse',
                                    borderColor: '#4f35be',
                                    padding: '8px 16px',
                                    marginLeft: !files ? 0 : 12,
                                    justifyContent: 'space-between',
                                    width: !files ? '200px' : '172px',
                                    height: '44px',
                                    position: 'relative'
                                }}
                                className='image-frame'
                            >
                                <img width="20" height="20" alt="upload" src={!files ? icUpload : icEditColor} />
                                <span style={{ paddingRight: 10, fontSize: 16, color: '#4f35be' }}>
                                    {!files ? 'העלה מהמחשב' : 'שנה תמונה'}
                                </span>
                                <label style={{ cursor: 'pointer', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                                    <input
                                        style={{ display: 'none' }}
                                        onChange={(event) => {
                                            setFiles(event.target.files[0]);
                                        }}
                                        accept="image/png,image/jpeg"
                                        type="file"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );

}
