import React from 'react';
import ReactLoading from 'react-loading';

export default function LoadingModal({ visible }) {
    return (
        visible &&
        <>
            <div style={{ zIndex: 1, }} className="mask" />
            <div style={{
                zIndex:1000,
                position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
            }}>
                <ReactLoading type={'spin'} color={'black'} height={50} width={50} />
            </div>
        </>
    );

}
