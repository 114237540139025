import React from 'react';

function FileUploadButton({ image, onChange, style, text, className }) {
  return (
    <div style={style} className={`button ${className}`}>
      {text !== '' && <span>{text}</span>}
      {image !== undefined && <img alt='icon' style={{ marginRight: text === '' ? 0 : 8 }} src={image} />}
      <input
        className='button'
        accept='video/*' // Accepts any type of video file
        style={{ cursor: 'pointer', opacity: 0, position: 'absolute' }}
        onChange={onChange}
        type='file'
      />
    </div>
  );
}

export default FileUploadButton;
