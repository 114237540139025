import config from "../config";

const NetworkSetting = {
  ROOT: `${config.API_URL}/api/v1`,
};


const URL = {
  LOGIN: `${NetworkSetting.ROOT}/auth/login`,
  TWO_FACTOR: `${NetworkSetting.ROOT}/auth/two-fa`,
  GET_USER_DETAILS: `${NetworkSetting.ROOT}/user`,
  FORGOT_PASS: `${NetworkSetting.ROOT}/auth/forgot-password`,
  RESET_PASS: `${NetworkSetting.ROOT}/auth/reset-password`,
  // COMPANIES: `${NetworkSetting.ROOT}/get_companies`,
  UPDATE_COMPANY: `${NetworkSetting.ROOT}/company`,
  GET_LIST: `${NetworkSetting.ROOT}/get-lists`,
  GET_LEGALS: `${NetworkSetting.ROOT}/legal`,

  // GET_COMPANY_DETAILS: `${NetworkSetting.ROOT}/admin_company_details`,
  UPDATE_USER_DEVICE: `${NetworkSetting.ROOT}/user-device`,
  // CREATE_TEAM_MEMBER: `${NetworkSetting.ROOT}/company-team`,
  GET_ISRAELI_COMPANY: `${NetworkSetting.ROOT}/israeli-company`,
  VALID_TOKEN: `${NetworkSetting.ROOT}/auth/validate-token`,
  UPDATE_JOB: `${NetworkSetting.ROOT}/job`,
  CREATE_JOB: `${NetworkSetting.ROOT}/job`,
  UPDATE_TEAM_MEMBER: `${NetworkSetting.ROOT}/company-team/update-member`,
  UPDATE_USER_DETAILS: `${NetworkSetting.ROOT}/user-update-details`,
  CREATE_COMPANY: `${NetworkSetting.ROOT}/company`,
  // EMPLOYESS: `${NetworkSetting.ROOT}/admin_get_employees`,
  GET_EMPLOYEE_DETAILS: `${NetworkSetting.ROOT}/employee`,
  GET_EMPLOYEE_DETAILS_COMPANY: `${NetworkSetting.ROOT}/get_employee_details`,
  UPDATE_EMPLOYEE: `${NetworkSetting.ROOT}/employee-edit`,
  CREATE_EMPLOYEE: `${NetworkSetting.ROOT}/employee-register`,
  EMPLOYEES_GUEST: `${NetworkSetting.ROOT}/guest_get_employees`,
  JOBS_GUEST: `${NetworkSetting.ROOT}/guest/job_list`,
  JOB_LIST: `${NetworkSetting.ROOT}/get-job-list`,
  GET_EMPLOYEES: `${NetworkSetting.ROOT}/get-employee-list`,
  FILE_SIGNED_UPLOAD_URL: `${NetworkSetting.ROOT}/file-signed-upload-url`,
  FILE_DETAILS: `${NetworkSetting.ROOT}/file-details`,
  DELETE_IMAGES: `${NetworkSetting.ROOT}/file-delete-by-ids`,
  SAVE_TO_FAV_COMPANY: `${NetworkSetting.ROOT}/company-user-like/save-to-favorites`,
  SAVE_TO_FAV_EMPLOYEE: `${NetworkSetting.ROOT}/employee-job-like/save-to-favorites`,
  ADD_VIEW: `${NetworkSetting.ROOT}/view`,
  GET_JOBS: `${NetworkSetting.ROOT}/get-jobs`,
  REACT_EMPLOYEE: `${NetworkSetting.ROOT}/react_employee`,
  REACT_JOB: `${NetworkSetting.ROOT}/react_job`,
  EMPLOYEE_MATCH: `${NetworkSetting.ROOT}/matches`,
  COMPANY_MATCH: `${NetworkSetting.ROOT}/matches-by-company`,
  EMPLOYEE_MAYBE: `${NetworkSetting.ROOT}/employee-job-like`,
  COMPANY_MAYBE: `${NetworkSetting.ROOT}/company-likes`,
  GET_JOB: `${NetworkSetting.ROOT}/job`,
  CREATE_INTERVIEW: `${NetworkSetting.ROOT}/create_interview`,
  UPDATE_INTERVIEW: `${NetworkSetting.ROOT}/update_interview`,
  GET_INTERVIEWS: `${NetworkSetting.ROOT}/interview-company`,
  GET_EMPLOYEE_INTERVIEWS: `${NetworkSetting.ROOT}/interview-employee`,
  GET_COMMENTS: `${NetworkSetting.ROOT}/get_comments_employee`,
  ADD_COMMENT: `${NetworkSetting.ROOT}/comment_employee`,
  COMPANY_JOBS: `${NetworkSetting.ROOT}/job-company`,
  COMPANY_JOBS_USERS: `${NetworkSetting.ROOT}/job-users`,
  TOP_LIST: `${NetworkSetting.ROOT}/top-list`,
  COMPANY_CHAT_LIST: `${NetworkSetting.ROOT}/chat-company-user`,
  EMPLOYEE_CHAT_LIST: `${NetworkSetting.ROOT}/chat-employee`,
  GET_CHAT: `${NetworkSetting.ROOT}/chat`,
  ADD_MESSAGE: `${NetworkSetting.ROOT}/chat/send-message`,
  GET_NOTIFICATION: `${NetworkSetting.ROOT}/notifications`,
  READ_NOTIFICATION: `${NetworkSetting.ROOT}/read-notification`,
  FILE_MUX_URL: `${NetworkSetting.ROOT}/file-mux-video`,
  ADD_REPORT: `${NetworkSetting.ROOT}/abuse`

};

export default URL;