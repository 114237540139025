import React, { useState } from 'react';
import { icCheckBox, icCheckBoxChecked, icClose } from '../assets/images/images';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Button from './Button';

const distanceArr = [{ id: 1, label: "עד 15 ק״מ", value: 15 }, { id: 2, label: "עד 25 ק״מ", value: 25 }, { id: 3, label: "עד 35 ק״מ", value: 35 }, { id: 4, label: "עד 45 ק״מ", value: 45 }]

export default function Filter({ handleFilter, onClose, screen, jobs, job, status, distance, scope, type, experience, education }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })

  const [jobFilter, setJobFilter] = useState(job ?? [])
  const [scopeFilter, setScopeFilter] = useState(scope ?? [])
  const [distanceFilter, setDistanceFilter] = useState(distance ?? {})
  const [typeFilter, setTypeFilter] = useState(type ?? [])
  const [experienceFilter, setExperienceFilter] = useState(experience ?? [])
  const [educationFilter, setEducationFilter] = useState(education ?? [])

  const [statusJob, setStatusJob] = useState(status ?? [false, false])
  const [statusEmployee, setStatusEmployee] = useState(status ?? [])

  const list = useSelector(state => state.listDetails)

  const handleItem = (item, arr, setFunc) => {
    const tmpArr = [...arr]
    var indexOf = tmpArr.findIndex(i => i.id === item.id);

    if (indexOf !== -1) {
      tmpArr.splice(indexOf, 1)
    }
    else {
      tmpArr.push(item)
    }
    setFunc(tmpArr)

  }

  return (
    <>
      {!isTabletOrMobile && <div className="mask" />}
      <div className={`${isTabletOrMobile ? 'filter-mobile' : 'filter'}`}>
        <img alt="icon" onClick={onClose} style={{cursor: "pointer", alignSelf: 'flex-end', width: isTabletOrMobile ? 20 : 29, height: isTabletOrMobile ? 20 : 29 }} src={icClose} />
        <p style={{ fontWeight: 'bold', fontSize: 24 }}>סינון</p>
        {screen === 'employee' ?
          <>
            <p style={{ fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>בחירת משרה</p>
            <div className='d-flex flex-row flex-wrap'>
              {
                jobs?.map(job => {
                  return (
                    <div
                      onClick={() => handleItem(job, jobFilter, setJobFilter)}
                      style={{
                        cursor: "pointer",
                        color: jobFilter.length > 0 && jobFilter.some(({ id }) => id === job.id) ? '#ffffff' : '#2f2072',
                        backgroundColor: jobFilter.length > 0 && jobFilter.some(({ id }) => id === job.id) ? '#4f35be' : '#ffffff'
                      }}
                      className={`tag${isTabletOrMobile ? '-mobile' : ''}`}>
                      {job.label}
                    </div>
                  )
                })
              }
            </div>
            <p style={{ marginTop: 30, fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>סטטוס</p>
            <div>
              <div style={{cursor: "pointer",}} className='d-flex justify-content-start align-items-center'>
                <img alt="icon" onClick={() => {
                  const tmp = [...statusJob]
                  tmp[0] = !tmp[0]
                  setStatusJob(tmp)

                }} src={statusJob[0] ? icCheckBoxChecked : icCheckBox} />

                <span style={{ paddingRight: 10, fontSize: 16, backgroundColor: 'transparent' }}>{'התאמה חדשה'}</span>
              </div>
              <div style={{cursor: "pointer", marginTop: 16 }} className='d-flex justify-content-start align-items-center'>
                <img alt="icon" onClick={() => {
                  const tmp = [...statusJob]
                  tmp[1] = !tmp[1]
                  setStatusJob(tmp)

                }} src={statusJob[1] ? icCheckBoxChecked : icCheckBox} />

                <span style={{ paddingRight: 10, fontSize: 16, backgroundColor: 'transparent' }}>{'בתהליך'}</span>
              </div>
            </div>

            <p style={{ marginTop: 30, fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>מרחק</p>
            <div className='d-flex flex-row flex-wrap'>
              {
                distanceArr.map(distance => {
                  return (
                    <div
                      onClick={() => distanceFilter?.id === distance.id ? setDistanceFilter({}) : setDistanceFilter(distance)}
                      style={{
                        cursor: "pointer",
                        color: distanceFilter?.id === distance.id ? '#ffffff' : '#2f2072',
                        backgroundColor: distanceFilter?.id === distance.id ? '#4f35be' : '#ffffff'
                      }}
                      className={`tag${isTabletOrMobile ? '-mobile' : ''}`}>
                      {distance.label}
                    </div>
                  )
                })
              }
            </div>
            <p style={{ marginTop: 30, fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>היקף משרה</p>
            <div className='d-flex flex-row flex-wrap'>
              {
                list?.jobLocation.map(location => {
                  return (
                    <div
                      onClick={() => handleItem(location, scopeFilter, setScopeFilter)}
                      style={{
                        cursor: "pointer",
                        color: scopeFilter.length > 0 && scopeFilter.some(({ id }) => id === location.id) ? '#ffffff' : '#2f2072',
                        backgroundColor: scopeFilter.length > 0 && scopeFilter.some(({ id }) => id === location.id) ? '#4f35be' : '#ffffff'
                      }}
                      className={`tag${isTabletOrMobile ? '-mobile' : ''}`}>
                      {location.location}
                    </div>
                  )
                })
              }
            </div>
            <p style={{ marginTop: 30, fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>סוג משרה</p>
            <div className='d-flex flex-row flex-wrap'>
              {
                list?.jobEmploymentType.map(type => {
                  return (
                    <div
                      onClick={() => handleItem(type, typeFilter, setTypeFilter)}
                      style={{
                        cursor: "pointer",
                        color: typeFilter.length > 0 && typeFilter.some(({ id }) => id === type.id) ? '#ffffff' : '#2f2072',
                        backgroundColor: typeFilter.length > 0 && typeFilter.some(({ id }) => id === type.id) ? '#4f35be' : '#ffffff'
                      }}
                      className={`tag${isTabletOrMobile ? '-mobile' : ''}`}>
                      {type.type}
                    </div>
                  )
                })
              }
            </div>
            <Button style={{ width: isTabletOrMobile ? '100%' : 160, marginTop: 50, alignSelf: 'flex-end' }} onPress={() => { handleFilter(jobFilter, statusJob, distanceFilter, scopeFilter, typeFilter); onClose() }} text={'החל'} />
          </>
          :
          <>
            <p style={{ fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>בחירת משרה</p>
            <div className='d-flex flex-row flex-wrap'>
              {
                jobs?.map(job => {
                  return (
                    <div
                      onClick={() => handleItem(job, jobFilter, setJobFilter)}
                      style={{
                        cursor: "pointer",
                        color: jobFilter.length > 0 && jobFilter.some(({ id }) => id === job.id) ? '#ffffff' : '#2f2072',
                        backgroundColor: jobFilter.length > 0 && jobFilter.some(({ id }) => id === job.id) ? '#4f35be' : '#ffffff'
                      }}
                      className={`tag${isTabletOrMobile ? '-mobile' : ''}`}>
                      {jobFilter.length > 0 && jobFilter.some(({ id }) => id === job.id) ? `${job.label} (${job.count})` : job.label}
                    </div>
                  )
                })
              }
            </div>
            {status && <p style={{ marginTop: 30, fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>סטטוס</p>}
            {status && <div>
              <div style={{ cursor: "pointer", }} className='d-flex justify-content-start align-items-center'>
                <img alt="icon" onClick={() => {
                  const tmp = [...statusEmployee]
                  const indexOf = tmp.indexOf(1)
                  if (indexOf !== -1)
                    tmp.splice(indexOf, 1)
                  else tmp.push(1)
                  setStatusEmployee(tmp)

                }} src={statusEmployee.indexOf(1) !== -1 ? icCheckBoxChecked : icCheckBox} />

                <span style={{ paddingRight: 10, fontSize: 16, backgroundColor: 'transparent' }}>{'התאמה חדשה'}</span>
              </div>
              <div style={{cursor: "pointer", marginTop: 16 }} className='d-flex justify-content-start align-items-center'>
                <img alt="icon" onClick={() => {
                  const tmp = [...statusEmployee]
                  const indexOf = tmp.indexOf(2)
                  if (indexOf !== -1)
                    tmp.splice(indexOf, 1)
                  else tmp.push(2)
                  setStatusEmployee(tmp)

                }} src={statusEmployee.indexOf(2) !== -1 ? icCheckBoxChecked : icCheckBox} />

                <span style={{ paddingRight: 10, fontSize: 16, backgroundColor: 'transparent' }}>{'בתהליך'}</span>
              </div>
              <div style={{cursor: "pointer", marginTop: 16 }} className='d-flex justify-content-start align-items-center'>
                <img alt="icon" onClick={() => {
                  const tmp = [...statusEmployee]
                  const indexOf = tmp.indexOf(3)
                  if (indexOf !== -1)
                    tmp.splice(indexOf, 1)
                  else tmp.push(3)
                  setStatusEmployee(tmp)

                }} src={statusEmployee.indexOf(3) !== -1 ? icCheckBoxChecked : icCheckBox} />

                <span style={{ paddingRight: 10, fontSize: 16, backgroundColor: 'transparent' }}>{'התקבל/ה'}</span>
              </div>
              <div style={{cursor: "pointer", marginTop: 16 }} className='d-flex justify-content-start align-items-center'>
                <img alt="icon" onClick={() => {
                  const tmp = [...statusEmployee]
                  const indexOf = tmp.indexOf(4)
                  if (indexOf !== -1)
                    tmp.splice(indexOf, 1)
                  else tmp.push(4)
                  setStatusEmployee(tmp)

                }} src={statusEmployee.indexOf(4) !== -1 ? icCheckBoxChecked : icCheckBox} />

                <span style={{ paddingRight: 10, fontSize: 16, backgroundColor: 'transparent' }}>{'לא התקבל/ה'}</span>
              </div>
            </div>}
            <p style={{ marginTop: 30, fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>ניסיון</p>
            <div className='d-flex flex-row flex-wrap'>
              {
                list?.experience?.sort((a, b) => a.id - b.id).map(exp => {
                  return (
                    <div
                      onClick={() => handleItem(exp, experienceFilter, setExperienceFilter)}
                      style={{
                        cursor: "pointer",
                        color: experienceFilter.length > 0 && experienceFilter.some(({ id }) => id === exp.id) ? '#ffffff' : '#2f2072',
                        backgroundColor: experienceFilter.length > 0 && experienceFilter.some(({ id }) => id === exp.id) ? '#4f35be' : '#ffffff'
                      }}
                      className={`tag${isTabletOrMobile ? '-mobile' : ''}`}>
                      {exp.itemText}
                    </div>
                  )
                })
              }
            </div>
            <p style={{ marginTop: 30, fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>השכלה</p>
            <div className='d-flex flex-column flex-wrap'>
              {
                list?.education?.sort((a, b) => a.id - b.id).map((edu, index) => {
                  return (
                    <div style={{cursor: "pointer", marginTop: 16 }} className='d-flex justify-content-start align-items-center'>
                      <img alt="icon" onClick={() => {
                        handleItem(edu, educationFilter, setEducationFilter)

                      }} src={educationFilter.includes(edu) ? icCheckBoxChecked : icCheckBox} />

                      <span style={{ paddingRight: 10, fontSize: 16, backgroundColor: 'transparent' }}>{edu.itemTextHeb}</span>
                    </div>
                  )
                })
              }
            </div>

            <p style={{ marginTop: 30, fontWeight: 'bold', fontSize: 18, color: '#827aa5' }}>מרחק</p>
            <div className='d-flex flex-row flex-wrap'>
              {
                distanceArr.map(distance => {
                  return (
                    <div
                      onClick={() => distanceFilter?.id === distance.id ? setDistanceFilter({}) : setDistanceFilter(distance)}
                      style={{
                        cursor: "pointer",
                        color: distanceFilter?.id === distance.id ? '#ffffff' : '#2f2072',
                        backgroundColor: distanceFilter?.id === distance.id ? '#4f35be' : '#ffffff'
                      }}
                      className={`tag${isTabletOrMobile ? '-mobile' : ''}`}>
                      {distance.label}
                    </div>
                  )
                })
              }
            </div>
            <Button style={{ width: isTabletOrMobile ? '100%' : 160, marginTop: 50, alignSelf: 'flex-end' }} onPress={() => { handleFilter(jobFilter, statusEmployee, distanceFilter, experienceFilter, educationFilter);; onClose() }} text={'החל'} />

          </>
        }
      </div>
    </>
  );

}
