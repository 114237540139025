import React, { useState } from 'react';
import {
  ic1stDegree,
  ic2ndDegree,
  ic3ndDegree,
  highSchoolDiploma,
  icArrowRight,
  icDelete,
  icEdit,
  icOtherDegree,
  icReport,
  icFile,
} from '../../../assets/images/images';
import { Button, Card, TextInput } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

export default function EmployeeEducation({ handleNext, handleBack, data }) {
  console.log(data);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const [record2, setRecord2] = useState(data?.record2 !== undefined ? data.record2 : [{ editable: true }]);
  const [resume, setResume] = useState(data?.resume !== undefined ? data?.resume : null);
  const [file_1, setFile_1] = useState(data?.file_1 !== undefined ? data?.file_1 : null);
  const [file_2, setFile_2] = useState(data?.file_2 !== undefined ? data?.file_2 : null);

  const [education, setEducation] = useState(
    data?.educationsValues !== undefined
      ? data.educationsValues?.map((item) => item.educationId)
      : [{ id: null, value: null, image: '', valid: false }]
  );
  const list = useSelector((state) => state.listDetails);

  const [degree, setDegree] = useState(
    data?.educationsValues !== undefined
      ? data.educationsValues?.map((item) => item.text1)
      : [{ label: '', valid: false }]
  );
  const [location, setLocation] = useState(
    data?.educationsValues !== undefined
      ? data.educationsValues?.map((item) => item.text2)
      : [{ id: null, label: '', valid: false }]
  );

  const handleSubmit = () => {
    const educationsValues = prepareAnswers();
    const valid = validInputs();
    if (!valid) {
      const educations = [];
      record2.forEach(
        (item, index) =>
          education[index].id !== null &&
          educations.push({
            educationId: education[index].id,
            text1: degree[index].label,
            text2: location[index].label,
          })
      );

      handleNext(
        {
          educationsValues: educationsValues,
          educations: educations,
          record2: record2,
          resume: resume,
          file_1: file_1,
          file_2: file_2,
        },
        true
      );
    }
  };

  const prepareAnswers = (records = record2, educations = education, degrees = degree, locations = location) => {
    const educationsValues = [];
    records.forEach((item, index) =>
      educationsValues.push({ educationId: educations[index], text1: degrees[index], text2: locations[index] })
    );
    return educationsValues;
  };

  const validInputs = () => {
    let valid = false;
    education.map((item, index) => {
      if (item.id === null) {
        valid = true;
        const newArr = [...education];
        newArr[index].valid = true;
        setEducation(newArr);
        setTimeout(() => {
          const newArr = [...education];
          newArr[index].valid = false;
          setEducation(newArr);
        }, 1000);
      } else {
        const newArr = [...education];
        newArr[index].valid = false;
        setEducation(newArr);
      }
      return null;
    });

    degree.map((item, index) => {
      const newArr = [...degree];
      newArr[index].valid = false;
      setDegree(newArr);
      return null;
    });

    location.map((item, index) => {
      const newArr = [...location];
      newArr[index].valid = false;
      setLocation(newArr);
      return null;
    });

    return valid;
  };

  return (
    <>
      <div
        onClick={() => {
          handleBack({});
        }}
        style={{
          cursor: 'pointer',
          marginTop: '16px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img alt='arrow' src={icArrowRight} />
        <span style={{ color: '#4f35be', fontSize: 28, marginRight: 10, fontWeight: 'bold' }}>חזור</span>
      </div>

      <span
        style={{
          textAlign: 'right',
          fontSize: isTabletOrMobile ? '24px' : '32px',
          fontWeight: 'bold',
          marginTop: isTabletOrMobile ? '24px' : '15px',
        }}
      >
        {'תחום לימודים'}
      </span>
      {record2.map((item, index) => {
        return item.editable ? (
          <div style={{ textAlign: 'right', alignSelf: 'flex-start' }} key={index}>
            {index > 0 && <div className='seperator' />}
            {index !== 0 && (
              <img
                style={{ marginTop: '32px' }}
                alt='delete'
                onClick={() => {
                  const tmpEducation = [...education];
                  const tmpDegree = [...degree];
                  const tmpLocation = [...location];
                  const tmpRecords = [...record2];

                  if (record2.length === 1) {
                    tmpEducation[index] = { id: null, value: null, image: '', valid: false };
                    tmpDegree[index] = { id: null, label: '', valid: false };
                    tmpLocation[index] = { id: null, label: '', valid: false };

                    tmpRecords[index] = { editable: true };

                    setEducation(tmpEducation);
                    setDegree(tmpDegree);
                    setLocation(tmpLocation);
                    setRecord2(tmpRecords);

                    const educationsValues = prepareAnswers(tmpRecords, tmpEducation, tmpDegree, tmpLocation);
                    handleNext({ educations: educationsValues, record2: record2 }, false);
                  } else {
                    tmpEducation.splice(index, 1);
                    tmpDegree.splice(index, 1);
                    tmpLocation.splice(index, 1);
                    tmpRecords.splice(index, 1);

                    setEducation(tmpEducation);
                    setDegree(tmpDegree);
                    setLocation(tmpLocation);
                    setRecord2(tmpRecords);
                    const educationsValues = prepareAnswers(tmpRecords, tmpEducation, tmpDegree, tmpLocation);
                    handleNext({ educations: educationsValues, record2: record2 }, false);
                  }
                }}
                width='32'
                height='32'
                src={icDelete}
              />
            )}
            <div
              style={{ width: isTabletOrMobile ? '100vw' : '30vw', gap: !isTabletOrMobile ? 0 : 15 }}
              className={`d-flex justify-content-${isTabletOrMobile ? 'evenly' : 'start'} flex-wrap`}
            >
              <Card
                error={education[index].valid}
                title={list.education[5].itemTextHeb}
                image={highSchoolDiploma}
                onPress={() => {
                  const tmpEducation = [...education];
                  tmpEducation[index] = {
                    id: list.education[5].id,
                    value: list.education[5].itemTextHeb,
                    image: highSchoolDiploma,
                    valid: false,
                  };
                  setEducation(tmpEducation);
                }}
                clicked={education[index].id === list.education[5].id}
              />
              <Card
                error={education[index].valid}
                title={list.education[4].itemTextHeb}
                image={icReport}
                onPress={() => {
                  const tmpEducation = [...education];
                  tmpEducation[index] = {
                    id: list.education[4].id,
                    value: list.education[4].itemTextHeb,
                    image: icReport,
                    valid: false,
                  };
                  setEducation(tmpEducation);
                }}
                clicked={education[index].id === list.education[4].id}
              />
              <Card
                error={education[index].valid}
                title={list.education[3].itemTextHeb}
                image={ic1stDegree}
                onPress={() => {
                  const tmpEducation = [...education];
                  tmpEducation[index] = {
                    id: list.education[3].id,
                    value: list.education[3].itemTextHeb,
                    image: ic1stDegree,
                    valid: false,
                  };
                  setEducation(tmpEducation);
                }}
                clicked={education[index].id === list.education[3].id}
              />
              <Card
                error={education[index].valid}
                title={list.education[2].itemTextHeb}
                image={ic2ndDegree}
                onPress={() => {
                  const tmpEducation = [...education];
                  tmpEducation[index] = {
                    id: list.education[2].id,
                    value: list.education[2].itemTextHeb,
                    image: ic2ndDegree,
                    valid: false,
                  };
                  setEducation(tmpEducation);
                }}
                clicked={education[index].id === list.education[2].id}
              />
              <Card
                error={education[index].valid}
                title={`${list.education[1].itemTextHeb.split('/')[0]}
                  /${list.education[1].itemTextHeb.split('/')[1]}`}
                image={ic3ndDegree}
                onPress={() => {
                  const tmpEducation = [...education];
                  tmpEducation[index] = {
                    id: list.education[1].id,
                    value: list.education[1].itemTextHeb,
                    image: ic3ndDegree,
                    valid: false,
                  };
                  setEducation(tmpEducation);
                }}
                clicked={education[index].id === list.education[1].id}
              />
              <Card
                error={education[index].valid}
                title={list.education[0].itemTextHeb}
                image={icOtherDegree}
                onPress={() => {
                  const tmpEducation = [...education];
                  tmpEducation[index] = {
                    id: list.education[0].id,
                    value: list.education[0].itemTextHeb,
                    image: icOtherDegree,
                    valid: false,
                  };
                  setEducation(tmpEducation);
                }}
                clicked={education[index].id === list.education[0].id}
              />
            </div>
            {education[index].id !== null && (
              <div style={{ alignSelf: 'center' }}>
                <div style={{ marginTop: 30 }}>
                  <span style={{ color: '#827aa5', fontSize: '18px' }}>פרטים נוספים על ההשכלה שלך</span>
                </div>
                <div style={{ flexWrap: 'wrap', flexDirection: 'column' }} className='d-flex'>
                  <TextInput
                    style={{
                      marginRight: 0,
                      marginLeft: 16,
                      border: `1px solid ${degree[index].valid ? 'red' : '#D4DAF8'}`,
                    }}
                    className='auto-complete'
                    value={degree[index].label}
                    onChange={(value) => {
                      const tmpDegree = [...degree];
                      tmpDegree[index].label = value;
                      setDegree(tmpDegree);
                    }}
                    label={'שם השכלה'}
                  />
                  <TextInput
                    style={{
                      marginRight: 0,
                      marginLeft: 16,
                      marginBottom: 25,
                      border: `1px solid ${location[index].valid ? 'red' : '#D4DAF8'}`,
                    }}
                    className='auto-complete'
                    value={location[index].label}
                    onChange={(value) => {
                      const tmpLocation = [...location];
                      tmpLocation[index].label = value;
                      setLocation(tmpLocation);
                    }}
                    onBlur={() => {
                      if (degree[index].label !== '' && location[index].label !== '') {
                        const tmpArr = [...record2];
                        tmpArr[index].editable = false;
                        setRecord2(tmpArr);
                      }
                    }}
                    label={'מקום לימודים'}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div key={index} className='cards'>
              <div style={{ justifyContent: 'space-between', width: '100%' }} className='d-flex'>
                <span style={{ fontSize: 20, textAlign: 'right', fontWeight: 'bold' }}>{`${education[index].value} ${
                  degree[index].label ? `ב - ${degree[index].label}` : degree[index].label
                }`}</span>
                <img
                  alt='delete'
                  onClick={() => {
                    const tmpEducation = [...education];
                    const tmpDegree = [...degree];
                    const tmpLocation = [...location];
                    const tmpRecords = [...record2];

                    if (record2.length === 1) {
                      tmpEducation[index] = { id: null, value: null, image: '', valid: false };
                      tmpDegree[index] = { id: null, label: '', valid: false };
                      tmpLocation[index] = { id: null, label: '', valid: false };

                      tmpRecords[index] = { editable: true };

                      setEducation(tmpEducation);
                      setDegree(tmpDegree);
                      setLocation(tmpLocation);
                      setRecord2(tmpRecords);

                      const educationsValues = prepareAnswers(tmpRecords, tmpEducation, tmpDegree, tmpLocation);
                      handleNext({ educations: educationsValues, record2: record2 }, false);
                    } else {
                      tmpEducation.splice(index, 1);
                      tmpDegree.splice(index, 1);
                      tmpLocation.splice(index, 1);
                      tmpRecords.splice(index, 1);

                      setEducation(tmpEducation);
                      setDegree(tmpDegree);
                      setLocation(tmpLocation);
                      setRecord2(tmpRecords);
                      const educationsValues = prepareAnswers(tmpRecords, tmpEducation, tmpDegree, tmpLocation);
                      handleNext({ educations: educationsValues, record2: record2 }, false);
                    }
                  }}
                  width='32'
                  height='32'
                  src={icDelete}
                />
              </div>
              <div style={{ justifyContent: 'space-between', width: '100%' }} className='d-flex'>
                <span style={{ color: '#827aa5', fontSize: 16, textAlign: 'right', fontWeight: 'bold', marginTop: 8 }}>
                  {location[index].label}
                </span>
                <img
                  alt='edit'
                  onClick={() => {
                    const tmpArr = [...record2];
                    tmpArr[index].editable = true;
                    setRecord2(tmpArr);
                  }}
                  src={icEdit}
                />
              </div>
            </div>
          </>
        );
      })}
      <span
        onClick={() => {
          const valid = validInputs();

          if (!valid) {
            const tmpArr = [...record2];
            tmpArr[tmpArr.length - 1].editable = false;
            tmpArr.push({ editable: true });
            setRecord2(tmpArr);

            const tmpEducation = [...education];
            tmpEducation.push({ id: null, value: null, image: '', valid: false });
            setEducation(tmpEducation);

            const tmpDegree = [...degree];
            tmpDegree.push({ label: '', valid: false });
            setDegree(tmpDegree);

            const tmpLocation = [...location];
            tmpLocation.push({ label: '', valid: false });
            setLocation(tmpLocation);

            const educationsValues = prepareAnswers();
            handleNext({ educations: educationsValues, record2: record2 }, false);
          }
        }}
        style={{
          cursor: 'pointer',
          marginTop: 24,
          textDecoration: 'underline',
          paddingRight: 5,
          fontSize: 16,
          color: '#36c0b0',
        }}
      >
        + הוספת השכלה נוספת
      </span>
      <div className='seperator' />
      <span style={{ color: '#827aa5', marginTop: 30 }}>באפשרותך להעלות עד 3 קבצים:</span>
      <span style={{ fontSize: '16px', marginTop: 12 }}>
        קו״ח בעברית/באנגלית/מותאמי תפקיד/מכתבי המלצה או כל קובץ רלוונטי אחר
      </span>
      {resume ? (
        <div
          style={{
            marginTop: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: isTabletOrMobile ? '100%' : 'auto',
          }}
        >
          {`1. ${resume.name}`}
          <img style={{ marginRight: 33 }} onClick={() => setResume(null)} alt='delete' src={icDelete} />
        </div>
      ) : (
        <div style={{ marginTop: 24 }} className='file_button_container frame'>
          <img alt='upload' src={icFile} />
          <span style={{ paddingRight: 10 }}>העלה קורות חיים</span>
          <input
            accept='.pdf,.doc,.jpeg'
            onChange={(event) => {
              setResume(event.target.files[0]);
            }}
            type='file'
          />
        </div>
      )}
      {file_1 ? (
        <div
          style={{
            marginTop: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: isTabletOrMobile ? '100%' : 'auto',
          }}
        >
          {`2. ${file_1.name}`}
          <img style={{ marginRight: 33 }} onClick={() => setFile_1(null)} alt='delete' src={icDelete} />
        </div>
      ) : (
        <div style={{ marginTop: 24 }} className='file_button_container frame'>
          <div>
            <img alt='file' src={icFile} />
            <span style={{ paddingRight: 10 }}>העלה קובץ 1</span>
          </div>
          <input
            accept='.pdf,.doc,.jpeg'
            style={{ padding: 0 }}
            onChange={(event) => {
              setFile_1(event.target.files[0]);
            }}
            type='file'
          />
        </div>
      )}
      {file_2 ? (
        <div
          style={{
            marginTop: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: isTabletOrMobile ? '100%' : 'auto',
          }}
        >
          {`3. ${file_2.name}`}
          <img style={{ marginRight: 33 }} onClick={() => setFile_2(null)} alt='delete' src={icDelete} />
        </div>
      ) : (
        <div style={{ marginTop: 24 }} className='file_button_container frame'>
          <div>
            <img alt='file' src={icFile} />
            <span style={{ paddingRight: 10 }}>העלה קובץ 2</span>
          </div>
          <input
            style={{ padding: 0 }}
            accept='.pdf,.doc,.jpeg'
            onChange={(event) => {
              setFile_2(event.target.files[0]);
            }}
            type='file'
          />
        </div>
      )}
      <div style={{ marginTop: '40px', position: 'relative', marginRight: 0, marginBottom: 20 }} className='d-flex row'>
        <Button onPress={() => handleSubmit()} text={'הבא'} />
        <Button
          style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }}
          onPress={() => handleNext({}, true)}
          text={'דלג'}
        />
      </div>
    </>
  );
}
