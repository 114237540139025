import moment from 'moment';
import React from 'react';
import { icCalendarNotif, icClose, icContractNotif, icNewMatchNotif } from '../assets/images/images';
import { useMediaQuery } from 'react-responsive';

export default function Notifications({ notification, handleNavigate, handleReadAll, setIsVisibleNotif }) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })

    const renderIcon = type => {
        switch (type) {
            case 'new match':
                return icNewMatchNotif;
            case 'company cancel interview': case 'new interview scheduled': case 'employee request new dates for inteview': case 'company interview starts now': case 'employee cancel interview': case 'employee interview starts now':
                return icCalendarNotif;
            case 'company contract denied': case 'company contract approved':
                return icContractNotif;
        }
    }

    return (
        <div style={isTabletOrMobile ? {} : { zIndex: 1000, position: 'absolute', top: '65px', right: '60px' }}>
            {!isTabletOrMobile && <div className="arrow-up" />}
            <div className='notif'>
                <div style={{ width: '100%', padding: isTabletOrMobile ? '20px 0px' : '20px', textAlign: 'right', alignSelf: 'flex-start' }}>
                    <span style={{ fontWeight: 'bold' }} className="title-user">התראות</span>
                    {!isTabletOrMobile && <img width="20" onClick={()=> setIsVisibleNotif(false)} style={{cursor:'pointer', position:"absolute", left:10}} src={icClose}/>}

                    {notification.filter(item => !item.isRead).length > 0 && <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{`יש לך ${notification.filter(item => !item.isRead).length} התראות חדשות`}</span>
                        <span onClick={() => handleReadAll()} style={{ cursor: 'pointer', fontSize: 14, color: '#4F35BE', textDecoration: 'underline', fontWeight: 'bold' }}>{'סמן הכל כנקרא'}</span>
                    </div>}
                    <div style={{ overflowY: 'auto', maxHeight: '50vh', height: isTabletOrMobile ? 'auto' : '53vh', }}>
                        {
                            notification.map((notif, index) => (
                                <div onClick={() => handleNavigate(notif)} style={{ cursor: 'pointer', marginTop: 10, }} >
                                    <div style={{ gap: 10 }} className="d-flex flex-row">
                                        <img width="30" height="30" src={renderIcon(notif.type.type)} />
                                        <div className='d-flex flex-column'>
                                            <span style={{ fontSize: isTabletOrMobile ? 14 : 16, fontWeight: notif.isRead ? 'normal' : 'bold' }}>{notif.notiBody}</span>
                                            <span style={{ fontSize: isTabletOrMobile ? 10 : 12, color: '#8690a2' }}>{moment(notif.createdAt).format('DD.MM.YYYY')}</span>
                                        </div>
                                    </div>
                                    {index !== notification.length - 1 && <div style={{ opacity: 0.5, width: '100%', marginTop: 14, height: '0.1px', transform: 'rotate(-180deg)', backgroundColor: '#d4daf8' }} />}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}
