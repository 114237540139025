import React, {  useState } from 'react';
import Button from './Button';
import { icCheckBoxChecked, icClose, icCheckBox } from '../assets/images/images';
import { useMediaQuery } from 'react-responsive';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";


const useStyles = makeStyles({
    paper: {
        border: "1px solid #d4daf8",
    },
    inputRoot: {

        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment': {
            left: 1,
            textAlign: 'left'
        },
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-clearIndicator': {
            position: 'absolute',
            right: -9,
        },
    },

});

const CssTextField = styled(TextField)({
    '& label': {
        transformOrigin: "right !important",
        left: "inherit !important",
        right: "1.75rem !important",
        overflow: "unset",
    },
    '& .MuiOutlinedInput-root': {

        '& fieldset': {
            border: '0px'
        },
        '&:hover fieldset': {
            border: '1px solid #d4daf8'

        },
        '&.Mui-focused fieldset': {
            border: '1px solid #d4daf8'
        },
    },
});




export default function EmployeeInfoDialog({dashboard, title, data, list, search, firstButtonText, firstButtonHandler, subtitle, secondButtonText, secondButtonHandler }) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
    const classes = useStyles();

    const [items, setItems] = useState(data)
    const [open, setOpen] = useState(false)

    const display = isTabletOrMobile ? 1 : 3

    const handleDelete = (option, index) => {
        let newArr = [...items]
        const indexOf = newArr.indexOf(option);
        if (indexOf > -1) { // only splice array when item is found
            newArr.splice(index, 1); // 2nd parameter means remove one item only

        }
        setItems(newArr)

    }



    return (
        <>
            <div style={{ zIndex: 1 }} className="mask" />
            <div style={{ zIndex: 1, height: 'auto', position:'fixed' }} className='dialog'>
                <div style={{ width: '580px' }}>
                    <p style={{ marginTop: 12, fontSize: isTabletOrMobile ? 24 : 28, fontWeight: 'bold', textAlign: 'center' }}>{title}</p>
                    <p style={{ marginTop: 12, fontSize: isTabletOrMobile ? 16 : 18, textAlign: 'center' }}>{subtitle}</p>

                    <div style={{ position: 'relative', marginTop: '32px', }}>

                        <Autocomplete
                            open={open}
                            onOpen={() => setOpen(true)}
                            onFocus={() => setOpen(true)}
                            onBlur={() => { setOpen(false) }}
                            disableCloseOnSelect
                            dir='rtl'
                            multiple
                            id="size-small-standard-multi"
                            size="small"
                            options={list ?? []}
                            getOptionLabel={(option) => option?.label}
                            value={items}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                if (newValue.length > 9) {
                                    newValue.pop();
                                }
                                setItems([...newValue]);

                            }}
                            ListboxProps={{
                                className: "myCustomList",
                                style: {
                                    direction: 'rtl',
                                    maxHeight: '192px',
                                }
                            }}
                            forcePopupIcon={true}
                            renderInput={(params) =>
                                <CssTextField
                                    {...params}
                                    sx={{
                                        width: '100%',
                                        border: `1px solid #d4daf8`,
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 16px -1px rgba(207, 204, 217, 0.2)',
                                        input: {
                                            fontSize: 16,
                                            fontFamily: 'Heebo',
                                            "&::placeholder": {    // <----- Add this.
                                                opacity: 1,
                                            },
                                        },
                                        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment': {
                                            left: 1,
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "20px!important",
                                        },

                                    }} placeholder={items?.length === 0 ? search : ''} id="custom-css-outlined-input" />



                            }
                            classes={classes}
                            renderOption={(props, option) => {
                                return (
                                    <div  {...props} key={option.id} style={{ paddingRight: 10 }} className='d-flex justify-content-start align-items-center'>
                                        <img alt="" width="14" height="14" src={items.filter(item => item.id === option.id).length > 0 ? icCheckBoxChecked : icCheckBox} />
                                        <li style={{ fontSize: 16, backgroundColor: 'transparent' }} {...props}>{option.label}</li>
                                    </div>
                                )
                            }}
                            PaperComponent={({ children }) => {
                                return (
                                    <Paper style={{ zIndex: 1, }}>
                                        {children}
                                        <div onMouseDown={() => { setOpen(false); }} style={{ color: items.length === 9 ? '#ff5c5c' : '#1f0039', lineHeight: 3, textAlign: 'center', fontSize: 18, backgroundColor: '#d4daf8', height: 48 }}>
                                            {!dashboard && items.length === 9 ? '!לא ניתן לבחור יותר מ-3 תכונות אופי' : 'בחר'}
                                        </div>
                                    </Paper>
                                );
                            }}
                            renderTags={(value) =>

                                items.length <=  display ?
                                    value.map((option, index) => {
                                        return (
                                            <div style={{ cursor: 'pointer' }} key={option.id} className='skill'>{option?.label}
                                                <img alt="" style={{ zIndex: 1 }} onClick={() => {
                                                    let newArr = [...items]
                                                    const indexOf = newArr.indexOf(option);
                                                    if (indexOf > -1) { // only splice array when item is found
                                                        newArr.splice(index, 1); // 2nd parameter means remove one item only
                                                    }
                                                    setItems(newArr)

                                                }} width="16" height="16" src={icClose} />
                                            </div>

                                        )
                                    }) :
                                    <>
                                        {
                                            items.slice(0, display).map((option, index) => {
                                                return (
                                                    <div key={option.id} className='skill'>{option?.label}
                                                        <img alt="" style={{ zIndex: 1 }} onClick={() => {
                                                            handleDelete(option, index)
                                                        }} width="16" height="16" src={icClose} />
                                                    </div>

                                                )
                                            })
                                        }
                                        <div className='skill-2'>
                                            {items.length - display }+
                                        </div>
                                    </>
                            }
                        />
                        {/* } */}
                    </div>


                    < div style={{ marginTop: 20, flexDirection: isTabletOrMobile ? 'column' : 'row', alignItems: 'center' }} className='d-flex justify-content-center'>
                        <Button style={{ width: isTabletOrMobile ? '100%' : 160, }} onPress={() => firstButtonHandler(items)} text={firstButtonText} />
                        <Button style={{ width: isTabletOrMobile ? '100%' : 160, backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} onPress={secondButtonHandler} text={secondButtonText} />
                    </div>
                </div>
            </div >
        </>
    );

}
