import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, TextArea } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import EmployeeInfoDialog from '../../../components/EmployeeInfoDialog';

export default function EmployeeAdditional({ handleNext, data, handleBack }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const list = useSelector((state) => state.listDetails);
  const [character, setCharacter] = useState(data?.characteristics !== undefined ? data.characteristics : []);
  const [interest, setInterest] = useState(data?.interests !== undefined ? data.interests : []);
  const [about, setAbout] = useState(data?.about !== undefined ? data.about : '');

  const [characterList, setCharacterList] = useState([]);
  const [interestList, setInterestList] = useState([]);

  const [characterLimited] = useState(true);
  const [interestLimited] = useState(true);

  const [validChar, setValidChar] = useState(false);
  const [validInterest, setValidInterest] = useState(false);

  const [visibleChar, setVisibleChar] = useState(false);
  const [visibleInterest, setVisibleInterest] = useState(false);

  useEffect(() => {
    const tmpCharacter = [];
    list?.characteristics.forEach((item) => tmpCharacter.push({ id: item.id, label: item.itemTextHeb }));
    setCharacterList(tmpCharacter);

    const tmpInterest = [];
    list?.skill.forEach((item) => tmpInterest.push({ id: item.id, label: item.itemTextHeb }));
    setInterestList(tmpInterest);
  }, [list]);

  const handleItem = (item, arr, setFunc, key) => {
    const tmpArr = [...arr];
    if (tmpArr.includes(item)) {
      const index = tmpArr.indexOf(item);
      tmpArr.splice(index, 1);
    } else {
      tmpArr.push(item);
    }
    if (key === 'interests') handleNext({ interests: tmpArr }, false);
    else handleNext({ characteristics: tmpArr }, false);

    setFunc(tmpArr);
  };

  const handleSubmit = () => {
    if (character.length === 0) {
      setValidChar(true);
      setTimeout(() => {
        setValidChar(false);
      }, 1000);
    }
    if (interest.length === 0) {
      setValidInterest(true);
      setTimeout(() => {
        setValidInterest(false);
      }, 1000);
    } else if (character.length > 0 && interest.length > 0) {
      // const formData = new FormData()

      // formData.append('characteristics', JSON.stringify(character.map(item => item.id)))
      // formData.append('interests', JSON.stringify(interest.map(item => item.id)))
      // formData.append('about', about)

      // dispatch(updateEmployee(formData, currentUser?.id, currentUser?.token)).then(res => {
      handleNext({ characteristics: character, interests: interest, about: about }, true);
      // }).catch(err => console.log("err -->", err))
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: isTabletOrMobile ? '24px' : '16px',
        }}
      >
        <span style={{ color: '#4f35be', fontSize: 28, marginRight: 10, fontWeight: 'bold' }}></span>
      </div>
      <span
        style={{
          fontSize: isTabletOrMobile ? '24px' : '32px',
          textAlign: 'right',
          fontWeight: 'bold',
        }}
      >
        {'אופי וכישורים'}
      </span>
      <span style={{ fontSize: '16px', marginTop: '24px', color: '#827aa5' }}>מהן תכונות האופי הבולטות שלך?</span>

      <div className='div-container'>
        {characterList.map((item, index) => {
          if (characterLimited && index < 7) {
            return (
              <div
                key={item.id}
                onClick={() => handleItem(item, character, setCharacter, 'characteristics')}
                style={{
                  cursor: 'pointer',
                  border: `1px solid ${validChar ? 'red' : '#D4DAF8'}`,
                  color: character.length > 0 && character.some(({ id }) => id === item.id) ? '#ffffff' : '#2f2072',
                  backgroundColor:
                    character.length > 0 && character.some(({ id }) => id === item.id) ? '#4f35be' : '#ffffff',
                }}
                className={`tag${isTabletOrMobile ? '-mobile' : ''}`}
              >
                {item.label}
              </div>
            );
          } else if (!characterLimited)
            return (
              <div
                key={item.id}
                onClick={() => handleItem(item, character, setCharacter, 'characteristics')}
                style={{
                  cursor: 'pointer',
                  border: `1px solid ${validChar ? 'red' : '#D4DAF8'}`,
                  color: character.length > 0 && character.some(({ id }) => id === item.id) ? '#ffffff' : '#2f2072',
                  backgroundColor:
                    character.length > 0 && character.some(({ id }) => id === item.id) ? '#4f35be' : '#ffffff',
                }}
                className={`tag${isTabletOrMobile ? '-mobile' : ''}`}
              >
                {item.label}
              </div>
            );
          return null;
        })}
        <span
          onClick={() => setVisibleChar(true)}
          style={{ cursor: 'pointer', marginTop: 10, fontSize: '16px', textDecoration: 'underline' }}
        >
          תכונות נוספות
        </span>
      </div>

      <span style={{ fontSize: '16px', marginTop: '24px', color: '#827aa5' }}>מהם הכישורים שלך?</span>

      <div className='div-container'>
        {interestList.map((item, index) => {
          if (interestLimited && index < 7) {
            return (
              <div
                key={item.id}
                onClick={() => handleItem(item, interest, setInterest, 'interests')}
                style={{
                  cursor: 'pointer',
                  border: `1px solid ${validInterest ? 'red' : '#D4DAF8'}`,
                  color: interest.length > 0 && interest.some(({ id }) => id === item.id) ? '#ffffff' : '#2f2072',
                  backgroundColor:
                    interest.length > 0 && interest.some(({ id }) => id === item.id) ? '#4f35be' : '#ffffff',
                }}
                className={`tag${isTabletOrMobile ? '-mobile' : ''}`}
              >
                {item.label}
              </div>
            );
          } else if (!interestLimited)
            return (
              <div
                onClick={() => handleItem(item, interest, setInterest, 'interests')}
                style={{
                  cursor: 'pointer',
                  border: `1px solid ${validInterest ? 'red' : '#D4DAF8'}`,
                  color: interest.length > 0 && interest.some(({ id }) => id === item.id) ? '#ffffff' : '#2f2072',
                  backgroundColor:
                    interest.length > 0 && interest.some(({ id }) => id === item.id) ? '#4f35be' : '#ffffff',
                }}
                className={`tag${isTabletOrMobile ? '-mobile' : ''}`}
              >
                {item.label}
              </div>
            );
          return null;
        })}
        <span
          onClick={() => setVisibleInterest(true)}
          style={{ cursor: 'pointer', marginTop: 10, fontSize: '16px', textDecoration: 'underline' }}
        >
          כישורים נוספים
        </span>
      </div>
      <TextArea
        style={{ marginTop: 40 }}
        maxLength={2000}
        className='text-area'
        value={about}
        onChange={setAbout}
        label={'ספר לנו על עצמך'}
      />

      <div style={{ marginTop: '40px', position: 'relative', marginRight: 0, marginBottom: 20 }} className='d-flex row'>
        <Button onPress={() => handleSubmit()} text={'הבא'} />
        <Button
          style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }}
          onPress={() => handleNext({}, true)}
          text={'דלג'}
        />
      </div>
      {visibleChar && (
        <EmployeeInfoDialog
          title={'הוספת תכונות אופי בולטות'}
          subtitle={'ניתן לבחור עד 3 תכונות אופי בולטות'}
          data={character}
          search={'בחר תכונות אופי'}
          list={characterList}
          firstButtonText={'הוסף'}
          firstButtonHandler={(items) => {
            setVisibleChar(false);
            setCharacter(items);
          }}
          secondButtonHandler={() => {
            setVisibleChar(false);
          }}
          secondButtonText={'ביטול'}
          onClose={() => setVisibleChar(false)}
        />
      )}
      {visibleInterest && (
        <EmployeeInfoDialog
          title={'הוספת כישורים'}
          subtitle={'ניתן לבחור עד 3 כישורים'}
          search={'חפש כישורים'}
          data={interest}
          list={interestList}
          firstButtonText={'שמור'}
          firstButtonHandler={(items) => {
            setVisibleInterest(false);
            setInterest(items);
          }}
          secondButtonHandler={() => {
            setVisibleInterest(false);
          }}
          secondButtonText={'ביטול'}
          onClose={() => setVisibleInterest(false)}
        />
      )}
    </>
  );
}
