import React, { useState, useRef } from 'react';

function DraggableList({ children }) {
  const [dragging, setDragging] = useState(false);
  const listRef = useRef();

  const handleMouseDown = (e) => {
    setDragging(true);
    listRef.current.style.cursor = 'grabbing';
    listRef.current.startY = e.clientY - listRef.current.scrollTop;
  };

  const handleMouseUp = () => {
    setDragging(false);
    listRef.current.style.cursor = 'grab';
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const newY = e.clientY;
      const diff = listRef.current.startY - newY;
      listRef.current.scrollTop += diff;
      listRef.current.startY = newY;
    }
  };

  return (
    <div
      ref={listRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      style={{ overflowY: 'auto', cursor: 'grab', height: '100%' }}
    >
      {children}
    </div>
  );
}

export default DraggableList;
