import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HomeRouterCompany from './routers/HomeRouterCompany';
import HomeRouterEmployee from './routers/HomeRouterEmployee';
import { getLegals, getList, getUserDetails } from './store/actions';
import CompanyRegister from './screens/Company/Register';
import EmployeeRegister from './screens/Employee/Register';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import EmployeeLogin from './screens/Employee/EmployeeLogin';
import CompanyLogin from './screens/Company/CompanyLogin';

function App() {
  const currentUser = useSelector((state) => state.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLegals());
    dispatch(getList());
    const userData = localStorage.getItem('userData');
    const data = JSON.parse(userData);
    if (data) {
      if (window.location.href.includes('/company-login')) window.location.href = '/company';
      if (window.location.href.includes('/employee-login')) window.location.href = '/employee';
      if (
        (data?.type?.id === 3 && window.location.href.includes('/company')) ||
        (data?.type?.id === 2 && window.location.href.includes('/employee'))
      ) {
        dispatch(getUserDetails(data?.id, data?.token));
      } else {
        dispatch({ type: 'signOut' });
        localStorage.clear();
        sessionStorage.clear();
      }
    }
    if (
      (currentUser?.type?.id === 3 && window.location.href.includes('/company')) ||
      (currentUser?.type?.id === 2 && window.location.href.includes('/employee'))
    ) {
      dispatch(getUserDetails(currentUser?.id, currentUser?.token));
    } else {
      dispatch({ type: 'signOut' });
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='*'
          element={
            <Navigate
              to={
                window.location.href.includes('/company')
                  ? '/company'
                  : window.location.href.includes('/employee')
                  ? '/employee'
                  : '/employee-login'
              }
              replace
            />
          }
        />

        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password/:userId' element={<ResetPassword />} />
        <Route path='/company-login' element={<CompanyLogin />} />
        <Route path='/employee-login' element={<EmployeeLogin />} />

        <Route path='/register-company' element={<CompanyRegister />} />
        <Route path='/register-employee' element={<EmployeeRegister />} />

        {(currentUser?.type?.id === 3 || currentUser === null) && (
          <Route path='/company/*' element={<HomeRouterCompany />} />
        )}

        {(currentUser?.type?.id === 2 || currentUser === null) && (
          <Route path='/employee/*' element={<HomeRouterEmployee />} />
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
