import React, { useEffect, useState } from 'react';
import { Header, MobileHeader } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import {
  ic1stDegree,
  icClothes,
  icCouch,
  icFrame,
  icJobCard,
  icJobCover,
  icLength,
  icLogin,
  icOffice,
  icSchedule,
  icStepComplete,
  icUmbrella,
  icVolume,
  icCircle,
  icRect,
} from '../../../assets/images/images';
import EmployeeDetails from './EmployeeDetails';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeJobRole from './EmployeeJobRole';
import EmployeeEducation from './EmployeeEducation';
import EmployeeAdditional from './EmployeeAdditional';
import EmployeeVideo from './EmployeeVideo';
import useFetchList from '../../../hooks/useFetchList';
const stepsMap = {
  step1: 'יצירת פרופיל',
  step2: 'אופי וכישורים',
  step3: 'תפקיד מבוקש',
  step4: 'השכלה',
  step5: 'צילום סרטון',
};
export default function EmployeeRegister() {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const showCover = useMediaQuery({ query: '(max-width: 1170px)' });
  const currentUser = useSelector((state) => state.userData);
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState(null);
  const [visible, setVisible] = useState(0);
  const dispatch = useDispatch();
  useFetchList();

  const renderSteps = () => {
    console.log('step', step);
    switch (step) {
      case 1:
        return (
          <EmployeeDetails
            data={info}
            handleNext={(data) => {
              setStep(2);
            }}
          />
        );
      case 2:
        return (
          <EmployeeAdditional
            location={location}
            data={info}
            handleNext={(data, next) => {
              const tmpData = {
                ...info,
                ...data,
              };
              setInfo(tmpData);
              next && setStep(3);
            }}
            handleBack={(data, next) => {
              const tmpData = {
                ...info,
                ...data,
              };
              setInfo(tmpData);
              setStep(2);
            }}
          />
        );
      case 3:
        return (
          <EmployeeJobRole
            location={location}
            data={info}
            handleNext={(data, next) => {
              const tmpData = {
                ...info,
                ...data,
              };
              setInfo(tmpData);
              next && setStep(4);
            }}
            handleBack={(data, next) => {
              const tmpData = {
                ...info,
                ...data,
              };
              setInfo(tmpData);
              setStep(2);
            }}
          />
        );
      case 4:
        return (
          <EmployeeEducation
            location={location}
            data={info}
            handleNext={(data, next) => {
              const tmpData = {
                ...info,
                ...data,
              };
              setInfo(tmpData);
              next && setStep(5);
            }}
            handleBack={(data) => {
              const tmpData = {
                ...info,
                ...data,
              };
              setInfo(tmpData);
              setStep(3);
            }}
          />
        );
      case 5:
        return (
          <EmployeeVideo
            location={location}
            data={info}
            handleStep={(isVisible) => setVisible(isVisible)}
            handleBack={(data, next) => {
              const tmpData = {
                ...info,
                ...data,
              };
              setInfo(tmpData);
              setStep(4);
            }}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    if (currentUser?.type?.id === 3) {
      dispatch({ type: 'signOut' });
    } else if (currentUser?.type?.id === 2) {
      setStep(2);
    }
  }, [currentUser]);

  return (
    <>
      {isTabletOrMobile ? <MobileHeader type={'login'} /> : <Header />}
      <div className='d-flex justify-content-start'>
        {/* <img alt="" width="20" height="20" style={{ left: 27, top: 90, position: 'absolute' }} onClick={() => navigate('/company')} src={icClose} /> */}
        {!showCover && (
          <>
            {step === 5 && visible !== 2 ? (
              <div className='video-cover'>
                <span style={{ fontSize: 32, color: '#f8f8ff', fontWeight: 'bold' }}>דגשים לצילום</span>
                <span>הגיע הזמן לצאת מבין השורות היבשות של קורות החיים ולהכיר את האדם שמאחוריהם - אותך!</span>
                <div style={{ marginTop: 48 }} className='d-flex align-items-center'>
                  <img alt='icon' src={icCouch} />
                  <span style={{ paddingRight: 12 }}>
                    דאג <b>שיהיה לך נוח</b> - מצא מקום בו יש לך פרטיות וצלם את הסרטון כשיש לך זמן וכשאתה לא לחוץ
                  </span>
                </div>
                <div style={{ marginTop: 48 }} className='d-flex align-items-center'>
                  <img alt='icon' src={icVolume} />
                  <span style={{ paddingRight: 12 }}>
                    צלם <b>במקום שקט</b> ודאג ל<b>תאורה נכונה</b> - חשוב שישמעו אותך ושיראו אותך באופן ברור
                  </span>
                </div>
                <div style={{ marginTop: 48 }} className='d-flex align-items-center'>
                  <img alt='icon' src={icFrame} />
                  <span style={{ paddingRight: 12 }}>
                    <b>שים לב לרקע</b> שמאחוריך - רקע מסודר מייצר רושם ראשוני חיובי, כמו כן רקע ללא הסחות דעת שומר את
                    הפוקוס עליך
                  </span>
                </div>
                <div style={{ marginTop: 48 }} className='d-flex align-items-center'>
                  <img alt='icon' src={icClothes} />
                  <span style={{ paddingRight: 12 }}>
                    לבש <b>בגדים המייצגים</b> את המשרה אליה אתה רוצה להתקבל
                  </span>
                </div>
                <div style={{ marginTop: 48 }} className='d-flex align-items-center'>
                  <img alt='icon' src={icLength} />
                  <span style={{ paddingRight: 12 }}>
                    אורך הסרטון הוא עד 3 דקות, חשוב להיות <b>ממוקדים</b> בעיקר
                  </span>
                </div>
                <div style={{ marginTop: 48 }} className='d-flex align-items-center'>
                  <img alt='icon' src={icSchedule} />
                  <span style={{ paddingRight: 12 }}>
                    <b>תכנן</b> מה אתה רוצה לומר מראש - מהן החוזקות שלך, מה מייחד אותך ומי אתה בכללי
                  </span>
                </div>
                <div style={{ marginTop: 48 }} className='d-flex align-items-center'>
                  <img alt='icon' src={icUmbrella} />
                  <span style={{ paddingRight: 12 }}>
                    והכי חשוב, אתה יכול לצלם את הסרטון <b>כמה פעמים שתרצה</b>, ולהחליף אותו בכל זמן בעתיד אז חייך והתנהג
                    בטבעיות
                  </span>
                </div>
              </div>
            ) : step === 1 ? (
              <img alt='' src={icLogin} />
            ) : (
              <div
                style={{
                  fontSize: 14,
                  textAlign: 'right',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  width: 700,
                  backgroundImage: `url(${icJobCover})`,
                  backgroundRepeat: 'repeat',
                }}
              >
                <img alt='' style={{ paddingLeft: 50 }} src={icJobCard} />
                {info && (
                  <div style={{ position: 'absolute', top: 480, marginRight: 10, width: 350 }}>
                    <div style={{ width: '90%', position: 'absolute' }}>
                      <span style={{ color: '#827aa5' }}>מחפש\ת את התפקידים:</span>
                      {info?.jobRoleExperiencesValues?.map((item, index) => (
                        <div key={index}>
                          {item.jobRole.label} {item.experience.label} שנות ניסיון
                        </div>
                      ))}
                      {info?.previousCompanyValues && info?.previousCompanyValues[0]?.companyName?.label !== '' && (
                        <>
                          <div style={{ color: '#827aa5', marginTop: 12 }}>
                            {' '}
                            <img alt='icon' width='28' height='28' src={icOffice} /> נסיון תעסוקתי
                          </div>
                          {info?.previousCompanyValues?.map((item, index) => (
                            <div key={index} className='d-flex align-items-center'>
                              <div>{`${item.jobRoles.label} ב${item.companyName.label}`} &nbsp;</div>
                              <span style={{ color: '#a7abb2', fontSize: 12 }}>
                                {' '}
                                {`${item.fromYear.label} - ${item.toYear.label}`}
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                      {info?.educationsValues && info?.educationsValues[0]?.educationId?.id !== null && (
                        <>
                          <div style={{ color: '#827aa5', marginTop: 12 }}>
                            {' '}
                            <img alt='icon' width='28' height='28' src={ic1stDegree} />
                            השכלה{' '}
                          </div>
                          {info?.educationsValues?.map((item, index) => (
                            <div key={index} className='d-flex align-items-center'>
                              <div>
                                {`${item.educationId.value} ${item.text1.label ? `ב - ${item.text1.label}` : ''}`}{' '}
                                &nbsp;
                              </div>
                              <span style={{ color: '#a7abb2', fontSize: 12 }}> {`${item.text2.label}`}</span>
                            </div>
                          ))}
                        </>
                      )}
                      {info?.characteristics && (
                        <div style={{ marginTop: 20 }} className='d-flex flex-wrap'>
                          {info.characteristics?.map(
                            (item, index) =>
                              index < 3 && (
                                <div
                                  key={index}
                                  style={{
                                    margin: '5px 0px 5px 5px',
                                    color: '#017b96',
                                    fontSize: 14,
                                    borderRadius: 20,
                                    backgroundColor: '#eafffd',
                                    padding: '4px 16px',
                                  }}
                                >
                                  {item.label} #
                                </div>
                              )
                          )}
                        </div>
                      )}
                      {info?.interests && (
                        <div style={{ marginTop: 12 }} className='d-flex flex-wrap'>
                          {info?.interests?.map(
                            (item, index) =>
                              index < 3 && (
                                <div
                                  key={index}
                                  style={{
                                    margin: '5px 0px 5px 5px',
                                    color: '#017b96',
                                    fontSize: 14,
                                    borderRadius: 20,
                                    backgroundColor: '#eafffd',
                                    padding: '4px 16px',
                                  }}
                                >
                                  {item.label} #
                                </div>
                              )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <div
          style={{
            marginTop: 0,
            paddingTop: isTabletOrMobile ? 0 : '60px',
            margin: step === 5 && visible === 1 ? '0 auto' : !isTabletOrMobile ? '0px 7vw 0px 0px' : '0 auto',
          }}
          className='job-screen'
        >
          {visible === 1 ? null : (
            <div
              style={{ flexWrap: 'wrap', gap: 5, width: '100%', justifyContent: 'start' }}
              className='step d-flex align-items-center'
            >
              {isTabletOrMobile ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    //gap: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    marginBottom: 20,
                  }}
                >
                  {Object.values(stepsMap).map((v, index) => {
                    return (
                      <div
                        style={{
                          height: 50,
                          width: 38,
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span>
                          {index + 1 === step && <img alt='icon' src={icRect} />}
                          {index + 1 !== step && <img alt='icon' src={icCircle} />}
                        </span>
                        {index + 1 === step && (
                          <span
                            style={{
                              color: '#2f2072',
                              fontSize: 12,
                              textAlign: 'center',
                            }}
                          >
                            {v}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    gap: 15,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  {step > 1 ? (
                    <img alt='icon' src={icStepComplete} />
                  ) : (
                    <div className='circle'>
                      <span style={{ margin: '0 auto', color: '#f7f5ff', fontWeight: 500 }}>1</span>
                    </div>
                  )}
                  <span style={{ color: '#2f2072', fontSize: 12, width: 70 }}>{stepsMap.step1}</span>
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => step > 1 && setStep(2)}
                  >
                    <div
                      style={{ backgroundColor: step === 2 ? '#4f35be' : '#f7f5ff', border: '2.2px solid #4f35be' }}
                      className='circle'
                    >
                      <span style={{ margin: '0 auto', color: step === 2 ? '#f7f5ff' : '#4f35be', fontWeight: 500 }}>
                        2
                      </span>
                    </div>
                  </div>
                  <span style={{ color: '#2f2072', fontSize: 12, width: 70 }}>{stepsMap.step2}</span>
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => step > 1 && setStep(3)}
                  >
                    <div
                      style={{ backgroundColor: step === 3 ? '#4f35be' : '#f7f5ff', border: '2.2px solid #4f35be' }}
                      className='circle'
                    >
                      <span style={{ margin: '0 auto', color: step === 3 ? '#f7f5ff' : '#4f35be', fontWeight: 500 }}>
                        3
                      </span>
                    </div>
                  </div>
                  <span style={{ color: '#2f2072', fontSize: 12, width: 70 }}>{stepsMap.step3}</span>
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => step > 1 && setStep(4)}
                  >
                    <div
                      style={{ backgroundColor: step === 4 ? '#4f35be' : '#f7f5ff', border: '2.2px solid #4f35be' }}
                      className='circle'
                    >
                      <span style={{ margin: '0 auto', color: step === 4 ? '#f7f5ff' : '#4f35be', fontWeight: 500 }}>
                        4
                      </span>
                    </div>
                  </div>
                  <span style={{ color: '#2f2072', fontSize: 12, width: 70, textAlign: 'center' }}>
                    {stepsMap.step4}
                  </span>
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => step > 1 && setStep(5)}
                  >
                    <div
                      style={{ backgroundColor: step === 5 ? '#4f35be' : '#f7f5ff', border: '2.2px solid #4f35be' }}
                      className='circle'
                    >
                      <span style={{ margin: '0 auto', color: step === 5 ? '#f7f5ff' : '#4f35be', fontWeight: 500 }}>
                        5
                      </span>
                    </div>
                  </div>
                  <span style={{ color: '#2f2072', fontSize: 12, width: 70 }}>{stepsMap.step5}</span>
                </div>
              )}
            </div>
          )}
          {renderSteps()}
        </div>
      </div>
    </>
  );
}
