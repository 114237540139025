import moment from "moment";

export const isAbove18 = (dateString) => {
  const dob = moment(dateString, 'DD/MM/YYYY');
  const today = moment();
  const age = today.diff(dob, 'years');
  return age >= 18;
}

export const validatePhoneNum = (text) => {
  if (text.length === 9 || text.length === 10) {
    return false;
  } else {
    return true;
  }
};

export const validateEmail = (text) => {
  let reg = /([+\w0-9._-]+@[\w0-9._-]+\.[\w0-9_-]+)/


  if (reg.test(text) === false) {
    return true;
  } else {
    return false;
  }
};


export const checkStrongPassword = (password) => {
  var pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
  return pattern.test(password);
};

export function copy(url) {
  const el = document.createElement('input');
  el.value = url;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

}


export const numberWithCommas = (x) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

export function getOS() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
  } else if (/Android/.test(userAgent)) {
      os = 'Android';
  } else if (/Linux/.test(platform)) {
      os = 'Linux';
  }

  return os;
}