import React, { useEffect, useState } from 'react';
import { icContract, icError, icEye, icSearch } from '../../../assets/images/images';
import Autocomplete from 'react-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, TextInput, LoadingModal } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import URL from '../../../api/urls';
import { createCompany, getUserDetails, saveToFavCompany, updateUserDevice } from '../../../store/actions';
import { getOS, validatePhoneNum } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { Terms, Dialog } from '../../../components';
import axios from 'axios';
import { checkStrongPassword } from '../../../utils/validator';
import PwdConstrains from '../../../components/PwdConstrains';
import isEmail from 'validator/es/lib/isEmail';
import isNull from 'lodash/isNull';
import trim from 'validator/es/lib/trim';
import isNumeric from 'validator/es/lib/isNumeric';

export default function CompanyDetails({ handleNext }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.listDetails);
  const [isFetching, setIsFetching] = useState(false);

  const [company, setCompany] = useState({ label: '', id: null });
  const [companyName, setCompanyName] = useState('');

  const [city, setCity] = useState({ id: null, label: '' });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [validatePassword, setValidatePassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [showPassValid, setShowPassValid] = useState(false);

  const [cityList, setCityList] = useState([]);
  const [israeliCompany, setIsraeliCompany] = useState([]);

  const [validCity, setValidCity] = useState(false);
  const [validNCompanyName, setValidNCompanyName] = useState(false);
  const [validFirstName, setValidFirstName] = useState('');
  const [validLastName, setValidLastName] = useState('');
  const [validNCompany, setValidNCompany] = useState(false);

  const [validPhone, setValidPhone] = useState('');
  const [validEmail, setValidEmail] = useState(null);
  const [, setValidTerms] = useState(false);

  const [terms, setTerms] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const [validPassword, setValidPassword] = useState('');
  const likes = useSelector((state) => state.likes);
  const maybes = useSelector((state) => state.maybes);

  useEffect(() => {
    const tmpCity = [];
    list?.city.forEach((city) => tmpCity.push({ id: city.id, label: city.itemTextHeb }));
    setCityList(tmpCity);
  }, [list]);

  const searchIsraeliCompany = async (value) => {
    const tmpIsraeli = [];
    const response = await fetch(`${URL.GET_ISRAELI_COMPANY}/${value}`);
    const data = await response.json();
    data?.result?.result?.map((company) => tmpIsraeli.push({ id: company.id, label: company.companyBn }));
    setIsraeliCompany(tmpIsraeli);
  };

  const handleSubmit = () => {
    if (company?.id === null) {
      setValidNCompany(true);
    }
    if (city?.id === null) {
      setValidCity(true);
    }
    if (companyName === '') {
      setValidNCompanyName(true);
    }
    if (firstName === '') {
      setValidFirstName(true);
    }
    if (lastName === '') {
      setValidLastName(true);
    }
    const validNumber = validatePhoneNum(phone);
    if (phone === '' || validNumber) {
      setValidPhone(true);
    }
    const valid = isEmail(email);

    setValidEmail(!!!(!valid || email === ''));

    if (!checkStrongPassword(password) || password === '' || validatePassword === '' || validatePassword !== password) {
      setValidPassword(true);
    }
    if (!terms) {
      setValidTerms(true);
    }
    if (
      company?.id !== null &&
      city?.id !== null &&
      companyName !== '' &&
      firstName !== '' &&
      lastName !== '' &&
      phone !== '' &&
      !validNumber &&
      email !== '' &&
      !!valid &&
      password !== '' &&
      validatePassword !== '' &&
      validatePassword === password &&
      checkStrongPassword(password)
    ) {
      setIsFetching(true);
      const formData = new FormData();
      formData.append('name', companyName);
      formData.append('israeliCompany', company.id);
      formData.append('city', city.id);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('password', password);
      formData.append('isFinishedRegistration', true);
      formData.append('type', 1);

      dispatch(createCompany(formData))
        .then(async (res) => {
          if (res.status === 200) {
            //const response = await axios.get('https://api.my-ip.io/v2/ip.json');
            const formData1 = new FormData();
            formData1.append('os', getOS());
            formData1.append('ip', null);
            formData1.append('deviceId', '1');
            formData1.append('pushToken', 'sometoken');
            dispatch(updateUserDevice(formData1, res.data.result?.accessToken));
            dispatch(getUserDetails(res.data.result?.user?.id, res.data.result?.accessToken))
              .then((response) => {
                dispatch({ type: 'GET_EMPLOYEES_REQUEST' });

                if (likes.length > 0 || maybes.length > 0) {
                  likes.forEach((like) => {
                    const formData = new FormData();
                    formData.append('companyId', response.data.result.companyTeams[0].company.id);
                    formData.append('employeeId', like.id);
                    formData.append('like', true);
                    formData.append('companyUserId', res.data.result?.user?.id);

                    dispatch(saveToFavCompany(formData, res.data.result?.accessToken, true));
                  });
                  maybes.forEach((maybe) => {
                    const formData = new FormData();
                    formData.append('companyId', response.data.result.companyTeams[0].company.id);
                    formData.append('employeeId', maybe.id);
                    formData.append('like', true);
                    formData.append('companyUserId', res.data.result?.user?.id);

                    dispatch(saveToFavCompany(formData, res.data.result?.accessToken, true));
                  });
                }
                setIsFetching(false);
                setIsVisible(true);
                const user = {
                  ...response.data.result,
                  token: res.data.result?.accessToken,
                };

                sessionStorage.setItem('userData', JSON.stringify(user));
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => {
          console.log(err);
          setIsFetching(false);

          if (err.response.data.message === 'There is existing contract for this company') setIsError(true);

          if (err.response.data.message.includes('User exists')) {
            setUserExists(true);
            setTimeout(() => {
              setUserExists(false);
            }, 3000);
          }
        });
    }
  };

  console.log('validEmail ==>', validEmail);

  return (
    <>
      <span
        style={{
          fontSize: isTabletOrMobile ? '24px' : '32px',
          textAlign: 'right',
          fontWeight: 'bold',
          marginTop: isTabletOrMobile ? '16px' : '60px',
        }}
      >
        {isTabletOrMobile ? 'הרשמה למערכת' : 'ממש בעוד רגע נמצא לך את העובד/ת המושלמ/ת!'}
      </span>
      <span style={{ fontSize: '18px', marginTop: '24px' }}>פרטי חברה</span>
      <div style={{ flexWrap: 'wrap', flexDirection: isTabletOrMobile ? 'column' : 'row' }} className='d-flex'>
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${validNCompanyName ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={companyName}
          onChange={(value) => {
            setCompanyName(value);
            setValidNCompanyName(false);
          }}
          label={'שם החברה *'}
        />
        <div style={{ position: 'relative' }}>
          <Autocomplete
            inputProps={{
              style: {
                border: `1px solid ${validNCompany ? 'red' : '#D4DAF8'}`,
              },
              className: 'auto-complete icon',
              placeholder: 'ח.פ. *',
            }}
            value={company.label}
            items={israeliCompany}
            shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
            wrapperStyle={{ position: 'relative' }}
            getItemValue={(item) => item.label}
            onSelect={(value, item) => {
              setCompany(item);
              setValidNCompany(false);
            }}
            onChange={(event, value) => {
              if (event != null && value != null && event.target.value.length >= 5) searchIsraeliCompany(value);
              else setIsraeliCompany([]);

              setValidNCompany(false);
              setCompany({ id: null, label: value });
            }}
            renderMenu={(items) => <div className='menu'>{items}</div>}
            renderItem={(item) => <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}</p>}
            renderInput={(props) => (
              <>
                {company.label === '' && (
                  <img alt='' style={{ zIndex: 1, position: 'absolute', top: 24, right: 12 }} src={icSearch} />
                )}
                <input {...props} />
              </>
            )}
          />
        </div>
      </div>
      <Autocomplete
        inputProps={{
          style: {
            marginTop: 16,
            border: `1px solid ${validCity ? 'red' : '#D4DAF8'}`,
          },
          className: 'auto-complete icon',
          placeholder: 'עיר *',
        }}
        value={city.label}
        items={cityList}
        shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
        getItemValue={(item) => item.label}
        wrapperStyle={{ position: 'relative' }}
        onSelect={(value, item) => {
          setCity(item);
          setValidCity(false);
        }}
        onChange={(event, value) => {
          setCity({ id: null, label: value });
          setValidCity(false);
        }}
        renderMenu={(items, value, style) => <div className='menu'>{items}</div>}
        renderItem={(item, isHighlighted) => <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}</p>}
        renderInput={(props) => (
          <>
            {city.label === '' && (
              <img alt='' style={{ zIndex: 1, position: 'absolute', top: 31, right: 12 }} src={icSearch} />
            )}
            <input {...props} />
          </>
        )}
      />
      <span style={{ fontSize: '18px', marginTop: '24px' }}>פרטי התקשרות</span>
      <div style={{ flexDirection: isTabletOrMobile ? 'column' : 'row', flexWrap: 'wrap' }} className='d-flex'>
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${validFirstName ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={firstName}
          onChange={(value) => {
            setFirstName(value);
            setValidFirstName(false);
          }}
          label={'איש קשר - שם פרטי *'}
        />
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${validLastName ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={lastName}
          onChange={(value) => {
            setLastName(value);
            setValidLastName(false);
          }}
          label={'איש קשר - שם משפחה *'}
        />
      </div>
      <div style={{ flexDirection: isTabletOrMobile ? 'column' : 'row', flexWrap: 'wrap' }} className='d-flex'>
        <div className='d-flex'>
          <TextInput
            style={{
              marginRight: 0,
              marginLeft: 12,
              border: `1px solid ${validPhone ? 'red' : '#D4DAF8'}`,
            }}
            className='auto-complete'
            value={phone}
            onChange={(value) => {
              setPhone((prev) => {
                return isNumeric(value) ? trim(value) : value === '' ? '' : prev;
              });
              setValidPhone(false);
            }}
            label={'טלפון * (יש להזין ספרות בלבד)'}
          />
        </div>
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${(!isNull(validEmail) && !validEmail) || userExists ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={email}
          onChange={(value) => {
            setEmail(trim(value));
            setValidEmail(null);
          }}
          label={'אימייל *'}
        />
      </div>
      {userExists && (
        <div
          style={{
            display: 'flex',
            marginTop: 10,
            fontWeight: '500',
            alignItems: 'center',
            textAlign: 'right',
            height: '41px',
            color: '#ff2525',
            border: '1px solid',
            borderColor: '#ff2525',
            borderRadius: 4,
            padding: '10px 12px',
          }}
        >
          <img alt='' src={icError} />
          <span style={{ paddingRight: 10, fontSize: 14 }}>האימייל קיים במערכת, נא צור קשר לתמיכה</span>
        </div>
      )}
      <span style={{ fontSize: '18px', marginTop: '24px' }}>יצירת סיסמה</span>
      <PwdConstrains />
      <div
        style={{ flexDirection: isTabletOrMobile ? 'column' : 'row', flexWrap: 'wrap' }}
        className='d-flex position-relative'
      >
        <div className='position-relative'>
          <TextInput
            style={{
              marginRight: 0,
              marginLeft: 16,
              border: `1px solid ${validPassword ? 'red' : '#D4DAF8'}`,
            }}
            className='auto-complete'
            value={password}
            security={!showPass}
            onChange={setPassword}
            label={'סיסמה *'}
          />
          <div
            style={{ cursor: 'pointer', position: 'absolute', left: 25, top: 20 }}
            onClick={() => setShowPass(!showPass)}
          >
            <img src={icEye} alt='icEye' />
          </div>
        </div>
        <div className='position-relative'>
          <TextInput
            style={{
              marginRight: 0,
              marginLeft: 16,
              border: `1px solid ${validPassword ? 'red' : '#D4DAF8'}`,
            }}
            className='auto-complete'
            value={validatePassword}
            security={!showPassValid}
            onChange={(value) => {
              setValidatePassword(value);
              setValidPassword(false);
            }}
            label={'אישור סיסמה *'}
          />
          <div
            style={{ cursor: 'pointer', position: 'absolute', left: 25, top: 20 }}
            onClick={() => setShowPassValid(!showPassValid)}
          >
            <img src={icEye} alt='iceEye' />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 24 }}>
        <Checkbox
          checked={terms}
          onChange={() => setTerms(!terms)}
          handleClick={() => setOpenTerms(true)}
          className='terms'
          label='קראתי והבנתי את תנאי השימוש באתר ומדיניות הגנת הפרטיות של החברה והם מקובלים עלי.'
        />
      </div>
      {(validNCompanyName ||
        validNCompany ||
        validCity ||
        validFirstName ||
        validLastName ||
        validPhone ||
        (!isNull(validEmail) && !validEmail)) && (
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            fontWeight: '500',
            alignItems: 'center',
            textAlign: 'right',
            height: '41px',
            color: '#ff2525',
            border: '1px solid',
            borderColor: '#ff2525',
            borderRadius: 4,
            padding: '10px 12px',
          }}
        >
          <img alt='error' src={icError} />
          <span style={{ paddingRight: 10, fontSize: 14 }}>יש לוודא שכל הפרטים הוזנו כשורה</span>
        </div>
      )}
      {validPassword && (
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            fontWeight: '500',
            alignItems: 'center',
            textAlign: 'right',
            color: '#ff2525',
            border: '1px solid',
            borderColor: '#ff2525',
            borderRadius: 4,
            padding: '10px 12px',
          }}
        >
          <img alt='error' src={icError} />
          <span style={{ paddingRight: 10, fontSize: 14 }}>
            הסיסמה חייבת להיות באורך של לפחות 8 תווים, עם לפחות אות אחת גדולה, אות אחת קטנה, וספרה אחת
          </span>
        </div>
      )}
      {/* {validNCompany && <div
                style={{ display: 'flex', marginTop: 20, fontWeight: '500', alignItems: 'center', textAlign: 'right', height: '41px', color: '#ff2525', border: '1px solid', borderColor: '#ff2525', borderRadius: 4, padding: '10px 12px' }}>
                <img alt="" src={icError} />
                <span style={{ paddingRight: 10, fontSize: 14 }}>הח.פ. שגוי. אנא בדוק את הח.פ. שלך ונסה שוב</span>
            </div>} */}

      <div style={{ marginTop: '40px', position: 'relative', marginRight: 0, marginBottom: 20 }} className='d-flex row'>
        <Button
          style={{
            opacity: !terms ? '0.5' : '1',
            pointerEvents: !terms ? 'none' : 'all',
          }}
          onPress={() => handleSubmit()}
          text={'הרשם'}
        />
        <Button
          style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }}
          onPress={() => navigate('/company')}
          text={'המשך כאורח'}
        />
      </div>
      {openTerms && (
        <Terms
          title={'תנאי השימוש'}
          firstButtonText={'אני מסכים'}
          onClose={() => setOpenTerms(false)}
          firstButtonHandler={() => {
            setTerms(true);
            setOpenTerms(false);
          }}
          secondButtonText={'ביטול'}
          secondButtonHandler={() => setOpenTerms(false)}
        />
      )}
      {isVisible && (
        <Dialog
          image={icContract}
          title={'ברגעים אלו נשלח למייל שלך חוזה התקשרות'}
          text={'רק ברגע שהוא ישלח אלינו חתום תסתיים ההרשמה ויהיה אפשר לפרסם את המשרות שבנית וליצור התאמות עם מועמדים'}
          subText={'עד אז, אפשר להמשיך ליצור משרות ולצפות במועמדים.'}
          firstButtonText={'המשך בהרשמה'}
          secondButtonText={'חזור לדף הבית'}
          onClose={() => setIsVisible(false)}
          firstButtonHandler={() => handleNext()}
          secondButtonHandler={() => navigate('/company')}
        />
      )}
      {isError && (
        <Dialog
          title={'טוב לראות אותך שוב!'}
          text={'לפי הנתונים שלנו כבר קיים חוזה התקשרות עם החברה שלך'}
          subText={'האם *שם החברה*, ח.פ *ח.פ החברה*, היא החברה הנכונה?'}
          firstButtonText={'אישור'}
          secondButtonText={'לא, ערוך ח.פ.'}
          onClose={() => setIsError(false)}
          firstButtonHandler={() => setIsError(false)}
          secondButtonHandler={() => setIsError(false)}
        />
      )}
      <LoadingModal visible={isFetching} />
    </>
  );
}
