import React, { useCallback, useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { homeRoutesCompany } from './routes'
import { Header, MobileHeader, MobileSideMenu, SideMenu } from '../components'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'

const HomeRouterCompany = () => {
    const company = useSelector(state => state.company)
    const currentUser = useSelector(state => state.userData)


    const location = useLocation()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        const { pathname } = location
        if (currentUser !== undefined && currentUser !== null) {
            if (currentUser?.type?.id === 2) {
                dispatch({ type: 'signOut' })
                navigate('/company')
            }

            else {
                if (!currentUser) {
                    navigate('/company-login', { state: { redirect: pathname } })
                }
                if (currentUser && (pathname === '/company-login')) {
                    navigate(pathname)

                }
            }

        }
        else {
            //navigate('/company')
        }

    }, [])



    return (
        <React.Fragment>
            {isTabletOrMobile ?  <MobileHeader /> : <Header />}
            <div style={{position: 'relative' }} className={`d-flex flex-${isTabletOrMobile ? 'column' : 'row'} home-container`}>

                {!isTabletOrMobile && <SideMenu type={'company'} />}
                <Routes>
                    {homeRoutesCompany.map((route) => (
                        <Route key={route.path} path={route.path} element={<route.component />} />
                    ))}
                </Routes>
            </div>
        </React.Fragment>
    )
}

export default HomeRouterCompany
