import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  icClose,
  icDefault,
  icExitIcon,
  icLogo,
  icNotification,
  icNotificationRed,
  icProfileIcon,
  icQuestion,
} from '../assets/images/images';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from './Dialog';
import { getNotif, readNotif } from '../store/actions';
import socketIO from 'socket.io-client';

import Notifications from './Notifications';
import config from '../config';

export default function Header() {
  const currentUser = useSelector((state) => state.userData);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleNotif, setIsVisibleNotif] = useState(false);
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [isRead, setIsRead] = useState(null);

  const location = useLocation();
  const userType = location?.pathname.split('/')[1];
  const company = useSelector((state) => state.company);
  const [notifications, setNotifications] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userImage, setUserImage] = useState(null);
  const [trigger, setTrigger] = useState(false);

  const isCompany = currentUser?.type?.id === 3;
  useEffect(() => {
    setUserImage(isCompany ? company?.company?.logo : currentUser?.image);
  }, [currentUser]);

  const handleLogout = () => {
    isCompany ? navigate('/company-login') : navigate('/employee-login');
    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: 'signOut' });
  };

  const handleReadAll = () => {
    dispatch(readNotif(currentUser?.token, null)).then(() => {
      setTrigger(!trigger);
    });
  };

  const handleNavigate = (notification) => {
    const data = {
      ids: [notification?.id],
    };
    dispatch(readNotif(currentUser?.token, data)).then(() => {
      setTrigger(!trigger);
    });
    switch (notification.type.type) {
      case 'new match':
        dispatch({ type: 'setTab', payload: 1 });
        dispatch({ type: 'setTabMatch', payload: 1 });
        isCompany ? navigate('/company/candidates') : navigate('/employee/candidates');
        break;
      case 'employee cancel interview':
      case 'company cancel interview':
      case 'new interview scheduled':
      case 'employee interview starts now':
      case 'company interview starts now':
        dispatch({ type: 'setTab', payload: 2 });
        dispatch({ type: 'setTabMatch', payload: 2 });
        isCompany ? navigate('/company/candidates') : navigate('/employee/candidates');
        break;
      case 'employee request new dates for inteview':
        dispatch({ type: 'setTab', payload: 2 });
        dispatch({ type: 'setTabMatch', payload: 2 });
        isCompany ? navigate('/company/candidates', { state: 2 }) : navigate('/employee/candidates');
      default:
        break;
    }
    setIsVisibleNotif(false);
  };
  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getNotif(isCompany ? company?.company?.id : null, currentUser?.token)).then((res) => {
        setNotifications(res);
      });
    }
  }, [currentUser, company, trigger]);

  const myEventHandler = useCallback((socketData) => {
    dispatch(getNotif(company?.company?.id, currentUser?.token)).then((res) => {
      setNotifications(res);
    });
  }, []);

  useEffect(() => {
    const socket = socketIO.connect(config.API_URL, {
      transports: ['websocket'],
    });

    socket.on(`new-notification`, myEventHandler);
    return () => socket.off(`new-notification`, myEventHandler);
  }, []);

  useEffect(() => {
    const unreadNotificationsCount = notifications.filter((item) => !item.isRead).length;
    setIsRead(unreadNotificationsCount);
  }, [notifications]);

  const handleLogoClick = () => {
    if (currentUser === null) {
      if (userType.includes('login')) {
        navigate(`/${userType.includes('employee') ? 'employee' : 'company'}-login`);
        return;
      }
      navigate(`/register-${userType.includes('employee') ? 'employee' : 'company'}`);
    } else {
      navigate(`/${userType.includes('employee') ? 'employee' : 'company'}`);
    }
  };

  return (
    <div
      onMouseLeave={() => {
        setIsVisible(false);
        setIsVisibleNotif(false);
      }}
      className='header'
    >
      <div className='d-flex align-items-center'>
        {currentUser !== null && (
          <>
            <img
              onMouseEnter={() => {
                setIsVisibleNotif(false);
                currentUser && setIsVisible(true);
              }}
              width='34'
              height='34'
              alt=''
              style={{ objectFit: 'cover', borderRadius: 17, marginLeft: 8 }}
              src={userImage?.url ?? icDefault}
            />
            <span style={{ marginLeft: 24 }} className='title-user'>
              {currentUser !== undefined && currentUser !== null ? `שלום, ${currentUser.firstName}` : `שלום, אורח`}
            </span>
            <div className='divider' />
            <div style={{ cursor: 'pointer' }}>
              <img
                alt=''
                onMouseEnter={() => {
                  setIsVisible(false);
                  currentUser && setIsVisibleNotif(true);
                }}
                style={{ marginRight: 24 }}
                src={isRead > 0 ? icNotificationRed : icNotification}
              />
            </div>
            <img
              alt='question'
              onClick={() => window.open('https://nona-jobs.com/contactus', '_blank')}
              style={{ marginRight: 12, cursor: 'pointer' }}
              src={icQuestion}
            />
          </>
        )}
      </div>
      <img style={{ cursor: 'pointer' }} onClick={handleLogoClick} alt='' width='100' height='24' src={icLogo} />
      {isVisible && (
        <div className='account'>
          <img
            alt='close'
            onClick={() => setIsVisible(false)}
            width='20'
            height='20'
            style={{ cursor: 'pointer', alignSelf: 'flex-end', marginTop: 10, marginLeft: 10 }}
            src={icClose}
          />
          <div style={{ display: 'inline-table' }}>
            <img
              width='60'
              height='60'
              alt=''
              style={{ objectFit: 'cover', borderRadius: '50%' }}
              src={userImage?.url ?? icDefault}
            />
          </div>
          <span
            style={{ marginTop: 12, fontSize: 20, color: '#1f0039', fontWeight: 'bold' }}
            className='title-user'
          >{`${currentUser?.firstName} ${currentUser?.lastName}`}</span>
          <span style={{ fontSize: 16, color: '#5233d9' }} className='title-user'>
            {currentUser?.email}
          </span>
          <div style={{ width: '100%', paddingRight: 20, display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginTop: 60, height: 1, backgroundColor: '#d4daf8', width: '100%' }} />
            <div
              onClick={() =>
                currentUser?.type?.id === 3 ? navigate('/company/setting') : navigate('/employee/setting')
              }
              style={{
                cursor: 'pointer',
                padding: 10,
                gap: 12,
                alignItems: 'center',
                display: 'flex',
                color: '#4f35be',
              }}
            >
              <img src={icProfileIcon} /> לפרופיל שלי
            </div>
            <div
              onClick={() => setLogoutVisible(true)}
              style={{
                cursor: 'pointer',
                padding: 10,
                gap: 12,
                alignItems: 'center',
                display: 'flex',
                color: '#4f35be',
              }}
            >
              <img src={icExitIcon} /> התנתק מהחשבון
            </div>
          </div>
        </div>
      )}
      {isVisibleNotif && (
        <Notifications
          setIsVisibleNotif={setIsVisibleNotif}
          handleReadAll={handleReadAll}
          handleNavigate={handleNavigate}
          notification={notifications}
        />
      )}
      {logoutVisible && (
        <div style={{ position: 'absolute', top: '50vh', right: '50%' }}>
          <Dialog
            title={'התנתקות מהחשבון'}
            text={'האם ברצונך להתנתק מהחשבון?'}
            firstButtonText={'כן, התנתק'}
            secondButtonText={'ביטול'}
            onClose={() => setLogoutVisible(false)}
            secondButtonHandler={() => setLogoutVisible(false)}
            firstButtonHandler={() => handleLogout()}
          />
        </div>
      )}
    </div>
  );
}
