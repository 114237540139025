import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL,
    GET_LEGALS_REQUEST,
    GET_LEGALS_SUCCESS,
    GET_LEGALS_FAIL,
    // GET_COMPANIES_REQUEST,
    // GET_COMPANIES_SUCCESS,
    // GET_COMPANIES_FAIL,
    GET_LIST_REQUEST,
    GET_LIST_SUCCESS,
    GET_LIST_FAIL,
    // GET_COMPANY_REQUEST,
    // GET_COMPANY_SUCCESS,
    // GET_COMPANY_FAIL,

    GET_EMPLOYESS_GUEST_REQUEST,
    GET_EMPLOYESS_GUEST_SUCCESS,
    GET_EMPLOYESS_GUEST_FAIL,

    GET_EMPLOYEES_REQUEST,
    GET_EMPLOYEES_SUCCESS,
    GET_EMPLOYEES_FAIL,
    UPDATE_EMPLOYEE_REQUEST,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAIL,
    UPDATE_TEAM_MEMBER_REQUEST,
    UPDATE_TEAM_MEMBER_SUCCESS,
    UPDATE_TEAM_MEMBER_FAIL,

    GET_JOBS_REQUEST,
    GET_JOBS_SUCCESS,
    GET_JOBS_FAIL,
    GET_JOB_REQUEST,
    GET_JOB_SUCCESS,
    GET_JOB_FAIL,
    GET_JOBS_GUEST_REQUEST,
    GET_JOBS_GUEST_SUCCESS,
    GET_JOBS_GUEST_FAIL,
    GET_EMPLOYEE_MATCH_REQUEST,
    GET_EMPLOYEE_MATCH_SUCCESS,
    GET_EMPLOYEE_MATCH_FAIL,
    GET_COMPANY_MATCH_REQUEST,
    GET_COMPANY_MATCH_SUCCESS,
    GET_COMPANY_MATCH_FAIL,
    GET_JOB_LIST_REQUEST,
    GET_JOB_LIST_SUCCESS,
    GET_JOB_LIST_FAIL,
    GET_EMPLOYEE_MAYBE_REQUEST,
    GET_EMPLOYEE_MAYBE_SUCCESS,
    GET_EMPLOYEE_MAYBE_FAIL,
    GET_COMPANY_MAYBE_REQUEST,
    GET_COMPANY_MAYBE_SUCCESS,
    GET_COMPANY_MAYBE_FAIL,
    GET_INTERVIEWS_REQUEST,
    GET_INTERVIEWS_SUCCESS,
    GET_INTERVIEWS_FAIL,
    GET_EMPLOYEE_INTERVIEWS_REQUEST,
    GET_EMPLOYEE_INTERVIEWS_SUCCESS,
    GET_EMPLOYEE_INTERVIEWS_FAIL,
    GET_COMPANY_JOBS_REQUEST,
    GET_COMPANY_JOBS_SUCCESS,
    GET_COMPANY_JOBS_FAIL,
    GET_COMPANY_JOBS_USERS_REQUEST,
    GET_COMPANY_JOBS_USERS_SUCCESS,
    GET_COMPANY_JOBS_USERS_FAIL,
    GET_EMPLOYEE_REQUEST,
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_FAIL,
    GET_NOTIF_REQUEST,
    GET_NOTIF_SUCCESS,
    GET_NOTIF_FAIL
} from './actionTypes';

export const initialState = {
    isFetching: false,
    isDrawerOpen: false,
    userData: null,
    companies: null,
    employees: null,
    jobs: [],
    jobList: null,
    error: null,
    company: null,
    employee: null,
    listDetails: null,
    sidebarShow: true,
    legals: null,
    chosenMenu: 0,
    likes: [],
    maybes: [],
    unlikes: [],
    employeeVideos: [],
    job: null,
    employeeMatches: [],
    companyMatches: [],
    employeeMaybes: [],
    companyMaybes: [],
    currentTab: 1,
    currentTabMatch: 1,
    interviews: [],
    employeeInterviews: [],
    companyJobs: [],
    companyJobUsers: [],
    notification: [],
    step: 0,
};

const homestack = (state = initialState, action) => {
    switch (action.type) {
        case 'signOut':
            return initialState;
        case 'set':
            return {
                ...state,
                sidebarShow: action.payload
            }
        case 'setStep':
            return {
                ...state,
                step: action.payload
            }
        case 'setTab':
            return {
                ...state,
                currentTab: action.payload
            }
        case 'setTabMatch':
            return {
                ...state,
                currentTabMatch: action.payload
            }
        case 'setVideo':
            return {
                ...state,
                employeeVideos: action.payload
            }
        case 'setLikes':
            return {
                ...state,
                likes: action.payload
            }

        case 'setMaybes':
            return {
                ...state,
                maybes: action.payload
            }
        case 'setUnLikes':
            return {
                ...state,
                unlikes: action.payload
            }
        case 'setChosenMenu':
            return {
                ...state,
                chosenMenu: action.payload
            }
        case 'setCompany':
            return {
                ...state,
                company: action.payload
            }
        case 'OPEN_DRAWER':
            return {
                ...state,
                isDrawerOpen: action.payload,
            };
        case 'CLOSE_DRAWER':
            return {
                ...state,
                isDrawerOpen: action.payload,
            };
        case LOGIN_REQUEST:
            return {
                ...state,
                // isFetching: true,
                error: null,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                // isFetching: false,
                userData: action.payload,
                error: null,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                // isFetching: false,
                error: action.error,
            };

        case UPDATE_COMPANY_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
            };
        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: null,
            };
        case UPDATE_COMPANY_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case GET_NOTIF_REQUEST:
            return {
                ...state,
                error: null,
            };
        case GET_NOTIF_SUCCESS:
            return {
                ...state,
                notification: action.payload,
                error: null,
            };
        case GET_NOTIF_FAIL:
            return {
                ...state,
                notification: [],
                error: action.error,
            };
        case GET_INTERVIEWS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
            };
        case GET_INTERVIEWS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                interviews: action.payload,
                error: null,
            };
        case GET_INTERVIEWS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case GET_EMPLOYEE_INTERVIEWS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
            };
        case GET_EMPLOYEE_INTERVIEWS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                employeeInterviews: action.payload,
                error: null,
            };
        case GET_EMPLOYEE_INTERVIEWS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case GET_LIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
            };
        case GET_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                listDetails: action.payload,
                error: null,
            };
        case GET_LIST_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case GET_LEGALS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
            };
        case GET_LEGALS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                legals: action.payload,
                error: null,
            };
        case GET_LEGALS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case GET_EMPLOYEE_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
            };
        case GET_EMPLOYEE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                employee: action.payload,
                error: null,
            };
        case GET_EMPLOYEE_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case GET_COMPANY_JOBS_REQUEST:
            return {
                ...state,
                isFetching: true,
                companyJobs: null,
                error: null,
            };
        case GET_COMPANY_JOBS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                companyJobs: action.payload,
                error: null,
            };
        case GET_COMPANY_JOBS_FAIL:
            return {
                ...state,
                isFetching: false,
                companyJobs: null,
                error: action.error,
            };
        case GET_COMPANY_JOBS_USERS_REQUEST:
            return {
                ...state,
                isFetching: true,
                companyJobUsers: {},
                error: null,
            };
        case GET_COMPANY_JOBS_USERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                companyJobUsers: action.payload,
                error: null,
            };
        case GET_COMPANY_JOBS_USERS_FAIL:
            return {
                ...state,
                isFetching: false,
                companyJobUsers: {},
                error: action.error,
            };
        case GET_EMPLOYESS_GUEST_REQUEST:
            return {
                ...state,
                isFetching: true,
                employees: [],
                error: null,
            };
        case GET_EMPLOYESS_GUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                employees: action.payload,
                error: null,
            };
        case GET_EMPLOYESS_GUEST_FAIL:
            return {
                ...state,
                isFetching: false,
                employees: [],
                error: action.error,
            };
        case GET_JOBS_GUEST_REQUEST:
            return {
                ...state,
                isFetching: true,
                jobs: [],
                error: null
            };
        case GET_JOBS_GUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                jobs: action.payload,
                error: null
            };
        case GET_JOBS_GUEST_FAIL:
            return {
                ...state,
                isFetching: false,
                jobs: [],
                error: action.error
            };
        case GET_EMPLOYEES_REQUEST:
            return {
                ...state,
                employees: null,
                isFetching: true,
                error: null,
            };
        case GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                employees: action.filter ? action.payload : [...state.employees, ...action.payload],
                error: null,
            };
        case GET_EMPLOYEES_FAIL:
            return {
                ...state,
                isFetching: false,
                employees: null,
                error: action.error,
            };
        case GET_JOB_LIST_REQUEST:
            return {
                ...state,
                jobs: [],
                isFetching: true,
                error: null,
            };
        case GET_JOB_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                jobs: action.filter ? action.payload : [...state.jobs, ...action.payload],
                error: null,
            };
        case GET_JOB_LIST_FAIL:
            return {
                ...state,
                isFetching: false,
                jobs: null,
                error: action.error,
            };
        case GET_JOBS_REQUEST:
            return {
                ...state,
                isFetching: true,
                jobList: {},
                error: null,
            };
        case GET_JOBS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                jobList: action.payload,
                error: null,
            };
        case GET_JOBS_FAIL:
            return {
                ...state,
                isFetching: false,
                jobList: {},
                error: action.error,
            };
        case GET_JOB_REQUEST:
            return {
                ...state,
                isFetching: true,
                job: null,
                error: null
            }
        case GET_JOB_SUCCESS:
            return {
                ...state,
                isFetching: false,
                job: action.payload,
                error: null
            }
        case GET_JOB_FAIL:
            return {
                ...state,
                isFetching: false,
                job: null,
                error: action.error
            }
        case GET_EMPLOYEE_MATCH_REQUEST:
            return {
                ...state,
                isFetching: false,
                employeeMatches: [],
                error: null,
            };
        case GET_EMPLOYEE_MATCH_SUCCESS:
            return {
                ...state,
                isFetching: true,
                employeeMatches: action.payload,
                error: null
            }
        case GET_EMPLOYEE_MATCH_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case GET_EMPLOYEE_MAYBE_REQUEST:
            return {
                ...state,
                isFetching: false,
                employeeMaybes: [],
                error: null,
            };
        case GET_EMPLOYEE_MAYBE_SUCCESS:
            return {
                ...state,
                isFetching: true,
                employeeMaybes: action.payload,
                error: null
            }
        case GET_EMPLOYEE_MAYBE_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case GET_COMPANY_MAYBE_REQUEST:
            return {
                ...state,
                isFetching: false,
                companyMaybes: [],
                error: null,
            };
        case GET_COMPANY_MAYBE_SUCCESS:
            return {
                ...state,
                isFetching: true,
                companyMaybes: action.payload,
                error: null
            }
        case GET_COMPANY_MAYBE_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case GET_COMPANY_MATCH_REQUEST:
            return {
                ...state,
                isFetching: false,
                companyMatches: [],
                error: null,
            };
        case GET_COMPANY_MATCH_SUCCESS:
            return {
                ...state,
                isFetching: true,
                companyMatches: action.payload,
                error: null
            }
        case GET_COMPANY_MATCH_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case UPDATE_EMPLOYEE_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
            };
        case UPDATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: null,
            };
        case UPDATE_EMPLOYEE_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case UPDATE_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
            };
        case UPDATE_TEAM_MEMBER_SUCCESS:
            return initialState;
        case UPDATE_TEAM_MEMBER_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        default: {
            return {
                ...state
            }
        }
    }
};

export default homestack;
