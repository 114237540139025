import React, { useState } from 'react';
import { icDelete, icError, icImage, icUpload, icVideo } from '../../../assets/images/images';
import { Button, TextArea } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import { getFileMuxUrl, getUserDetails, updateCompany, uploadFiles } from '../../../store/actions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
export default function CompanyUploads() {
    const width = window.innerWidth
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.userData)
    const [companyFiles, setCompanyFiles] = useState([])
    const [desc, setDesc] = useState("")
    const [error, setError] = useState(false)
    const [logo, setLogo] = useState(null)
    const [video, setVideo] = useState(null)


    const handleSubmit = async () => {
        const formData = new FormData()
        logo !== null && formData.append('logo', logo, logo.name)
        formData.append('description', desc)

        dispatch(updateCompany(formData, currentUser?.companyTeams[0]?.company?.id, currentUser?.token)).then(res => {
            dispatch(getUserDetails(currentUser?.id, currentUser?.token)).then(response => {

                const user = {
                    ...response.data.result,
                    token: res.data.result?.accessToken
                }
                sessionStorage.setItem('userData', JSON.stringify(user))

            }).catch(err => console.log(err))
            navigate('/company/create-job', { state: { screen: 'register' } })
        })
        handleVideoUpload()

        companyFiles.length > 0 && await uploadFiles(companyFiles, currentUser?.token, currentUser?.companyTeams[0]?.company?.id)
    }

    const handleVideoUpload = () => {
        dispatch(getFileMuxUrl(video, currentUser?.companyTeams[0]?.company?.id)).then(res => {
            console.log(res);
        })
    }

    return (
        <>
            <span style={{ fontSize: isTabletOrMobile ? '24px' : '32px', textAlign: 'right', fontWeight: 'bold', marginTop: isTabletOrMobile ? '16px' : '60px' }}>{'ממש בעוד רגע נמצא לך את העובד/ת המושלמ/ת!'}</span>
            <span style={{ fontSize: '18px', marginTop: '24px', textAlign: 'right' }}>כדי למשוך את המועמדים האיכותיים ביותר, שווה לספר עוד קצת על החברה</span>

            <div style={{ marginTop: 40, position: 'relative' }}>
                <TextArea maxLength={2000} className="text-area" value={desc} onChange={setDesc} label={'ספר לנו קצת על החברה...'} />
                <span style={{ position: 'absolute', top: 20, left: 10, fontSize: 16, color: '#827aa5' }}>{`${desc.length}/2000`}</span>
            </div>
            <div style={{ marginTop: 24, flexDirection: isTabletOrMobile ? 'column' : 'row' }} className='upload d-flex'>

                {logo ?

                    <div style={{ height: '102px' }} className='frame position-relative'>
                        <img alt="icon" style={{ height: '102px', borderRadius: 5, objectFit: 'contain' }} src={URL.createObjectURL(logo)} />
                        <div style={{ backgroundColor: '#ffffff', left: 0, bottom: 0, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 4, border: '1px solid #d4daf8', borderRadius: 8, }}>
                            <img alt="icon" onClick={() => setLogo(null)} width="24" height="24" src={icDelete} />
                        </div>
                    </div>
                    :
                    <div style={{ height: '102px' }} className='frame position-relative'>
                        <img alt="icon" src={icUpload} />
                        <div>
                            <img alt="icon" src={icImage} />
                            <span style={{ paddingRight: 10 }}>העלה לוגו חברה</span>

                        </div>
                        <input
                            style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            onChange={(event) => {
                                setLogo(event.target.files[0]);
                            }}

                            accept="image/png,image/jpeg"
                            type="file" />
                    </div>}
                {video ?
                    <div style={{ height: '102px' }} className='frame position-relative'>
                        <video controls style={{ height: '100%' }} src={typeof video !== 'string' ? URL.createObjectURL(video) : video} />
                        <div style={{ backgroundColor: '#ffffff', left: 0, bottom: 0, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 4, border: '1px solid #d4daf8', borderRadius: 8, }}>
                            <img alt="icon" onClick={() => setVideo(null)} width="24" height="24" src={icDelete} />
                        </div>
                    </div> :
                    <div style={{ height: '102px' }} className='frame position-relative'>
                        <img alt="upload" src={icUpload} />
                        <div>
                            <img alt="video" src={icVideo} />
                            <span style={{ paddingRight: 10 }}>העלה וידאו חברה</span>
                        </div>
                        <input
                            style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            onChange={(event) => {
                                setVideo(event.target.files[0]);
                            }}
                            accept="video/*"  // Accepts any type of video file

                            type="file" />
                    </div>}
            </div>
            <div style={{ position: 'relative', justifyContent: 'space-between', padding: '20px 16px 20px 16px', flexDirection: 'row', width: isTabletOrMobile ? width - 55 : '40vw', marginTop: 24, flexWrap: 'wrap' }} className='frame'>

                {
                    companyFiles?.map(item => {
                        return (
                            <div style={{ position: 'relative', height: 120, width: isTabletOrMobile ? '100%' : '32%', backgroundColor: '#f1eff5', borderRadius: 4 }}>
                                <img alt="icon" style={{ objectFit: 'contain', height: 120, width: '100%' }} src={item?.file?.id === undefined ? URL.createObjectURL(item) : item?.file?.url} />

                                <div style={{ zIndex: 1, backgroundColor: '#ffffff', left: isTabletOrMobile ? -10 : -10, bottom: isTabletOrMobile ? -10 : -10, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 4, border: '1px solid #d4daf8', borderRadius: 8, }}>
                                    <img alt="icon" onClick={() => {

                                        const tmpFiles = [...companyFiles]
                                        const indexOf = tmpFiles.indexOf(item);
                                        if (indexOf > -1) {
                                            tmpFiles.splice(indexOf, 1);
                                        }
                                        setCompanyFiles(tmpFiles)
                                    }} width={isTabletOrMobile ? "20" : "24"} height={isTabletOrMobile ? "20" : "24"} src={icDelete} />
                                </div>
                            </div>
                        )
                    })
                }
                {
                    Array.from({ length: 10 - companyFiles?.length }, (_, index) => (
                        <div key={index} style={{ height: 120, width: isTabletOrMobile ? '100%' : '32%', backgroundColor: '#f1eff5', borderRadius: 4 }} />
                    ))
                }
                {!isTabletOrMobile && <div style={{ width: '30%' }} />}
            </div>
            <div style={{ position: 'relative', width: 160, height: 44, marginTop: 20, alignSelf: 'center' }}>
                <Button style={{ width: 160, position: 'absolute',  }} onPress={() => { }} text={'העלאת תמונות'} />
                <input
                    style={{ opacity: 0, position: 'absolute', left: 0, width: '100%', height: '100%' }}
                    onChange={(event) => {
                        if (Array.from(event.target.files).length > 10 || companyFiles.length === 10 || (Array.from(event.target.files).length + companyFiles.length) > 10) {
                            event.preventDefault();
                            setError(true)
                            setTimeout(() => {
                                setError(false)
                            }, 3000);
                            return;
                        }
                        const tmpFiles = [...companyFiles]
                        Array.prototype.slice.call(event.target.files).map(file => tmpFiles.push(file))
                        setCompanyFiles(tmpFiles)
                    }}
                    multiple
                    accept="image/png,image/jpeg"
                    type="file" />
            </div>
            {error && <div
                style={{ display: 'flex', marginTop: 10, fontWeight: '500', alignItems: 'center', textAlign: 'right', height: '41px', color: '#ff2525', border: '1px solid', borderColor: '#ff2525', borderRadius: 4, padding: '10px 12px' }}>
                <img alt="icon" src={icError} />
                <span style={{ paddingRight: 10, fontSize: 14 }}>לא ניתן להעלות יותר מ-10 תמונות</span>
            </div>}
            <div style={{ marginTop: '40px', position: 'relative', marginRight: 0, marginBottom: 40 }} className='d-flex row'>
                <Button onPress={() => handleSubmit()} text={'הבא'} />
                <Button style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} onPress={() => navigate('/company/create-job', { state: { screen: 'register' } })} text={'דלג'} />
            </div>
        </>
    );
}
