export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
// export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST'
// export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS'
// export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL'
export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL'
export const GET_LIST_REQUEST = 'GET_LIST_REQUEST'
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS'
export const GET_LIST_FAIL = 'GET_LIST_FAIL'
export const GET_LEGALS_REQUEST = 'GET_LEGALS_REQUEST'
export const GET_LEGALS_SUCCESS = 'GET_LEGALS_SUCCESS'
export const GET_LEGALS_FAIL = 'GET_LEGALS_FAIL'
export const GET_COMPANY_JOBS_REQUEST = 'GET_COMPANY_JOBS_REQUEST'
export const GET_COMPANY_JOBS_SUCCESS = 'GET_COMPANY_JOBS_SUCCESS'
export const GET_COMPANY_JOBS_FAIL = 'GET_COMPANY_JOBS_FAIL'
export const GET_EMPLOYESS_GUEST_REQUEST = 'GET_EMPLOYESS_GUEST_REQUEST'
export const GET_EMPLOYESS_GUEST_SUCCESS = 'GET_EMPLOYESS_GUEST_SUCCESS'
export const GET_EMPLOYESS_GUEST_FAIL = 'GET_EMPLOYESS_GUEST_FAIL'

export const GET_JOBS_GUEST_REQUEST = 'GET_JOBS_GUEST_REQUEST'
export const GET_JOBS_GUEST_SUCCESS = 'GET_JOBS_GUEST_SUCCESS'
export const GET_JOBS_GUEST_FAIL = 'GET_JOBS_GUEST_FAIL'

export const GET_EMPLOYEES_REQUEST = 'GET_EMPLOYEES_REQUEST'
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS'
export const GET_EMPLOYEES_FAIL = 'GET_EMPLOYEES_FAIL'
export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST'
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS'
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEE_FAIL'
export const UPDATE_TEAM_MEMBER_REQUEST = 'UPDATE_TEAM_MEMBER_REQUEST'
export const UPDATE_TEAM_MEMBER_SUCCESS = 'UPDATE_TEAM_MEMBER_SUCCESS'
export const UPDATE_TEAM_MEMBER_FAIL = 'UPDATE_TEAM_MEMBER_FAIL'
export const GET_JOBS_REQUEST = 'GET_JOBS_REQUEST'
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS'
export const GET_JOBS_FAIL = 'GET_JOBS_FAIL'
export const GET_JOB_REQUEST = 'GET_JOB_REQUEST'
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS'
export const GET_JOB_FAIL = 'GET_JOB_FAIL'
export const GET_EMPLOYEE_MATCH_REQUEST = 'GET_EMPLOYEE_MATCH_REQUEST'
export const GET_EMPLOYEE_MATCH_SUCCESS = 'GET_EMPLOYEE_MATCH_SUCCESS'
export const GET_EMPLOYEE_MATCH_FAIL = 'GET_EMPLOYEE_MATCH_FAIL'
export const GET_COMPANY_MATCH_REQUEST = 'GET_COMPANY_MATCH_REQUEST'
export const GET_COMPANY_MATCH_SUCCESS = 'GET_COMPANY_MATCH_SUCCESS'
export const GET_COMPANY_MATCH_FAIL = 'GET_COMPANY_MATCH_FAIL'
export const GET_JOB_LIST_REQUEST = 'GET_JOB_LIST_REQUEST'
export const GET_JOB_LIST_SUCCESS = 'GET_JOB_LIST_SUCCESS'
export const GET_JOB_LIST_FAIL = 'GET_JOB_LIST_FAIL'
export const GET_EMPLOYEE_MAYBE_REQUEST = 'GET_EMPLOYEE_MAYBE_REQUEST'
export const GET_EMPLOYEE_MAYBE_SUCCESS = 'GET_EMPLOYEE_MAYBE_SUCCESS'
export const GET_EMPLOYEE_MAYBE_FAIL = 'GET_EMPLOYEE_MAYBE_FAIL'
export const GET_COMPANY_MAYBE_REQUEST = 'GET_COMPANY_MAYBE_REQUEST'
export const GET_COMPANY_MAYBE_SUCCESS = 'GET_COMPANY_MAYBE_SUCCESS'
export const GET_COMPANY_MAYBE_FAIL = 'GET_COMPANY_MAYBE_FAIL'
export const GET_INTERVIEWS_REQUEST = 'GET_INTERVIEWS_REQUEST'
export const GET_INTERVIEWS_SUCCESS = 'GET_INTERVIEWS_SUCCESS'
export const GET_INTERVIEWS_FAIL = 'GET_INTERVIEWS_FAIL'
export const GET_EMPLOYEE_INTERVIEWS_REQUEST = 'GET_EMPLOYEE_INTERVIEWS_REQUEST'
export const GET_EMPLOYEE_INTERVIEWS_SUCCESS = 'GET_EMPLOYEE_INTERVIEWS_SUCCESS'
export const GET_EMPLOYEE_INTERVIEWS_FAIL = 'GET_EMPLOYEE_INTERVIEWS_FAIL'
export const GET_COMPANY_JOBS_USERS_REQUEST = 'GET_COMPANY_JOBS_USERS_REQUEST'
export const GET_COMPANY_JOBS_USERS_SUCCESS = 'GET_COMPANY_JOBS_USERS_SUCCESS'
export const GET_COMPANY_JOBS_USERS_FAIL = 'GET_COMPANY_JOBS_USERS_FAIL'
export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST'
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS'
export const GET_EMPLOYEE_FAIL = 'GET_EMPLOYEE_FAIL'
export const GET_NOTIF_REQUEST = 'GET_NOTIF_REQUEST'
export const GET_NOTIF_SUCCESS = 'GET_NOTIF_SUCCESS'
export const GET_NOTIF_FAIL = 'GET_NOTIF_FAIL'