const configs = {
  localhost: {
    API_URL: 'http://localhost:8080',
    DOMAIN: 'http://localhost:8080',
  },
  development: {
    API_URL: 'https://qaapi2024.nona-jobs.com',
    DOMAIN: 'https://qa2024.nona-jobs.com',
  },
  production: {
    API_URL: 'https://api.nona-jobs.com',
    DOMAIN: 'https://app.nona-jobs.com',
  },
};

//6LdPrPopAAAAAMp3v4NneuKhPaYZ0zjKu8o3TfkJ // testing
//6LcVqPopAAAAAETrVQVXphEx6uG8lUfEBKwRwgQN - live/dev

export const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const VERSION = '1.0.0';

export default configs[process.env.REACT_APP_API_URL || 'development'];

export const terms = (
  <div>
    <p>
      <b>תודה שבחרתם ב-NONA.</b>
    </p>
    <p>
      לפני שימוש באתר/בממשק/באפליקציה, אני מצהיר/ה כי אני בן/בת 18 שנים לפחות בעת הרשמתי למערכת נונה, וקראתי את הסכם
      המשתמש הזה, המכיל את התקנון ומדיניות הפרטיות שלנו, ואת כל התנאים וההגבלות הכרוכים בהתקשרות עימנו ובשימוש
      באתר/ממשק/אפליקציה. עליכם לוודא כי קראתם בקפידה את התוכן והתנאים לפני הרשמתכם לשירות.
    </p>
    <p>
      <b>שכלול ותוקף ההסכמה:</b>
    </p>
    <p>על ידי הרשמה ו/או שימוש כלשהוא באתר/בממשק/באפליקציה, אתם מסכימים להיות מחויבים להסכם זה. </p>
    <p>
      <b>על אודות NONA:</b>
    </p>
    <p>
      NONA היא רעיון חדשני בתחום השמת עובדים. המודל שלנו מבוסס על מתן אפשרות למעסיק להתרשם באופן ישיר ובלתי אמצעי
      מהמועמד/ת באמצעות סרטון ישיר שמעלה המועמד/ת לצורך קבלה למשרה בה הוא מעונין. לאור ההבנה שעולם ההשמה צועד לעבר
      הליכים דיגיטליים והתנהלות מקוונת, השימוש בסרטון ככלי משלים לקורות החיים, מסייע למעסיקים ולמועמדים לדייק את
      הדרישות, ולזמן לראיון רק את המתאימים ביותר, ואלה שעונים על הקריטריונים שהמעסיק הציב כתנאי להעסקה. NONA מאפשרת לכל
      מעסיק או מועמד להירשם למערכת, בלא כל אפליה, הבדל, שיקולים של גזע ומין. עם זאת, ל-NONA אין שליטה או אחריות כלפי
      המעסיק או המועמד, ולא תישמע כל טענה כלפיה מצד מעסיק או מועמד בקשר להליך הקבלה לעבודה.
    </p>
    <p>
      <b>יצירת חשבון:</b>
    </p>
    <p>
      בטרם שימוש באתר/בממשק/באפליקציה עליכם להירשם וליצור חשבון בכדי לקבל את הזכות לשימוש. עליכם לוודא שכל המידע שאתם
      מעבירים בזמן ההרשמה הוא נכון. באחריותכם לעדכן את המידע ולשנות כל פרט לאחר הרשמתכם לאתר. בכל שלב שהוא, אתם רשאים
      לגרוע את פרטיכם מהמאגר, ובכפוף להתחייבותכם לעדכן האם מצאתם עבודה דרך NONA. עליכם לשמור על פרטי החשבון ולשנות את
      הסיסמה באופן קבוע.
    </p>
    <p>
      <b>העדר התחייבות ושמירת זכויות:</b>
    </p>
    <p>
      NONA לא מתחייבת בשום צורה שהיא למצוא עבודה למשתמשי האתר, והיא מספקת אך ורק שירותי השמה באמצעות
      האתר/הממשק/האפליקציה. אין בין NONA לבין המשתמשים כל יחסי עבודה או יחסים חוזיים שמטילים אחריות כלפי NONA, ואין בעצם
      ההשמה בין משתמשים לבין מעסיקים כדי לייצר אחריות או חבות כלשהיא כלפי NONA. NONA מאפשרת לכל מעסיק לפעול במערכת ולאתר
      את המועמדים הרלבנטיים. משכך אין ל-NONA כל אחריות להתנהלות המעסיק בין לפני התקשרות בהסכם העסקה ובין לאחריו.
    </p>
    <p>
      <b>תשלום וכספים:</b>
    </p>
    <p>
      השימוש באתר/ממשק/אפליקציה לא כרוך בתשלום מצד המועמדים. NONA תעניק לכל מועמד אשר התקבל לעבודה באמצעות
      האתר/הממשק/האפליקציה גיפטקארד בשווי של כ- 1000 דולר, ככל שהעובד התמיד אצל המעסיק וקיבל בפועל 3 משכורות עוקבות.
      תנאי לכך, הוא עדכון על מציאת עבודה דרך האתר מיידית לקבלה לעבודה בכתב למייל:info@nona-jobs.com . ההטבה תינתן ישירות
      למועמד/ת תוך 45 יום, מהמועד בו דיווח המועמד על קבלת 3 משכורות בחשבונו. NONA רשאית להפסיק את ההטבה בכל שלב שהוא,
      ובלא התראה מראש.
    </p>
    <p>
      <b>פרטיות, פרסום ודיוור:</b>
    </p>
    <p>
      NONA מכבדת ומחויבת לשמירה על פרטיותכם ועומדת בחובותיה על פי חוק הגנת הפרטיות, תשמ"א-1981. בהרשמתכם אתם מאשרים ש-
      NONA תאסוף ותעבד נתונים אישיים על אודותיכם כחלק מהביקור והשימוש באתר/בממשק/באפליקציה. בהרשמתכם אתם נותנים הסכמתכם
      לפנייה לפרסום/שיווק, הן מ-NONA והן מהמעסיקים הרשומים במאגרי החברה. אתם מסכימים לקבל פרסומות שאנו שולחים, קידום או
      פרסום מידע (כולל הודעות מסחריות ולא מסחריות) בעצמנו, או באמצעות צד שלישי. הסכמות אלה בהתאם לחוק התקשורת (בזק
      ושידורים), תשמ"ב-1982. אנחנו מבטיחים לשמור על פרטיותכם, באופן שבו הגישה לאתר/לממשק/לאפליקציה, מותרת רק לעובדי NONA
      ומעסיקים שנרשמו לאתר. יש לקחת בחשבון שחלק מרצוננו לסייע לכם במציאת עבודה, ייתכן ופרטיכם יועברו למעסיקים חיצוניים
      או גורמים שאינם כלולים באתר, והנכם מודעים ומאשרים כי המידע שמסרתם לרבות הסרטון המצולם, יועברו למעסיקים רלבנטיים גם
      אם לא מופיעים במערכת. הנכם מאשרים פרסום הפרטים גם ברשתות חברתיות, כדוגמת פייסבוק, אינסטגרם, ו-טיקטוק.
    </p>
    <p>
      <b>תוכן הנוצר על ידי המשתמש:</b>
    </p>
    <p>
      האתר/הממשק/האפליקציה יכילו סרטונים שנוצרו על ידי המשתמשים וככאלה האחריות על תכנם היא של המשתמש בלבד. NONA רשאית
      לעשות שימוש בתכנים המועלים על ידי המשתמשים לצרכיה. NONA רשאית למחוק תכנים שהועלו, מכל סיבה שהיא, ולמשתמשים לא תהא
      כל טענה בעניין זה. על ידי העלאת תוכן לאתר, אתם מאשרים ל - NONA כי התוכן שהועלה לא מפר או יפר את זכויות הקניין
      הרוחני, פרטיותו, המוניטין של צד שלישי כלשהוא, ובכל מקרה שתועלה טענה נגד NONA בגין תכנים שהועלו על ידי המשתמש
      לאתר/ממשק/אפליקציה, המשתמש יהא האחראי הבלעדי לשפות או לפצות את הגורם שזכויותיו הופרו. בכל מקרה NONA רשאית למחוק
      תוכן מהאתר. למען הסר ספק - המשתמשים אחראים באופן בלעדי לכל הפעולות שבחשבונם, כולל כל תוכן שהועלה או נוצר על ידם
      לאתר וכל ההשלכות הנובעות ממנו או קשורות אליו.
    </p>
    <p>
      <b>איסורים</b>
    </p>
    <p>
      חל איסור מוחלט להעתיק, לשכפל, לפרסם מחדש, לשנות, מהנדס, לפרק, לנסות לייצא את קוד המקור, לפענח, להוריד, לפרסם,
      לשדר, להעביר, להנגיש לציבור, להתאים, לשנות או ליצור נגזרת. לעבוד או לעסוק בכל פעולה אחרת המנתחת או משכפלת את קוד
      המקור, המבנה ו/או הרעיון של האתר/הממשק/האפליקציה; להעתיק, להפיץ מחדש או להפיץ רישיון משנה של כל אחד מתכני
      האתר/הממשק/האפליקציה; לפרסם, להעביר או להעביר כל תוכן ומידע שעשויים להיות בלתי חוקיים, פוגעניים או פוגעים בזכויות
      צד ג '. תוכן כזה עשוי לכלול פורנוגרפיה, תוכן אלים, תוכן מאיים, תוכן משמיץ ו / או כל תוכן שמפר את החוקים והתקנות
      החלים בישראל או במדינה בה אתם מתגוררים או במדינה בה אנחנו נותנים בה שירות. בנוסף אין להשתמש באתר/בממשק/באפליקציה
      כדי לפרסם, להעביר או להעביר מידע פרסומי, מידע שיווקי או הודעות דואר זבל; להשתמש באתר כדי לפגוע בזכויות או אינטרסים
      משפטיים של אחרים, כגון זכויות קניין רוחני, פרטיות, מוניטין; להשתמש באתר/באפליקציה/בממשק כדי לפרסם מידע שגוי או כדי
      להטעות או להונות אחרים; לבצע כל פעולה העלולה לפגוע באבטחת האתר, לרבות אך לא רק: שימוש בנתונים לא מורשים או כניסה
      לשרתים / חשבונות לא מורשים; כניסה לרשתות מחשבים ציבוריות או למערכת מחשבים של אדם אחר ללא אישור, ו / או למחוק,
      לשנות או להוסיף למידע המאוחסן בתוכו; לנסות לבדוק, לסרוק או לבדוק את הפגיעות של האתר, או מעשים אחרים העלולים לסכן
      את אבטחת האתר; לנסות להפריע, או לפגוע בפעולה התקינה של האתר/הממשק/האפליקציה, או לעסוק בהפצה מכוונת של תוכנות או
      וירוסים זדוניים ו / או מעשים אחרים העלולים לפגוע בשיבוש שירותי מידע ברשת רגילים.
    </p>
    <p>
      <b>זכויות קניין רוחני </b>
    </p>
    <p>
      כל זכויות היוצרים, סימני המסחר, זכויות העיצוב וזכויות הקניין הרוחני האחרות (הרשומות והלא רשומות)
      באתר/בממשק/באפליקציה וכל התוכן (כולל כל הטקסט, התמונות, הצליל, התוכנה וחומרים אחרים) הנמצאים באתר/בממשק/באפליקציה
      יישאר בבעלות NONA. אנו מעניקים לך זכות לא בלעדית, ניתנת לביטול, שאינה ניתנת להעברה, לשימוש באתר/בממשק/באפליקציה
      לשימוש אישי ולא מסחרי. כל שימוש אחר בתכני האתר/הממשק/האפליקציה, דורש את הסכמת NONA בכתב ומראש.
    </p>
    <p>
      <b>אבטחה והגנה מפני וירוסים</b>
    </p>
    <p>
      NONA תנקוט באופן סביר באמצעים טכניים נחוצים כדי להגן על המידע ולבדוק חומרים, אולם המשתמשים מאשרים ומסכימם כי NONA
      לא מציגה שום מצג, אחריות או התחייבות כי האתר/הממשק/האפליקציה ו/או השירותים שלנו לא יופרעו, בטוחים או נטולי שגיאות
      או נקיים מווירוסים, ולכן לא ניקח על עצמנו אחריות לכל אובדן, הפרעה או נזק לנתונים של המשתמשים או למערכת המחשבים
      שלכם שעלולים להתרחש בעת השימוש באתר או בחומר הנגזר מהם. בהרשמתכם אתם נוטלים אחריות מלאה להגנה על מערכת המחשבים
      שלכם כולל חומרת מחשב, תוכנה ונתונים מאוחסנים במערכת המחשב שלכם וחומרה, תוכנה ונתונים מאוחסנים של צדדים שלישיים
      העשויים לגשת למערכת המחשבים שלך, או להיות מחוברים להם בדרך אחרת. אנו לא נהיה אחראים לכל אובדן שהמשתמשים עשויים
      להיפגע בנסיבות הבאות: תקלה וחוסר יכולת להפעיל את האתר/הממשק/האפליקציה באופן תקין; הפסקת או עיכוב השירותים הניתנים
      באתר/בממשק/באפליקציה הנגרמים על ידי וירוסי מחשב, סוס טרויאני, תוכנות זדוניות אחרות, התקפות האקרים; התאמות טכניות
      או תקלה של מחלקת התקשורת וחברות ההפעלה באינטרנט; תחזוקת מערכת; או מכל סיבה אחרת; הפסקת, סיום או עיכוב השירותים
      הניתנים באתר או באתר עצמו מסיבות כמו שינויים בחוקים ותקנות, הוראות ופסיקות של רשויות שיפוטיות, מינהליות ואחרות
      ו/או הפסדים שנגרמו כתוצאה מהשימוש באתר תוך הפרה של הסכם משתמש זה או פעילות לא תקינה בו.
    </p>
    <p>
      <b>עדכונים בהסכם משתמש זה</b>
    </p>
    <p>יש לנו את הזכות לשנות את תנאי הסכם משתמש זה ו/או התקנון ו/או מדיניות הפרטיות במידת הצורך.</p>
    <p>
      <b>יישוב סכסוכים</b>
    </p>
    <p>
      אנו מצפים להסדיר כל מחלוקת או סכסוך בדרך מכובדת ובלא צורך להזדקק לערכאות. עם זאת, וככל שיהיה צורך, הצדדים מסכימים
      כי בכל מקרה שבו לא ניתן יהיה לגשר על הטענות, הצדדים יפנו להליך בוררות, באמצעות בורר שימונה על ידי יו"ר לשכת עורכי
      הדין. הבורר יהיה כפוף לדין המהותי, אך לא לסדרי הדין ולא לדיני הראיות, ויהיה רשאי לפסוק הן לדין והן לפשרה. הבורר
      ייתן החלטות ו/או פסק בורר מנומקים. כמו כן, לבורר תהא הסמכות ליתן צווי ביניים וצווים סופיים, בין צווי אכיפה ובין
      אחרים. דין הוראות סעיף זה כדין הסכם בוררות בין הצדדים והוראות חוק הבוררות והתוספות לחוק הבוררות, התשכ"ח-1968
      (להלן: "חוק הבוררות") תחולנה על הבוררות מושא הסכם זה, וכן על הבורר. סמכות מקומית לעניין הסכם זה היא במחוז תל אביב
      – יפו.
    </p>
    <p>
      <b>יצירת קשר</b>
    </p>
    <p>בכל שאלה או בקשה ניתן לפנות לדוא"ל:info@nona-jobs.com </p>
  </div>
);
