import React, { useEffect, useState } from 'react';
import { icArrowDown, icArrowRight, icDelete, icEdit, icSearch } from '../../../assets/images/images';
import Autocomplete from 'react-autocomplete';
import { useSelector } from 'react-redux';
import { Button } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import { MenuItem, Select } from '@mui/material';
import { shouldItemRender } from '../../../utils';

export default function EmployeeJobRole({ handleNext, handleBack, data }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const list = useSelector((state) => state.listDetails);
  const [record, setRecord] = useState(
    data !== null && data !== undefined && data.record ? data.record : [{ editable: true }]
  );
  const [jobRole, setJobRole] = useState(
    data?.jobRoleExperiencesValues !== undefined
      ? data.jobRoleExperiencesValues?.map((item) => item.jobRole)
      : [{ id: null, label: '', valid: false }]
  );
  const [jobRoleList, setJobRoleList] = useState([]);

  const [experience, setExperience] = useState(
    data?.jobRoleExperiencesValues !== undefined
      ? data.jobRoleExperiencesValues?.map((item) => item.experience)
      : [{ id: null, label: '', valid: false }]
  );
  const [experienceList, setExperienceList] = useState([]);

  useEffect(() => {
    const tmpRoles = [];
    list?.jobRole.forEach((role) => tmpRoles.push({ id: role.id, label: role.itemText }));
    setJobRoleList(tmpRoles);

    const tmpExperience = [];
    list?.experience.forEach((exp) => tmpExperience.push({ id: exp.id, label: exp.itemText }));
    setExperienceList(tmpExperience);
  }, [list]);

  const prepareAnswers = (records = record, jobRoles = jobRole, experiences = experience) => {
    const jobRoleExperiencesValues = [];
    records.forEach(
      (item, index) =>
        jobRoles[index].id !== null &&
        experiences[index].id !== null &&
        jobRoleExperiencesValues.push({ jobRole: jobRoles[index], experience: experiences[index] })
    );

    return jobRoleExperiencesValues;
  };

  const handleSubmit = () => {
    const valid = validInputs();
    if (!valid) {
      // const formData = new FormData()
      const jobRoleExperiences = [];
      const jobRoleExperiencesValues = prepareAnswers();

      record.forEach(
        (item, index) =>
          jobRole[index].id !== null &&
          experience[index].id !== null &&
          jobRoleExperiences.push({ jobRole: jobRole[index].id, experience: experience[index].id })
      );

      // formData.append('jobRoleExperiences', JSON.stringify(jobRoleExperiences))

      // dispatch(updateEmployee(formData, currentUser?.id, currentUser?.token)).then(res => {

      // }).catch(err => console.log("err --->", err))

      handleNext(
        { jobRoleExperiencesValues: jobRoleExperiencesValues, jobRoleExperiences: jobRoleExperiences, record: record },
        true
      );
    }
  };

  const validInputs = () => {
    let valid = false;
    jobRole.map((job, index) => {
      if (job.id == null) {
        valid = true;
        const newArr = [...jobRole];
        newArr[index].valid = true;
        setJobRole(newArr);
        setTimeout(() => {
          const newArr = [...jobRole];
          newArr[index].valid = false;
          setJobRole(newArr);
        }, 1000);
      } else {
        const newArr = [...jobRole];
        newArr[index].valid = false;
        setJobRole(newArr);
      }
      return null;
    });

    experience.map((ex, index) => {
      if (ex.id == null) {
        valid = true;
        const newArr = [...experience];
        newArr[index].valid = true;
        setExperience(newArr);
        setTimeout(() => {
          const newArr = [...experience];
          newArr[index].valid = false;
          setExperience(newArr);
        }, 1000);
      } else {
        const newArr = [...experience];
        newArr[index].valid = false;
        setExperience(newArr);
      }
      return null;
    });
    return valid;
  };

  return (
    <>
      <div
        onClick={() => {
          handleBack({});
        }}
        style={{
          cursor: 'pointer',
          marginTop: '16px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img alt='arrow' src={icArrowRight} />
        <span style={{ color: '#4f35be', fontSize: 28, marginRight: 10, fontWeight: 'bold' }}>חזור</span>
      </div>

      <span
        style={{
          fontSize: isTabletOrMobile ? '24px' : '32px',
          textAlign: 'right',
          fontWeight: 'bold',
          marginTop: isTabletOrMobile ? '24px' : '15px',
        }}
      >{`נעים להכיר! איזה עבודה לחפש עבורך!`}</span>
      {record.map((item, index) => {
        return item.editable ? (
          <div className='d-flex flex-column' style={{ textAlign: 'right', marginTop: 40 }} key={index}>
            {index !== 0 && (
              <img
                alt='delete'
                onClick={() => {
                  const tmpRole = [...jobRole];
                  const tmpExperience = [...experience];
                  const tmpRecords = [...record];

                  if (record.length === 1) {
                    tmpRole[index] = { id: null, label: '', valid: false };
                    tmpExperience[index] = { id: null, label: '', valid: false };
                    tmpRecords[index] = { editable: true };

                    setJobRole(tmpRole);
                    setExperience(tmpExperience);
                    setRecord(tmpRecords);
                    const jobRoleExperiencesValues = prepareAnswers(tmpRecords, tmpRole, tmpExperience);
                    handleNext({ jobRoleExperiences: jobRoleExperiencesValues, record: record }, false);
                  } else {
                    tmpRole.splice(index, 1);
                    tmpExperience.splice(index, 1);
                    tmpRecords.splice(index, 1);

                    setJobRole(tmpRole);
                    setExperience(tmpExperience);
                    setRecord(tmpRecords);

                    const jobRoleExperiencesValues = prepareAnswers(tmpRecords, tmpRole, tmpExperience);
                    handleNext({ jobRoleExperiences: jobRoleExperiencesValues, record: record }, false);
                  }
                }}
                width='32'
                height='32'
                src={icDelete}
              />
            )}
            {index !== 0 ? (
              <span style={{ fontSize: '16px', marginTop: '24px' }}>תפקיד נוסף שתרצה לחפש:</span>
            ) : (
              <span style={{ fontSize: '16px', marginTop: '24px', color: '#827aa5' }}>
                באיזה תפקיד תהיה עבודת החלומות שלך?
              </span>
            )}
            <div style={{ position: 'relative' }}>
              <Autocomplete
                inputProps={{
                  style: {
                    border: `1px solid ${jobRole[index].valid ? 'red' : '#D4DAF8'}`,
                  },
                  className: 'auto-complete icon',
                  placeholder: 'שם התפקיד',
                }}
                value={jobRole[index].label}
                items={jobRoleList.filter((item1) => !jobRole.some((item2) => item2.id === item1.id))}
                shouldItemRender={shouldItemRender}
                wrapperStyle={{ position: 'relative' }}
                getItemValue={(item) => item.label}
                onSelect={(value, item) => {
                  const tmpJobRole = [...jobRole];
                  tmpJobRole[index] = item;
                  setJobRole(tmpJobRole);
                }}
                onChange={(event, value) => {
                  const tmpJobRole = [...jobRole];
                  tmpJobRole[index] = { id: null, label: value };
                  setJobRole(tmpJobRole);
                }}
                renderMenu={(items) => <div className='menu'>{items}</div>}
                renderItem={(item) => (
                  <p key={item.id} style={{ cursor: 'pointer', color: '#2f2072' }}>
                    {item.label}
                  </p>
                )}
                renderInput={(props) => (
                  <>
                    {jobRole[index].label === '' && (
                      <img
                        alt='search'
                        style={{ zIndex: 1, position: 'absolute', top: 26, right: 12 }}
                        src={icSearch}
                      />
                    )}
                    <input {...props} />
                  </>
                )}
              />
            </div>
            <div
              style={{
                position: 'relative',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '24px',
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              className='d-flex'
            >
              <span style={{ textAlign: 'right', marginLeft: 16 }}>כמה שנות ניסיון יש לך בתפקיד הזה?</span>
              <Select
                inputProps={{
                  IconComponent: () => null,
                  sx: { padding: '0 !important' },
                }}
                renderValue={(value) => {
                  if (experience[index].label === '') {
                    return (
                      <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ textAlign: 'right', color: '#827aa5' }}>שנות ניסיון</span>
                        <img alt='arrow' src={icArrowDown} />
                      </div>
                    );
                  }

                  return (
                    <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                      <span style={{ textAlign: 'right' }}>{value.label}</span>
                      <img alt='arrow' src={icArrowDown} />
                    </div>
                  );
                }}
                displayEmpty
                value={experience[index].label === '' ? '' : experience[index]}
                variant='outlined'
                onChange={({ target }) => {
                  const tmpExp = [...experience];
                  tmpExp[index] = target.value;
                  setExperience(tmpExp);
                  if (jobRole[index].id !== null) {
                    const tmpArr = [...record];
                    tmpArr[index].editable = false;
                    setRecord(tmpArr);
                  }
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  PaperProps: {
                    sx: {
                      '& .Mui-selected': {
                        backgroundColor: '#f8f8ff',
                      },
                      '& .MuiMenuItem-root:hover': {
                        backgroundColor: '#f8f8ff',
                      },
                    },
                  },
                }}
                sx={{
                  fontSize: 16,
                  fontFamily: 'Heebo',
                  color: '#1f0039',
                  height: '48px',
                  width: '140px',
                  borderRadius: '8px',
                  border: `1px solid ${experience[index].valid ? 'red' : '#D4DAF8'}`,
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '0px',
                    textAlign: 'right',
                  },
                }}
              >
                {experienceList.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Heebo',
                        color: '#1f0039',
                        direction: 'rtl',
                      }}
                      value={item}
                    >
                      <span>{item.label}</span>
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
        ) : (
          <div key={index} className='cards'>
            <div style={{ justifyContent: 'space-between', width: '100%' }} className='d-flex'>
              <span style={{ fontSize: 20, textAlign: 'right', fontWeight: 'bold' }}>{jobRole[index].label}</span>
              <img
                alt='delete'
                onClick={() => {
                  const tmpRole = [...jobRole];
                  const tmpExperience = [...experience];
                  const tmpRecords = [...record];

                  if (record.length === 1) {
                    tmpRole[index] = { id: null, label: '', valid: false };
                    tmpExperience[index] = { id: null, label: '', valid: false };
                    tmpRecords[index] = { editable: true };

                    setJobRole(tmpRole);
                    setExperience(tmpExperience);
                    setRecord(tmpRecords);
                    const jobRoleExperiencesValues = prepareAnswers(tmpRecords, tmpRole, tmpExperience);
                    handleNext({ jobRoleExperiences: jobRoleExperiencesValues, record: record }, false);
                  } else {
                    tmpRole.splice(index, 1);
                    tmpExperience.splice(index, 1);
                    tmpRecords.splice(index, 1);

                    setJobRole(tmpRole);
                    setExperience(tmpExperience);
                    setRecord(tmpRecords);

                    const jobRoleExperiencesValues = prepareAnswers(tmpRecords, tmpRole, tmpExperience);
                    handleNext({ jobRoleExperiences: jobRoleExperiencesValues, record: record }, false);
                  }
                }}
                width='32'
                height='32'
                src={icDelete}
              />
            </div>
            <div style={{ justifyContent: 'space-between', width: '100%' }} className='d-flex'>
              <span style={{ color: '#827aa5', fontSize: 16, textAlign: 'right', fontWeight: 'bold', marginTop: 8 }}>
                {experience[index].label}
              </span>
              <img
                alt='edit'
                onClick={() => {
                  const tmpArr = [...record];
                  tmpArr[index].editable = true;
                  setRecord(tmpArr);
                }}
                src={icEdit}
              />
            </div>
          </div>
        );
      })}
      <span
        onClick={() => {
          const valid = validInputs();

          if (!valid) {
            const tmpArr = [...record];
            tmpArr[tmpArr.length - 1].editable = false;
            tmpArr.push({ editable: true });
            setRecord(tmpArr);

            const tmpJobRole = [...jobRole];
            tmpJobRole.push({ id: null, label: '', valid: false });
            setJobRole(tmpJobRole);

            const tmpExperience = [...experience];
            tmpExperience.push({ id: null, label: '', valid: false });
            setExperience(tmpExperience);

            const jobRoleExperiencesValues = prepareAnswers();
            handleNext({ jobRoleExperiences: jobRoleExperiencesValues, record: record }, false);
          }
        }}
        style={{
          cursor: 'pointer',
          marginTop: 24,
          textDecoration: 'underline',
          paddingRight: 5,
          fontSize: 16,
          color: '#36c0b0',
        }}
      >
        + הוספת תפקיד נוסף
      </span>
      <div style={{ marginTop: '40px', position: 'relative', marginRight: 0, marginBottom: 20 }} className='d-flex row'>
        <Button onPress={() => handleSubmit()} text={'הבא'} />
        <Button
          style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }}
          onPress={() => handleNext({}, true)}
          text={'דלג'}
        />
      </div>
    </>
  );
}
