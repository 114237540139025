import React, { useEffect, useState } from 'react';
import { icCandidates, icChat, icInsights, icMain, icMyPositions, icPositions, icSetting } from '../assets/images/images';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import { VERSION } from '../config';

export default function SideMenu({ type }) {
    const navigate = useNavigate()
    const currentUser = useSelector(state => state.userData)
    const [chosenMenu, setChosenMenu] = useState(1)
    const company = useSelector(state => state.company)
    const location = useLocation();

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: 'setTab', payload: 1 })
        const segments = location?.pathname.split('/');
        const id = segments[segments.length - 1];
        currentUser?.type?.id == 3 ? dispatch({ type: 'GET_EMPLOYEES_REQUEST' }) : dispatch({ type: 'GET_JOB_LIST_REQUEST' })

        switch (location.pathname) {
            case '/company':
                setChosenMenu(0)
                break;
            case '/company/candidates':
                setChosenMenu(1)
                break;
            case '/company/employee-profile':
                setChosenMenu(1)
                break;
            case '/company/jobs':
                setChosenMenu(2)
                break;
            case `/company/jobs/${id}`:
                setChosenMenu(2)
                break;
            case '/company/chats':
                setChosenMenu(3)
                break;
            case '/company/setting':
                setChosenMenu(4)
                break;
            case '/employee':
                setChosenMenu(0)
                break;
            case '/employee/candidates':
                setChosenMenu(1)
                break;
            case '/employee/setting':
                setChosenMenu(3)
                break;
            case '/employee/chats':
                setChosenMenu(2)
                break;
            default:
                break;

        }
    }, [location, dispatch])

    const disabled = (currentUser === null || currentUser === undefined || (currentUser?.type?.id == 3 && company?.company?.status.id === 4))

    return (
        <>
            {disabled && <div>
                <Tooltip
                    place="left"
                    style={{
                        zIndex: 1,
                        boxShadow: '0 4px 20px 0 rgba(74, 71, 113, 0.2)',
                        textAlign: 'right', whiteSpace: 'pre-wrap', backgroundColor: '#fff', opacity: 1, fontSize: 14, borderRadius: 8, color: '#1f0039'
                    }}
                    id="guest-tooltip" />
            </div>}

            {(currentUser?.type?.id == 3 || type == 'company') ?
                <div className='side-menu'>
                    <div onClick={() => {
                        dispatch({ type: 'setTabMatch', payload: 1 });
                        location?.pathname === '/company' ? navigate(0) : navigate('/company')
                    }} style={{ margin: '0 auto', backgroundColor: chosenMenu === 0 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10 }}>
                        <img alt="main" src={icMain} />
                        <span>ראשי</span>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { dispatch({ type: 'setTabMatch', payload: 1 }); navigate('/company/candidates') }} style={{ opacity: disabled ? 0.4 : 1, pointerEvents: disabled ? 'none' : 'all', margin: '0 auto', backgroundColor: chosenMenu === 1 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10 }}>
                            <img alt="candidates" src={icCandidates} />
                            <span>מועמדים</span>
                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { dispatch({ type: 'setTabMatch', payload: 1 }); navigate('/company/jobs') }} style={{ opacity: disabled ? 0.4 : 1, pointerEvents: disabled ? 'none' : 'all', margin: '0 auto', backgroundColor: chosenMenu === 2 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10 }}>
                            <img alt="positions" src={icPositions} />
                            <span>משרות</span>
                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { dispatch({ type: 'setTabMatch', payload: 1 }); navigate('/company/chats') }} style={{ opacity: disabled ? 0.4 : 1, pointerEvents: disabled ? 'none' : 'all', margin: '0 auto', backgroundColor: chosenMenu === 3 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10 }}>
                            <img alt="chat" src={icChat} />
                            <span>צ׳אט</span>
                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { dispatch({ type: 'setTabMatch', payload: 1 }); navigate('/company/setting') }} style={{ opacity: disabled ? 0.4 : 1, pointerEvents: disabled ? 'none' : 'all', margin: '0 auto', backgroundColor: chosenMenu === 4 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10 }}>
                            <img alt="setting" src={icSetting} />
                            <span>הגדרות</span>
                        </div>
                    </div>
                    <div style={{ position: "absolute", bottom: 0, color: "white" }}>v {VERSION}</div>
                </div>
                :
                <div style={{ height: 'auto' }} className='side-menu'>
                    <div onClick={() => {
                        dispatch({ type: 'setTabMatch', payload: 1 });
                        location?.pathname === '/employee' ? navigate(0) : navigate('/employee')
                    }} style={{ margin: '0 auto', backgroundColor: chosenMenu === 0 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10 }}>
                        <img alt="main" src={icMain} />
                        <span>ראשי</span>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { dispatch({ type: 'setTabMatch', payload: 1 }); navigate('/employee/candidates') }} style={{ opacity: disabled ? 0.4 : 1, pointerEvents: disabled ? 'none' : 'all', margin: '0 auto', backgroundColor: chosenMenu === 1 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10 }}>
                            <img alt="positions" src={icPositions} />
                            <span>המשרות שלי</span>
                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { dispatch({ type: 'setTabMatch', payload: 1 }); navigate('/employee/chats') }} style={{ opacity: disabled ? 0.4 : 1, pointerEvents: disabled ? 'none' : 'all', margin: '0 auto', backgroundColor: chosenMenu === 2 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10 }}>
                            <img alt="chat" src={icChat} />
                            <span>צ׳אט</span>
                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { dispatch({ type: 'setTabMatch', payload: 1 }); navigate('/employee/setting', { state: { refresh: true } }) }} style={{ opacity: disabled ? 0.4 : 1, pointerEvents: disabled ? 'none' : 'all', margin: '0 auto', backgroundColor: chosenMenu === 3 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10 }}>
                            <img alt="setting" src={icSetting} />
                            <span>הגדרות</span>
                        </div>
                    </div>
                    <div style={{ position: "absolute", bottom: 0, color: "white" }}>v {VERSION}</div>
                </div>
            }
        </>
    );

}
