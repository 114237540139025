import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { icArrowDown, icDefault, icFilter, icRadio, icRadioColor, icSearch } from '../assets/images/images';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Autocomplete from 'react-autocomplete';
import { MenuItem, Select } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { getEmployeeDetails } from '../store/actions';
const _ = require('lodash');

export default function SideBarJobUser({ likes, handleJobFilter, handleFilterCity, handleFilterDistance }) {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userData);
  const list = useSelector((state) => state.listDetails);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const [filterBy, setFilterBy] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [distanceList, setDistanceList] = useState([]);
  const [city, setCity] = useState({ id: null, label: '' });
  const [distance, setDistance] = useState({ id: null, label: '' });

  const [openFilter, setOpenFilter] = useState(false);

  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const [jobRole, setJobRole] = useState(jobs[0] ?? { id: null, label: '' });

  useEffect(() => {
    currentUser !== null &&
      dispatch(getEmployeeDetails(currentUser?.id, currentUser?.token)).then((res) => {
        const tmpJobs = res.data.result.employeeJobRoleExperiences?.map((item) => {
          return { id: item?.jobRole?.id, label: item?.jobRole?.itemText };
        });
        setJobs(tmpJobs);
        setJobRole(tmpJobs[0]);
        setUser(res.data.result);
      });

    const tmpDistanceList = [];
    const tmpList = list?.distanceScore.sort((a, b) => a.minDistance - b.minDistance);
    tmpList?.forEach((item) =>
      tmpDistanceList.push({ id: item.id, label: `${item.minDistance} - ${item.maxDistance} ק״מ` })
    );
    setDistanceList(tmpDistanceList);

    const tmpCity = [];
    list?.city.forEach((city) => tmpCity.push({ id: city.id, label: city.itemTextHeb }));
    setCityList(tmpCity);
  }, [list]);

  return isTabletOrMobile ? (
    user?.employeeFiles.filter((item) => item.cvVideoFile !== null).length === 0 ? null : currentUser ===
      null ? null : (
      <>
        <div style={{ width: '90%', alignSelf: 'center' }} className='d-flex align-items-center position-relative mt-2'>
          <Autocomplete
            inputProps={{
              style: {
                border: `1px solid '#D4DAF8`,
                width: '80vw',
                height: '44px',
                marginLeft: 5,
                marginTop: 0,
              },
              className: 'auto-complete icon',
              placeholder: 'חפש לפי תפקיד',
            }}
            value={jobRole?.label}
            items={jobs}
            shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
            wrapperStyle={{ position: 'relative' }}
            getItemValue={(item) => item.label}
            onSelect={(value, item) => {
              handleJobFilter(item);
              setJobRole(item);
            }}
            onChange={(event, value) => {
              if (value.length === 0) {
                handleJobFilter([]);
              }
              setJobRole({ id: null, label: value });
            }}
            renderMenu={(items) => (
              <div style={{ width: '100%' }} className='menu'>
                {items}
              </div>
            )}
            renderItem={(item) => <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}</p>}
            renderInput={(props) => (
              <>
                {jobRole?.label === '' && (
                  <img alt='' style={{ zIndex: 1, position: 'absolute', top: 14, right: 12 }} src={icSearch} />
                )}
                <input {...props} />
              </>
            )}
          />

          <img alt='icon' style={{ cursor: 'pointer' }} onClick={() => setOpenFilter(!openFilter)} src={icFilter} />
          {openFilter && (
            <div className='filters'>
              <div style={{ position: 'relative' }}>
                <div className='triangle' />
                <div className='rectangle'>
                  <span>פילטור נוסף</span>
                  <div className='d-flex justify-content-between'>
                    <div style={{ cursor: 'pointer' }} onClick={() => setFilterBy(false)}>
                      <img alt='icon' src={filterBy ? icRadio : icRadioColor} />
                      <span className='pr-1'>חיפוש לפי עיר</span>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => setFilterBy(true)}>
                      <img alt='icon' src={!filterBy ? icRadio : icRadioColor} />
                      <span className='pr-1'>חיפוש לפי מרחק</span>
                    </div>
                  </div>
                  {!filterBy ? (
                    <Autocomplete
                      inputProps={{
                        style: {
                          width: '100%',
                          marginRight: 0,
                          border: `1px solid #D4DAF8`,
                          height: '40px',
                          marginLeft: 16,
                        },
                        className: 'auto-complete',
                        placeholder: 'עיר',
                      }}
                      value={city.label}
                      items={cityList}
                      shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
                      getItemValue={(item) => item.label}
                      wrapperStyle={{ position: 'relative' }}
                      onSelect={(value, item) => {
                        setCity(item);
                        handleFilterCity(item.id);
                      }}
                      onChange={(event, value) => {
                        setCity({ id: null, label: value });
                        if (value.length === 0) {
                          handleFilterCity(null);
                        }
                      }}
                      renderMenu={(items, value, style) => (
                        <div style={{ width: '100%' }} className='menu'>
                          {items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}</p>
                      )}
                    />
                  ) : (
                    <Select
                      inputProps={{
                        IconComponent: () => null,
                        sx: { padding: '0 !important' },
                      }}
                      renderValue={(value) => {
                        if (distance.label === '') {
                          return (
                            <div
                              style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}
                            >
                              <span style={{ textAlign: 'right', fontWeight: 'normal' }}>
                                באיזה טווח נחפש לך משרות?
                              </span>
                              <img alt='arrow' src={icArrowDown} />
                            </div>
                          );
                        }

                        return (
                          <div
                            style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}
                          >
                            <span style={{ textAlign: 'right', fontWeight: 'normal' }}>{value.label}</span>
                            <img alt='arrow' src={icArrowDown} />
                          </div>
                        );
                      }}
                      displayEmpty
                      value={distance.label === '' ? '' : distance}
                      variant='outlined'
                      onChange={({ target }) => {
                        if (target.value.id === null) setDistance({ id: null, label: target.value.label });
                        else setDistance(target.value);
                        handleFilterDistance(target.value.id);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                        PaperProps: {
                          sx: {
                            '&& .Mui-selected:focus': {
                              backgroundColor: '#f8f8ff',
                            },
                            '&& .Mui-selected:hover': {
                              backgroundColor: '#f8f8ff',
                            },
                            '& .MuiMenuItem-root:hover': {
                              backgroundColor: '#f8f8ff',
                            },
                            '& .MuiMenuItem-root:focus': {
                              backgroundColor: '#ffff',
                            },
                          },
                        },
                      }}
                      sx={{
                        marginTop: 1.3,
                        fontSize: 16,
                        fontFamily: 'Heebo',
                        color: '#1f0039',
                        height: '40px',
                        width: '100%',
                        borderRadius: '8px',
                        border: `1px solid #D4DAF8`,
                        '.MuiOutlinedInput-notchedOutline': {
                          border: '0px',
                          textAlign: 'right',
                        },
                      }}
                    >
                      {[...distanceList, { id: null, label: 'ללא הגבלה' }].map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            sx={{
                              fontSize: 16,
                              fontFamily: 'Heebo',
                              color: '#1f0039',
                              direction: 'rtl',
                            }}
                            value={item}
                          >
                            <span>{item.label}</span>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={{ overflowY: 'scroll', padding: 15, gap: 7 }} className='d-flex flex-row'>
          {jobs?.slice(0, 5).map((item) => (
            <div
              key={item.id}
              style={{ cursor: 'pointer', border: jobRole.id === item.id ? 'solid 1px #4f35be' : '0px' }}
              className='filter-tag'
              onClick={() => {
                if (jobRole.id === item.id) {
                  setJobRole({ id: null, label: '' });
                  handleJobFilter(
                    jobs.map((item) => {
                      return { id: item.id, label: item.label };
                    })
                  );
                } else {
                  handleJobFilter(item);
                  setJobRole(item);
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      </>
    )
  ) : (
    <div style={{ marginBottom: 20 }} className='side-bar-user'>
      {
        // user?.employeeFiles.filter(item => item.cvVideoFile !== null).length === 0 ?
        // <main>
        //     <span>
        //         <b>
        //             עדיין לא העלת וידאו...
        //             העלה עכשיו כדי להופיע אצל מעסיקים וליצור התאמות.
        //         </b>
        //     </span>
        //     <Button style={{ marginTop: 13 }} onPress={() => navigate('/employee/video')} text={'העלה וידאו'} />
        //     <div style={{ marginTop: 20, height: 1, backgroundColor: '#d4daf8', width: '100%', }} />
        // </main>
        // :
        <main>
          <div className='d-flex align-items-center position-relative mt-4'>
            <Autocomplete
              inputProps={{
                style: {
                  border: `1px solid '#D4DAF8`,
                  width: '100%',
                  height: '44px',
                  marginTop: 0,
                },
                className: 'auto-complete icon',
                placeholder: 'חפש לפי תפקיד',
              }}
              value={jobRole?.label}
              items={jobs}
              shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
              wrapperStyle={{ position: 'relative' }}
              getItemValue={(item) => item.label}
              onSelect={(value, item) => {
                handleJobFilter(item);
                setJobRole(item);
              }}
              onChange={(event, value) => {
                if (value.length === 0) {
                  handleJobFilter([]);
                }
                setJobRole({ id: null, label: value });
              }}
              // onSelect={(value, item) => {
              //     handleSelect(item)
              // }}

              // onChange={(event, value) => {
              //     if (value.length == 0) {
              //         handleJobFilter(jobs.map(item => { return { id: item.id, label: item.label } }))
              //     }
              //     setJobRole({ id: null, label: value })
              // }}
              renderMenu={(items) => (
                <div style={{ width: '100%' }} className='menu'>
                  {items}
                </div>
              )}
              renderItem={(item) => <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}</p>}
              renderInput={(props) => (
                <>
                  {jobRole?.label === '' && (
                    <img alt='' style={{ zIndex: 1, position: 'absolute', top: 14, right: 12 }} src={icSearch} />
                  )}
                  <input {...props} />
                </>
              )}
            />

            <img alt='icon' style={{ cursor: 'pointer' }} onClick={() => setOpenFilter(!openFilter)} src={icFilter} />
            {openFilter && (
              <div style={{ top: -40 }} className='filters'>
                <div className='triangle' />
                <div className='rectangle'>
                  <span>פילטור נוסף</span>
                  <div className='d-flex justify-content-between'>
                    <div style={{ cursor: 'pointer' }} onClick={() => setFilterBy(false)}>
                      <img alt='icon' src={filterBy ? icRadio : icRadioColor} />
                      <span className='pr-1'>חיפוש לפי עיר</span>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => setFilterBy(true)}>
                      <img alt='icon' src={!filterBy ? icRadio : icRadioColor} />
                      <span className='pr-1'>חיפוש לפי מרחק</span>
                    </div>
                  </div>
                  {!filterBy ? (
                    <Autocomplete
                      inputProps={{
                        style: {
                          width: '100%',
                          marginRight: 0,
                          border: `1px solid #D4DAF8`,
                          height: '40px',
                          marginLeft: 16,
                        },
                        className: 'auto-complete',
                        placeholder: 'עיר',
                      }}
                      value={city.label}
                      items={cityList}
                      shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
                      getItemValue={(item) => item.label}
                      wrapperStyle={{ position: 'relative' }}
                      onSelect={(value, item) => {
                        setCity(item);
                        handleFilterCity(item.id);
                      }}
                      onChange={(event, value) => {
                        setCity({ id: null, label: value });
                        if (value.length === 0) {
                          handleFilterCity(null);
                        }
                      }}
                      renderMenu={(items, value, style) => (
                        <div style={{ width: '100%' }} className='menu'>
                          {items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}</p>
                      )}
                    />
                  ) : (
                    <Select
                      inputProps={{
                        IconComponent: () => null,
                        sx: { padding: '0 !important' },
                      }}
                      renderValue={(value) => {
                        if (distance.label === '') {
                          return (
                            <div
                              style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}
                            >
                              <span style={{ textAlign: 'right', fontWeight: 'normal' }}>
                                באיזה טווח נחפש לך משרות?
                              </span>
                              <img alt='arrow' src={icArrowDown} />
                            </div>
                          );
                        }

                        return (
                          <div
                            style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}
                          >
                            <span style={{ textAlign: 'right', fontWeight: 'normal' }}>{value.label}</span>
                            <img alt='arrow' src={icArrowDown} />
                          </div>
                        );
                      }}
                      displayEmpty
                      value={distance.label === '' ? '' : distance}
                      variant='outlined'
                      onChange={({ target }) => {
                        if (target.value.id === null) setDistance({ id: null, label: target.value.label });
                        else setDistance(target.value);
                        handleFilterDistance(target.value.id);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                        PaperProps: {
                          sx: {
                            '&& .Mui-selected:focus': {
                              backgroundColor: '#f8f8ff',
                            },
                            '&& .Mui-selected:hover': {
                              backgroundColor: '#f8f8ff',
                            },
                            '& .MuiMenuItem-root:hover': {
                              backgroundColor: '#f8f8ff',
                            },
                            '& .MuiMenuItem-root:focus': {
                              backgroundColor: '#ffff',
                            },
                          },
                        },
                      }}
                      sx={{
                        marginTop: 1.3,
                        fontSize: 16,
                        fontFamily: 'Heebo',
                        color: '#1f0039',
                        height: '40px',
                        width: '100%',
                        borderRadius: '8px',
                        border: `1px solid #D4DAF8`,
                        '.MuiOutlinedInput-notchedOutline': {
                          border: '0px',
                          textAlign: 'right',
                        },
                      }}
                    >
                      {[...distanceList, { id: null, label: 'ללא הגבלה' }].map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            sx={{
                              fontSize: 16,
                              fontFamily: 'Heebo',
                              color: '#1f0039',
                              direction: 'rtl',
                            }}
                            value={item}
                          >
                            <span>{item.label}</span>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </div>
              </div>
            )}
          </div>
          <div style={{ marginRight: 0, gap: 12, marginTop: 24 }} className='d-flex flex-column'>
            {jobs?.slice(0, 5).map((item) => (
              <div
                key={item.id}
                style={{ cursor: 'pointer', border: jobRole.id === item.id ? 'solid 1px #4f35be' : '0px' }}
                className='filter-tag'
                onClick={() => {
                  if (jobRole.id === item.id) {
                    setJobRole({ id: null, label: '' });
                    handleJobFilter([]);
                  } else {
                    handleJobFilter(item);
                    setJobRole(item);
                  }
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        </main>
      }
      <main>
        <div style={{ height: 1, backgroundColor: '#d4daf8', width: '100%' }} />
        <div style={{ margin: '20px 20px 0px 20px', display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontSize: 16, fontWeight: 'normal', marginBottom: 20, textAlign: 'right' }}>משרות שאהבת</span>
          {_.takeRight(likes, 5).map((like, index) => (
            <div key={index} className='candidate'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  alt='default'
                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                  width='30'
                  height='30'
                  src={like?.company?.logo?.url ?? icDefault}
                />
                <div
                  style={{
                    justifyContent: 'center',
                    paddingRight: 5,
                    height: '80%',
                    flexDirection: 'column',
                    display: 'flex',
                  }}
                >
                  <span
                    style={{ padding: '0px 10px 0px 0px', fontWeight: 'normal', fontSize: 14, textAlign: 'right' }}
                  >{`${like?.jobRolesJob[0]?.jobRole.itemText}`}</span>
                  <span
                    style={{
                      padding: '0px 10px 0px 0px',
                      textAlign: 'right',
                      fontWeight: 'normal',
                      color: '#646464',
                      fontSize: 12,
                    }}
                  >{`${like?.company.name}`}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}
