import React from 'react';
import { icDefault, icSideMenu } from '../assets/images/images';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
const _ = require('lodash');
export default function SideBarJobGuest({ likes }) {

    const navigate = useNavigate()
    return (
        <div style={{ marginBottom: 20, }} className='side-bar'>
            <main>

                <img style={{ alignSelf: 'center' }} alt="menu" width={180} height={180} src={icSideMenu} />
                <span>
                    <b>
                        הרשם בכדי לצפות בכל המשרות<br></br>
                        וליצור התאמות
                    </b>
                </span>
                <div style={{ width: 240, margin: '0 auto', marginTop: 30, marginBottom: 17 }} className='d-flex row justify-content-between'>
                    <Button onPress={() => navigate('/register-employee')} style={{  paddingBottom:10,  width: 110, height: 33, borderRadius: 4 }} text={'הרשמה'} />
                    <Button onPress={() => navigate('/employee-login')} style={{  paddingBottom:10,  borderRadius: 4, height: 33, width: 110, backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} text={'התחברות'} />
                </div>

            </main>
            <main>
                <div style={{ height: 1, backgroundColor: '#d4daf8', width: '100%', }} />
                <div style={{ margin: '20px 20px 0px 20px', display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: 16, fontWeight: 'normal', marginBottom: 20, textAlign: 'right' }} >משרות שאהבת</span>
                    {_.takeRight(likes, 5).map(like =>
                        <div
                            className='candidate'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt="default" style={{objectFit:'cover', borderRadius: '50%' }} width="30" height="30" src={like?.company?.logo?.url ?? icDefault} />
                                <div style={{ justifyContent: 'center', paddingRight: 5, height: '80%', flexDirection: 'column', display: 'flex' }}>
                                    <span style={{ padding: '0px 10px 0px 0px', fontWeight: 'normal', fontSize: 14, textAlign: 'right', }}>{`${like?.jobRolesJob[0]?.jobRole?.itemText}`}</span>
                                    <span style={{ padding: '0px 10px 0px 0px', textAlign: 'right', fontWeight: 'normal', color: '#646464', fontSize: 12 }}>{`${like?.company.name}`}</span>

                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </main>
        </div>
    );

}
