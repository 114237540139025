import React, { useEffect, useState } from 'react';
import { icError, icEye } from '../../../assets/images/images';
import Autocomplete from 'react-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, TextInput, LoadingModal } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import { createEmployee, getUserDetails, saveToFavEmployee, updateUserDevice } from '../../../store/actions';
import { getOS, validatePhoneNum } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { Terms } from '../../../components';
import axios from 'axios';
import moment from 'moment';
import { LOGIN_SUCCESS } from '../../../store/actionTypes';
import { checkStrongPassword, isAbove18 } from '../../../utils/validator';
import PwdConstrains from '../../../components/PwdConstrains';
import trim from 'validator/es/lib/trim';
import isNull from 'lodash/isNull';
import isEmail from 'validator/es/lib/isEmail';
import isNumeric from 'validator/es/lib/isNumeric';

export default function EmployeeDetails({ handleNext, data }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.listDetails);
  const [isFetching, setIsFetching] = useState(false);

  const [city, setCity] = useState({ id: null, label: '' });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday] = useState('');
  const [userImage] = useState(null);

  const [phone, setPhone] = useState('');
  const [distance] = useState({ id: null, label: '' });
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [validatePassword, setValidatePassword] = useState('');

  const [cityList, setCityList] = useState([]);
  const [, setDistanceList] = useState([]);

  const [validCity, setValidCity] = useState(false);
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [, setValidBirthday] = useState(false);
  const [, setValidBirthdayAge] = useState(false);

  const [validPhone, setValidPhone] = useState('');
  const [validEmail, setValidEmail] = useState(null);
  const [, setValidTerms] = useState(false);
  const [, setValidDistance] = useState(false);

  const [terms, setTerms] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const [userExists, setUserExists] = useState(false);

  const [validPassword, setValidPassword] = useState('');
  const likes = useSelector((state) => state.likes);
  const maybes = useSelector((state) => state.maybes);
  const [showPass, setShowPass] = useState(false);
  const [showPassValid, setShowPassValid] = useState(false);

  useEffect(() => {
    const tmpDistanceList = [];
    const tmpList = list?.distanceScore.sort((a, b) => a.minDistance - b.minDistance);
    tmpList?.forEach((item) =>
      tmpDistanceList.push({ id: item.id, label: `${item.minDistance} - ${item.maxDistance} ק״מ` })
    );
    setDistanceList(tmpDistanceList);

    const tmpCity = [];
    list?.city.forEach((city) => tmpCity.push({ id: city.id, label: city.itemTextHeb }));
    setCityList(tmpCity);
  }, [list]);

  const handleSubmit = () => {
    const above18 = isAbove18(birthday);
    if (firstName === '') {
      setValidFirstName(true);
    }
    if (lastName === '') {
      setValidLastName(true);
    }
    if (!moment(birthday, 'DD/MM/YYYY', true).isValid()) {
      setValidBirthday(true);
    }
    if (!above18) {
      setValidBirthdayAge(true);
    }

    const validNumber = validatePhoneNum(phone);
    if (phone === '' || validNumber) {
      setValidPhone(true);
    }

    const valid = isEmail(email, {
      allow_utf8_local_part: false,
    });

    setValidEmail(!!!(!valid || email === ''));

    if (city?.id === null) {
      setValidCity(true);
    }
    if (!checkStrongPassword(password) || password === '' || validatePassword === '' || validatePassword !== password) {
      setValidPassword(true);
    }
    if (!terms) {
      setValidTerms(true);
    }
    if (distance?.id === null) {
      setValidDistance(true);
    }
    if (
      terms &&
      city?.id !== null &&
      firstName !== '' &&
      lastName !== '' &&
      phone !== '' &&
      !validNumber &&
      email !== '' &&
      !!valid &&
      password !== '' &&
      validatePassword !== '' &&
      validatePassword === password &&
      checkStrongPassword(password)
    ) {
      setIsFetching(true);
      const formData = new FormData();

      formData.append('city', city.id);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('password', password);
      //formData.append('birthday', birthday.split('/').reverse().join('-'));
      //formData.append('distance', distance?.id);
      formData.append('isRegistered', true);
      userImage !== null && formData.append('userImage', userImage, userImage.name);
      dispatch(createEmployee(formData))
        .then(async (res) => {
          dispatch(getUserDetails(res.data.result?.savedEmployee?.user?.id, res.data.result?.accessToken)).then(
            async (result) => {
              if (res.status === 200) {
                dispatch({ type: 'GET_JOB_LIST_REQUEST' });

                if (likes.length > 0 || maybes.length > 0) {
                  likes.forEach((like) => {
                    const formData = new FormData();
                    formData.append('like', true);
                    formData.append('jobId', like.id);

                    dispatch(saveToFavEmployee(formData, res.data.result?.accessToken, true));
                  });
                  maybes.forEach((maybe) => {
                    const formData = new FormData();
                    formData.append('like', true);
                    formData.append('jobId', maybe.id);

                    dispatch(saveToFavEmployee(formData, res.data.result?.accessToken, true));
                  });
                }

                setIsFetching(false);
                const user = {
                  ...res.data.result,
                  ...result.data.result,
                  token: res.data.result?.accessToken,
                };
                sessionStorage.setItem('userData', JSON.stringify(user));
                dispatch({ type: LOGIN_SUCCESS, payload: user });
                handleNext();

                //const response = await axios.get('https://api.my-ip.io/v2/ip.json');
                const formData1 = new FormData();
                formData1.append('os', getOS());
                formData1.append('ip', null);
                formData1.append('deviceId', '1');
                formData1.append('pushToken', 'sometoken');
                dispatch(updateUserDevice(formData1, res.data.result?.accessToken));
              }
            }
          );
        })
        .catch((err) => {
          setIsFetching(false);

          if (err.response.data.message.includes('User exists')) {
            setUserExists(true);
            setTimeout(() => {
              setUserExists(false);
            }, 3000);
          }
        });
    }
  };

  return (
    <>
      <span
        style={{
          fontSize: isTabletOrMobile ? '24px' : '32px',
          textAlign: 'right',
          fontWeight: 'bold',
          marginTop: isTabletOrMobile ? '16px' : '60px',
        }}
      >
        {'יצירת פרופיל'}
      </span>
      <span style={{ fontSize: '18px', marginTop: '24px' }}>פרטים אישיים</span>
      <div style={{ flexWrap: 'wrap', flexDirection: isTabletOrMobile ? 'column' : 'row' }} className='d-flex'>
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${validFirstName ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={firstName}
          onChange={(value) => {
            setFirstName(value);
            setValidFirstName(false);
          }}
          label={'שם פרטי *'}
        />
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${validLastName ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={lastName}
          onChange={(value) => {
            setLastName(value);
            setValidLastName(false);
          }}
          label={'שם משפחה *'}
        />
      </div>
      {/* <div style={{ marginTop: '24px', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', }} className='d-flex'>
                <span style={{ width: '34%', fontSize: '18px', marginLeft: 20 }}>תאריך לידה: *</span>
                <DateMask isError={validBirthday} onChange={(day, month, year) => { setBirthday(`${day}/${month}/${year}`); setValidBirthday(false) }} />
            </div> */}
      {/* <div style={{ marginTop: 24, cursor: 'pointer' }} className='file_button_container frame'>
                {userImage ?
                    <div className='position-relative'>
                        <img alt="icon" width="146" height="146" style={{ objectFit: 'cover', borderRadius: '50%' }} src={URL.createObjectURL(userImage)} />
                        <div style={{cursor:'pointer', backgroundColor: '#ffffff', left: 0, bottom: 0, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 4, border: '1px solid #d4daf8', borderRadius: 8, }}>
                            <img alt="icon" onClick={() => setUserImage(null)} width="24" height="24" src={icDelete} />
                        </div>
                    </div>
                    :
                    <>
                        <img alt="upload" src={icUpload} />
                        <div>
                            <img alt="profile" src={icImage} />
                            <span style={{ paddingRight: 10 }}>העלה תמונת פרופיל</span>
                        </div>
                        <input
                            onChange={(event) => {
                                setUserImage(event.target.files[0]);
                            }}
                            accept="image/png,image/jpeg"
                            type="file" />
                    </>}
            </div> */}
      <span style={{ fontSize: '18px', marginTop: '24px' }}>פרטי התקשרות</span>
      <div
        style={{ marginTop: 8, flexDirection: isTabletOrMobile ? 'column' : 'row', flexWrap: 'wrap' }}
        className='d-flex'
      >
        <div className='d-flex'>
          <TextInput
            style={{
              marginRight: 0,
              marginLeft: 12,
              border: `1px solid ${validPhone ? 'red' : '#D4DAF8'}`,
            }}
            className='auto-complete'
            value={phone}
            onChange={(value) => {
              setPhone((prev) => {
                return isNumeric(value) ? trim(value) : value === '' ? '' : prev;
              });
              setValidPhone(false);
            }}
            label={'טלפון * (יש להזין ספרות בלבד)'}
          />
        </div>
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${(!isNull(validEmail) && !validEmail) || userExists ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={email}
          onChange={(value) => {
            setEmail(trim(value));
            setValidEmail(null);
          }}
          label={'אימייל *'}
        />
      </div>
      {userExists && (
        <div
          style={{
            display: 'flex',
            marginTop: 10,
            fontWeight: '500',
            alignItems: 'center',
            textAlign: 'right',
            height: '41px',
            color: '#ff2525',
            border: '1px solid',
            borderColor: '#ff2525',
            borderRadius: 4,
            padding: '10px 12px',
          }}
        >
          <img alt='error' src={icError} />
          <span style={{ paddingRight: 10, fontSize: 14 }}>האימייל קיים במערכת, נא צור קשר לתמיכה</span>
        </div>
      )}
      <span style={{ fontSize: '18px', marginTop: '24px' }}>מגורים</span>
      <div
        style={{ marginTop: 8, flexDirection: isTabletOrMobile ? 'column' : 'row', flexWrap: 'wrap' }}
        className='d-flex'
      >
        <Autocomplete
          inputProps={{
            // onMouseLeave() {
            //     document.addEventListener("click", setOpenCity(false))
            // },
            // onClick() { setOpenCity(true) },
            style: {
              border: `1px solid ${validCity ? 'red' : '#D4DAF8'}`,
              marginLeft: 16,
            },
            className: 'auto-complete',
            placeholder: 'עיר *',
          }}
          value={city.label}
          items={cityList}
          shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
          getItemValue={(item) => item.label}
          wrapperStyle={{ position: 'relative' }}
          onSelect={(value, item) => {
            setCity(item);
            setValidCity(false);
          }}
          onChange={(event, value) => {
            setCity({ id: null, label: value });
            setValidCity(false);
          }}
          renderMenu={(items, value, style) => <div className='menu'>{items}</div>}
          renderItem={(item, isHighlighted) => (
            <p key={item.id} style={{ cursor: 'pointer', color: '#2f2072' }}>
              {item.label}
            </p>
          )}
        />
        {/* <Select
                    inputProps={{
                        IconComponent: () => null,
                        sx: { padding: '0 !important' }
                    }
                    }
                    renderValue={(value) => {
                        if (distance.label === "") {
                            return <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ textAlign: 'right' }}>באיזה טווח נחפש לך משרות?</span>
                                <img alt="arrow" src={icArrowDown} />
                            </div>

                        }

                        return (
                            <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ textAlign: 'right' }}>{value.label}</span>
                                <img alt="arrow" src={icArrowDown} />
                            </div>
                        );
                    }}

                    displayEmpty
                    value={distance.label === "" ? "" : distance}
                    variant='outlined'
                    onChange={({ target }) => { setDistance(target.value); setValidDistance(false) }}
                    MenuProps={{

                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        PaperProps: {
                            sx: {
                                "&& .Mui-selected:focus": {
                                    backgroundColor: "#f8f8ff"
                                },
                                "&& .Mui-selected:hover": {
                                    backgroundColor: "#f8f8ff"
                                },
                                "& .MuiMenuItem-root:hover": {
                                    backgroundColor: '#f8f8ff',
                                },
                                "& .MuiMenuItem-root:focus": {
                                    backgroundColor: '#ffff',
                                },

                            },
                        },
                    }}
                    sx={{
                        marginTop: 1.3,
                        fontSize: 16,
                        fontFamily: 'Heebo',
                        color: '#1f0039',
                        height: '48px',
                        width: isTabletOrMobile ? width - 55 : '320px',
                        borderRadius: '8px',
                        border: `1px solid ${validDistance ? 'red' : '#D4DAF8'}`,
                        '.MuiOutlinedInput-notchedOutline': {
                            border: '0px',
                            textAlign: 'right'
                        },

                    }}
                >
                    {distanceList.map(item => {
                        return (
                            <MenuItem
                                sx={{
                                    fontSize: 16,
                                    fontFamily: 'Heebo',
                                    color: '#1f0039',
                                    direction: 'rtl'
                                }}
                                value={item}>
                                <span>{item.label}</span>
                            </MenuItem>
                        )
                    })}
                </Select> */}
      </div>

      <span style={{ fontSize: '18px', marginTop: '24px' }}>יצירת סיסמה</span>
      <PwdConstrains />
      <div
        style={{ flexDirection: isTabletOrMobile ? 'column' : 'row', flexWrap: 'wrap' }}
        className='d-flex position-relative'
      >
        <div className='position-relative'>
          <TextInput
            style={{
              marginRight: 0,
              marginLeft: 16,
              border: `1px solid ${validPassword ? 'red' : '#D4DAF8'}`,
            }}
            className='auto-complete'
            value={password}
            security={!showPass}
            onChange={setPassword}
            label={'סיסמה *'}
          />
          <div
            style={{ cursor: 'pointer', position: 'absolute', left: 25, top: 20 }}
            onClick={() => setShowPass(!showPass)}
          >
            <img src={icEye} alt='iceEye' />
          </div>
        </div>
        <div className='position-relative'>
          <TextInput
            style={{
              marginRight: 0,
              marginLeft: 16,
              border: `1px solid ${validPassword ? 'red' : '#D4DAF8'}`,
            }}
            className='auto-complete'
            value={validatePassword}
            security={!showPassValid}
            onChange={(value) => {
              setValidatePassword(value);
              setValidPassword(false);
            }}
            label={'אישור סיסמה *'}
          />
          <div
            style={{ cursor: 'pointer', position: 'absolute', left: 25, top: 20 }}
            onClick={() => setShowPassValid(!showPassValid)}
          >
            <img src={icEye} alt='iceEye' />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 24 }}>
        <Checkbox
          checked={terms}
          onChange={() => setTerms(!terms)}
          handleClick={() => setOpenTerms(true)}
          className='terms'
          label='קראתי והבנתי את תנאי השימוש באתר ומדיניות הגנת הפרטיות של החברה והם מקובלים עלי.'
        />
      </div>
      {(validFirstName || validLastName || validPhone || (!isNull(validEmail) && !validEmail) || validCity) && (
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            fontWeight: '500',
            alignItems: 'center',
            textAlign: 'right',
            height: '41px',
            color: '#ff2525',
            border: '1px solid',
            borderColor: '#ff2525',
            borderRadius: 4,
            padding: '10px 12px',
          }}
        >
          <img alt='error' src={icError} />
          <span style={{ paddingRight: 10, fontSize: 14 }}>יש לוודא שכל הפרטים הוזנו כשורה</span>
        </div>
      )}
      {validPassword && (
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            fontWeight: '500',
            alignItems: 'center',
            textAlign: 'right',
            color: '#ff2525',
            border: '1px solid',
            borderColor: '#ff2525',
            borderRadius: 4,
            padding: '10px 12px',
          }}
        >
          <img alt='error' src={icError} />
          <span style={{ paddingRight: 10, fontSize: 14 }}>
            הסיסמה חייבת להיות באורך של לפחות 8 תווים, עם לפחות אות אחת גדולה, אות אחת קטנה, וספרה אחת
          </span>
        </div>
      )}

      <div style={{ marginTop: '30px', position: 'relative', marginRight: 0, marginBottom: 20 }} className='d-flex row'>
        <Button
          style={{
            opacity: !terms ? '0.5' : '1',
            pointerEvents: !terms ? 'none' : 'all',
          }}
          onPress={() => {
            handleSubmit();
          }}
          text={'הרשם'}
        />
        <Button
          style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }}
          onPress={() => navigate('/employee')}
          text={'המשך כאורח'}
        />
      </div>
      {openTerms && (
        <Terms
          title={'תנאי השימוש'}
          firstButtonText={'אני מסכים'}
          onClose={() => setOpenTerms(false)}
          firstButtonHandler={() => {
            setTerms(true);
            setOpenTerms(false);
          }}
          secondButtonText={'ביטול'}
          secondButtonHandler={() => setOpenTerms(false)}
        />
      )}
      {/* {validBirthdayAge && (
        <Dialog
          title={'שגיאה'}
          text={'הגיל המינימלי להרשמה הוא 18. '}
          firstButtonText={'אישור'}
          secondButtonText={'ביטול'}
          onClose={() => setValidBirthdayAge(false)}
          firstButtonHandler={() => setValidBirthdayAge(false)}
          secondButtonHandler={() => setValidBirthdayAge(false)}
        />
      )} */}
      <LoadingModal visible={isFetching} />
    </>
  );
}
