import React from 'react';

export default function Button({ image, onPress, style, text, className }) {
    return (
        <div onClick={onPress} style={style} className={`button ${className}`}>
            {text !== '' && <span>{text}</span>}
            {image !== undefined && <img alt="icon" style={{marginRight: text === '' ? 0 : 8}} src={image} />}
        </div>
    );

}
