import React, { useEffect, useState } from 'react';
import Button from './Button';
import { icClose } from '../assets/images/images';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { terms } from '../config';
export default function Terms({ title, firstButtonText, firstButtonHandler, secondButtonText, secondButtonHandler, onClose }) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
    const [scrolled, setScrolled] = useState(false)

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setScrolled(true)
        }

    }

    useEffect(() => {
        var div = document.getElementById('terms');

        var hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
        if (!hasVerticalScrollbar) setScrolled(true)
    }, [])



    return (
        <>
            <div className="mask" />
            <div style={{ minWidth: isTabletOrMobile ? '90vw' : '800px', width: 'auto' }} className='dialog'>
                <div style={{ width: '100%'}}>
                    <img style={{cursor:'pointer', position:'absolute', left:20}} alt="" width="24" height="24" onClick={onClose} src={icClose} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <span style={{ marginTop: 12, fontSize: isTabletOrMobile ? 24 : 24, fontWeight: 'bold', textAlign: 'center' }}>{title}</span>
                        <div
                            id="terms"
                            onScroll={handleScroll}
                            style={{textAlign:'right', minWidth: '50vw', width: '100%', color: '#827aa5', marginTop: 24, overflowY: 'scroll', height: '45vh', borderRadius: '8px', border: 'solid 1px #d4daf8', padding: '12px' }}>
                            <p style={{textAlign:'center'}}><b>הסכם משתמש, תקנון אתר ומדיניות פרטיות</b></p>
                            {terms}
                        </div>
                        <div style={{ width: '95%', marginTop: 40, position: 'relative', justifyContent: 'center' }} className='d-flex row'>
                            <Button style={{ 
                                opacity: !scrolled ? '0.5' : '1', pointerEvents: !scrolled ? 'none' : 'all',
                                maxWidth: '100%', width: isTabletOrMobile ? '100%' : 160, }} onPress={firstButtonHandler} text={firstButtonText} />
                            <Button onPress={secondButtonHandler} style={{
                                
                                maxWidth: '100%', marginRight: 12, backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} text={secondButtonText} />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );

}
