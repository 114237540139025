import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { icCandidates, icChat, icClose, icCloseWhite, icInsights, icLoginIcon, icLogo, icLogoWhite, icMain, icPositions, icRegister, icSetting } from '../assets/images/images';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from './Button';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { VERSION } from '../config';

export default function MobileSideMenu({ type, openDrawer, setOpenDrawer }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentUser = useSelector(state => state.userData)
    const company = useSelector(state => state.company)
    const location = useLocation();
    const [chosenMenu, setChosenMenu] = useState(1)


    useEffect(() => {
        currentUser?.type?.id == 3 ? dispatch({ type: 'GET_EMPLOYEES_REQUEST' }) : dispatch({ type: 'GET_JOB_LIST_REQUEST' })

        dispatch({ type: 'setTab', payload: 1 })
        const segments = location?.pathname.split('/');
        const id = segments[segments.length - 1];

        switch (location.pathname) {
            case '/company':
                setChosenMenu(0)
                break;
            case '/company/candidates':
                setChosenMenu(1)
                break;
            case '/company/employee-profile':
                setChosenMenu(1)
                break;
            case '/company/jobs':
                setChosenMenu(2)
                break;
            case `/company/jobs/${id}`:
                setChosenMenu(2)
                break;
            case '/company/chats':
                setChosenMenu(3)
                break;
            case '/company/setting':
                setChosenMenu(4)
                break;
            case '/employee':
                setChosenMenu(0)
                break;
            case '/employee/candidates':
                setChosenMenu(1)
                break;
            case '/employee/setting':
                setChosenMenu(3)
                break;
            case '/employee/chats':
                setChosenMenu(2)
                break;
            default:
                break;

        }
    }, [location, dispatch])
    const disabled = (currentUser === null || currentUser === undefined || (currentUser?.type?.id == 3 && company?.company?.status.id === 4))

    const handleDrawerClose = () => {
        setOpenDrawer(false)
    }

    const list = () => (
        <>
            {disabled && <div>
                <Tooltip
                    place="top"
                    style={{
                        zIndex: 1,
                        width: '95%',
                        boxShadow: '0 4px 20px 0 rgba(74, 71, 113, 0.2)',
                        textAlign: 'right', whiteSpace: 'pre-wrap', backgroundColor: '#fff', opacity: 1, fontSize: 12, borderRadius: 8, color: '#1f0039'
                    }}
                    id="guest-tooltip" />
            </div>}
            {(currentUser?.type?.id == 3 || type == 'company') ?
                <Box
                    sx={{ width: '82vw', height: '100%', backgroundColor: '#2f2072', padding: '0px 32px 0px 32px' }}
                    role="presentation"
                >
                    <div style={{ marginLeft: -16, marginTop: 50, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <img onClick={(e) => { handleDrawerClose(); e.stopPropagation() }} width='20' height='20' src={icCloseWhite} />
                        <img width="120" height="40" src={icLogoWhite} />
                    </div>

                    <div onClick={() => {
                        location?.pathname === '/company' ? navigate(0) : navigate('/company')
                        handleDrawerClose()
                    }} style={{
                        marginTop: 27,
                        alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 0 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                    }}>
                        <span>ראשי</span>
                        <img style={{ paddingLeft: 8 }} alt="main" src={icMain} />
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { navigate('/company/candidates'); handleDrawerClose() }} style={{
                            marginTop: 16,
                            opacity: disabled ? 0.4 : 1,
                            pointerEvents: disabled ? 'none' : 'all',
                            alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 1 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                        }}>
                            <span>מועמדים</span>
                            <img style={{ paddingLeft: 8 }} alt="candidates" src={icCandidates} />
                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { navigate('/company/jobs'); handleDrawerClose() }} style={{
                            marginTop: 16,
                            opacity: disabled ? 0.4 : 1,
                            pointerEvents: disabled ? 'none' : 'all',
                            alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 2 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                        }}>
                            <span>משרות</span>
                            <img style={{ paddingLeft: 8 }} alt="positions" src={icPositions} />
                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { navigate('/company/chats'); handleDrawerClose() }} style={{
                            marginTop: 16,
                            opacity: disabled ? 0.4 : 1,
                            pointerEvents: disabled ? 'none' : 'all',
                            alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 3 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                        }}>
                            <span>צ׳אט</span>
                            <img style={{ paddingLeft: 8 }} alt="chat" src={icChat} />

                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { navigate('/company/setting'); handleDrawerClose() }} style={{
                            marginTop: 16,
                            opacity: disabled ? 0.4 : 1,
                            pointerEvents: disabled ? 'none' : 'all',
                            alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 4 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                        }}>
                            <span>הגדרות</span>
                            <img style={{ paddingLeft: 8 }} alt="setting" src={icSetting} />

                        </div>
                    </div>

                    {currentUser == null && <>
                        <div style={{ marginTop: 20, }}>
                            <div onClick={() => { navigate('/company-login'); handleDrawerClose() }} style={{
                                marginTop: 16,
                                alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 4 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                            }}>
                                <span>התחברות</span>
                                <img style={{ paddingLeft: 8 }} alt="login" src={icLoginIcon} />
                            </div>
                        </div>
                        <div style={{ marginTop: 20, }}>
                            <div onClick={() => window.open('https://nona-jobs.com/partners/')} style={{
                                marginTop: 16,
                                alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 4 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                            }}>
                                <span>הרשמה</span>
                                <img style={{ paddingLeft: 8 }} alt="registr" src={icRegister} />
                            </div>
                        </div>
                    </>}
                    {currentUser !== null && <Button style={{ bottom: 50, position: 'absolute', width: '80%', backgroundColor: '#2f2072', border: '1px solid white' }} onPress={() => { navigate('/company/create-job') }} text={'+ צור משרה חדשה'} />}
                    <div style={{ position: "absolute", right: '45%', bottom: 10, color: "white", }}>v {VERSION}</div>

                </Box>
                :
                <Box
                    sx={{ position: 'relative', width: '82vw', height: '100%', backgroundColor: '#2f2072', padding: '0px 32px 0px 32px' }}
                    role="presentation"

                >
                    <div style={{ marginLeft: -16, marginTop: 50, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <img onClick={handleDrawerClose} width='20' height='20' src={icClose} />
                        <img width="120" height="40" src={icLogoWhite} />
                    </div>
                    <div onClick={() => {
                        location?.pathname === '/employee' ? navigate(0) : navigate('/employee')
                        handleDrawerClose()
                    }} style={{
                        marginTop: 27,
                        alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 0 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                    }}>
                        <span>ראשי</span>
                        <img style={{ paddingLeft: 8 }} alt="main" src={icMain} />
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { navigate('/employee/candidates'); handleDrawerClose() }} style={{
                            marginTop: 16,
                            opacity: disabled ? 0.4 : 1,
                            pointerEvents: disabled ? 'none' : 'all',
                            alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 1 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                        }}>
                            <span>המשרות שלי</span>
                            <img style={{ paddingLeft: 8 }} alt="candidates" src={icPositions} />
                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { navigate('/employee/chats'); handleDrawerClose() }} style={{
                            marginTop: 16,
                            opacity: disabled ? 0.4 : 1,
                            pointerEvents: disabled ? 'none' : 'all',
                            alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 2 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                        }}>
                            <span>צ׳אט</span>
                            <img style={{ paddingLeft: 8 }} alt="positions" src={icChat} />
                        </div>
                    </div>
                    <div style={{ marginTop: 20, }} data-tooltip-id="guest-tooltip" data-tooltip-content={'צפייה בעמוד זה זמינה למשתמשים רשומים בלבד'} >
                        <div onClick={() => { navigate('/employee/setting', { state: { refresh: true } }); handleDrawerClose() }} style={{
                            marginTop: 16,
                            opacity: disabled ? 0.4 : 1,
                            pointerEvents: disabled ? 'none' : 'all',
                            alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 3 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                        }}>
                            <span>הגדרות</span>
                            <img style={{ paddingLeft: 8 }} alt="chat" src={icSetting} />

                        </div>
                    </div>
                    {currentUser == null && <>
                        <div style={{ marginTop: 20, }}>
                            <div onClick={() => { navigate('/employee-login'); handleDrawerClose() }} style={{
                                marginTop: 16,
                                alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 4 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                            }}>
                                <span>התחברות</span>
                                <img style={{ paddingLeft: 8 }} alt="login" src={icLoginIcon} />
                            </div>
                        </div>
                        <div style={{ marginTop: 20, }}>
                            <div onClick={() => { navigate('/register-employee'); handleDrawerClose() }} style={{
                                marginTop: 16,
                                alignItems: 'center', display: 'flex', justifyContent: 'flex-end', paddingRight: 63, color: '#e8eefa', textAlign: 'center', backgroundColor: chosenMenu === 4 ? 'rgba(237,235,248,0.2)' : 'transparent', borderRadius: 10
                            }}>
                                <span>הרשמה</span>
                                <img style={{ paddingLeft: 8 }} alt="registr" src={icRegister} />
                            </div>
                        </div>
                    </>}
                    <div style={{ position: "absolute", right: '45%', bottom: 10, color: "white", }}>v {VERSION}</div>
                </Box>}
        </>
    );

    return (
        <React.Fragment key={'right'}>
            <Drawer
                anchor={'right'}
                open={openDrawer}
                onClose={() => handleDrawerClose()}
            >
                {list()}
            </Drawer>
        </React.Fragment>

    );
}