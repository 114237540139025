import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_FAIL,
  GET_LEGALS_REQUEST,
  GET_LEGALS_SUCCESS,
  GET_LEGALS_FAIL,
  GET_COMPANY_JOBS_REQUEST,
  GET_COMPANY_JOBS_SUCCESS,
  GET_COMPANY_JOBS_FAIL,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYESS_GUEST_REQUEST,
  GET_EMPLOYESS_GUEST_SUCCESS,
  GET_EMPLOYESS_GUEST_FAIL,
  GET_JOBS_GUEST_REQUEST,
  GET_JOBS_GUEST_SUCCESS,
  GET_JOBS_GUEST_FAIL,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  UPDATE_TEAM_MEMBER_REQUEST,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_FAIL,
  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAIL,
  GET_EMPLOYEE_MATCH_REQUEST,
  GET_EMPLOYEE_MATCH_SUCCESS,
  GET_EMPLOYEE_MATCH_FAIL,
  GET_COMPANY_MATCH_REQUEST,
  GET_COMPANY_MATCH_SUCCESS,
  GET_COMPANY_MATCH_FAIL,
  GET_JOB_LIST_SUCCESS,
  GET_JOB_LIST_REQUEST,
  GET_EMPLOYEE_MAYBE_REQUEST,
  GET_EMPLOYEE_MAYBE_SUCCESS,
  GET_EMPLOYEE_MAYBE_FAIL,
  GET_COMPANY_MAYBE_REQUEST,
  GET_COMPANY_MAYBE_SUCCESS,
  GET_COMPANY_MAYBE_FAIL,
  GET_INTERVIEWS_REQUEST,
  GET_INTERVIEWS_SUCCESS,
  GET_INTERVIEWS_FAIL,
  GET_EMPLOYEE_INTERVIEWS_REQUEST,
  GET_EMPLOYEE_INTERVIEWS_SUCCESS,
  GET_EMPLOYEE_INTERVIEWS_FAIL,
  GET_COMPANY_JOBS_USERS_REQUEST,
  GET_COMPANY_JOBS_USERS_SUCCESS,
  GET_COMPANY_JOBS_USERS_FAIL,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  GET_NOTIF_REQUEST,
  GET_NOTIF_SUCCESS,
  GET_NOTIF_FAIL,
} from './actionTypes';
import URL from '../api/urls';
import axios from '../axios/axiosInstance';
import * as UpChunk from '@mux/upchunk';

export function login(email, password, cookie, userType) {
  const body = {
    email: email,
    password: password,
    useCookie: cookie,
    userType: userType,
  };
  return async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .post(URL.LOGIN, body)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: LOGIN_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function handleCompanyReaction(employeeId, companyUserReaction, jobIds, token) {
  const headers = {
    Authorization: 'Bearer ' + token,
  };
  const body = {
    employeeId: employeeId,
    companyUserReaction: companyUserReaction,
    jobIds: jobIds,
  };
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(URL.REACT_EMPLOYEE, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function updateInterview(data, id, token) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.UPDATE_INTERVIEW}/${id}`, data, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function handleEmployeeReaction(employeeReaction, jobId, token) {
  const headers = {
    Authorization: 'Bearer ' + token,
  };
  const body = {
    employeeReaction: employeeReaction,
    jobId: jobId,
  };
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(URL.REACT_JOB, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function twoFactorAuth(code, email, password, uniq) {
  const body = {
    email: email,
    password: password,
    code: code,
    uniq: uniq,
  };
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(URL.TWO_FACTOR, body)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: LOGIN_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getNotif(id, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_NOTIF_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.GET_NOTIFICATION}${id ? '?company=' + id : ''}`, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: GET_NOTIF_SUCCESS, payload: res.data.result });
          resolve(res.data.result);
        })
        .catch((err) => {
          dispatch({ type: GET_NOTIF_FAIL });
          reject(err);
        });
    });
  };
}

export function readNotif(token, body) {
  const headers = {
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.READ_NOTIFICATION}`, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function getUserDetails(id, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.GET_USER_DETAILS}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          const user = {
            ...res.data.result,
            token: token,
          };
          if (user.type.id === 3) dispatch({ type: 'setCompany', payload: user.companyTeams[0] });

          dispatch({ type: LOGIN_SUCCESS, payload: user });
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: LOGIN_FAIL });
          reject(err);
        });
    });
  };
}

export function forgotPassword(email) {
  const body = {
    email: email,
  };
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(URL.FORGOT_PASS, body)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function resetPass(formData) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  for (var pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(URL.RESET_PASS, formData, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function saveToFavCompany(formData, token, isDelete) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.SAVE_TO_FAV_COMPANY}`, formData, {
          headers: headers,
        })
        .then((res) => {
          isDelete && dispatch({ type: 'setLikes', payload: [] });
          dispatch({ type: 'setFav', payload: [] });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function saveToFavEmployee(formData, token, isDelete) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.SAVE_TO_FAV_EMPLOYEE}`, formData, {
          headers: headers,
        })
        .then((res) => {
          isDelete && dispatch({ type: 'setLikes', payload: [] });
          dispatch({ type: 'setFav', payload: [] });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function updateCompany(formData, id, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  for (var pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }

  return async (dispatch) => {
    dispatch({ type: UPDATE_COMPANY_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.UPDATE_COMPANY}/${id}`, formData, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: UPDATE_COMPANY_SUCCESS, payload: res.data.result });
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: UPDATE_COMPANY_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function updateJob(formData, id, token) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.UPDATE_JOB}/${id}`, formData, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function updateTeam(formData, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  for (var pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }

  return async (dispatch) => {
    dispatch({ type: UPDATE_TEAM_MEMBER_REQUEST });
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.UPDATE_TEAM_MEMBER}`, formData, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: UPDATE_TEAM_MEMBER_SUCCESS });
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: UPDATE_TEAM_MEMBER_FAIL });
          reject(err);
        });
    });
  };
}

export function createEmployee(formData) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  for (var pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL.CREATE_EMPLOYEE}`, formData, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function updateEmployee(formData, id, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };
  for (var pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }

  return async (dispatch) => {
    dispatch({ type: UPDATE_EMPLOYEE_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.UPDATE_EMPLOYEE}/${id}`, formData, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: UPDATE_EMPLOYEE_SUCCESS });
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: UPDATE_EMPLOYEE_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function createInterview(token, body) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL.CREATE_INTERVIEW}`, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function createJob(token, body) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL.CREATE_JOB}`, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function createCompany(body) {
  const headers = {
    'Content-Type': 'application/json',
  };
  for (var pair of body.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL.CREATE_COMPANY}`, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function getComapnyJobs(token, id, status) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_COMPANY_JOBS_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.COMPANY_JOBS}/${id}?status=${status}`, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: GET_COMPANY_JOBS_SUCCESS, payload: res.data.result });
          resolve(res.data.result);
        })
        .catch((err) => {
          dispatch({ type: GET_COMPANY_JOBS_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getComapnyJobsUsers(token, id) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_COMPANY_JOBS_USERS_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.COMPANY_JOBS_USERS}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: GET_COMPANY_JOBS_USERS_SUCCESS, payload: res.data.result });
          resolve(res.data.result);
        })
        .catch((err) => {
          dispatch({ type: GET_COMPANY_JOBS_USERS_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function createTopList(token, body) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL.TOP_LIST}`, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function deleteTopList(token, id) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL.TOP_LIST}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function getJobsGuest(body) {
  const headers = {
    'Content-Type': 'application/json',
  };
  return async (dispatch) => {
    dispatch({ type: GET_JOBS_GUEST_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .put(URL.JOBS_GUEST, body, {
          headers: headers,
        })
        .then((res) => {
          const jobs =
            res.data.result.result.length > 0
              ? [...res.data.result.result, res.data.result.result[res.data.result.result.length - 1]]
              : res.data.result.result;
          dispatch({ type: GET_JOBS_GUEST_SUCCESS, payload: jobs });
          resolve(jobs);
        })
        .catch((err) => {
          dispatch({ type: GET_JOBS_GUEST_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getJobById(id) {
  const headers = {
    'Content-Type': 'application/json',
  };
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.GET_JOB}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function getJobList(userId, jobRoleIds, distance, excludeIds, cityId, token, filter, shareId) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };
  const jobId = shareId ?? '';

  const url = `${URL.JOB_LIST}?excludeIds=[${excludeIds}]${
    jobRoleIds?.length > 0 ? '&jobRoleIds=[' + jobRoleIds + ']' : ''
  }${distance !== null ? '&distance=' + distance : ''}${cityId !== null ? '&cityId=' + cityId : ''}${
    jobId !== '' ? '&jobId=' + jobId : ''
  }`;
  return async (dispatch) => {
    filter && dispatch({ type: GET_JOB_LIST_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${url}`, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: GET_JOB_LIST_SUCCESS, payload: res.data.result.result, filter: filter });
          resolve(res.data.result);
        })
        .catch((err) => {
          dispatch({ type: GET_JOBS_GUEST_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getEmployessGuest(body, shareId) {
  const headers = {
    'Content-Type': 'application/json',
  };

  return async (dispatch) => {
    dispatch({ type: GET_EMPLOYESS_GUEST_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.EMPLOYEES_GUEST}?employeeId=${shareId}`, body, {
          headers: headers,
        })
        .then((res) => {
          const employees =
            res.data.result.result.length > 0
              ? [...res.data.result.result, res.data.result.result[res.data.result.result.length - 1]]
              : res.data.result.result;
          dispatch({ type: GET_EMPLOYESS_GUEST_SUCCESS, payload: employees });
          resolve(employees);
        })
        .catch((err) => {
          dispatch({ type: GET_EMPLOYESS_GUEST_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getCompanyChatList(token, id) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.COMPANY_CHAT_LIST}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function getEmployeeChatList(token, id) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.EMPLOYEE_CHAT_LIST}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function getChatById(id) {
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.GET_CHAT}/${id}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function addMessage(token, body, id) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL.ADD_MESSAGE}/${id}`, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function addReport(token, body) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL.ADD_REPORT}`, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function createChat(token, body) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL.GET_CHAT}`, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function getEmployees(companyId, excludeIds, jobIds, city, distance, token, filter, shareId) {
  const employeeId = shareId ?? '';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  const url =
    jobIds?.length === 0
      ? `${URL.GET_EMPLOYEES}?excludeIds=[${excludeIds}]&companyId=${companyId}&employeeId=${employeeId}`
      : `${URL.GET_EMPLOYEES}?excludeIds=[${excludeIds}]&companyId=${companyId}&jobIds=[${jobIds}]&cityId=${city}&distance=${distance}&employeeId=${employeeId}`;
  return async (dispatch) => {
    filter && dispatch({ type: GET_EMPLOYEES_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${url}`, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: GET_EMPLOYEES_SUCCESS, payload: res.data.result.result, filter: filter });
          resolve(res.data.result);
        })
        .catch((err) => {
          dispatch({ type: GET_EMPLOYEES_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getJobs(companyId, token) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_JOBS_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.GET_JOBS}/${companyId}`, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: GET_JOBS_SUCCESS, payload: res.data.result });
          resolve(res.data.result);
        })
        .catch((err) => {
          dispatch({ type: GET_JOBS_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getFileMuxUrl(video, companyId = null, employeeId = null) {
  return async () => {
    return new Promise((resolve, reject) => {
      axios.get(`${URL.FILE_MUX_URL}`).then((res) => {
        console.log(companyId, employeeId);
        return uploadMuxVideo(res.data.result, video, companyId, employeeId, resolve, reject);
      });
    });
  };
}

const uploadMuxVideo = async (data, video, companyId = null, employeeId = null, onSuccses, onError) => {
  console.log(employeeId, companyId);
  if (!video) {
    console.log('No file selected');
    return;
  }
  try {
    // const res = await fetch(data.url, {
    //   method: 'PUT',
    //   body: video,
    //   headers: { 'content-type': video.type },
    // });
    const upload = UpChunk.createUpload({
      endpoint: data.url, // Authenticated url
      file: video.file, // File object with your video file’s properties
      chunkSize: 2048,
    });
    upload.on('error', (error) => {
      console.log('Error:', error);
      onError(error);
    });
    upload.on('progress', (progress) => {
      console.log('Progress:', progress);
    });
    upload.on('success', async () => {
      console.log('Upload complete');
      await fetch(URL.FILE_MUX_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          muxUploadId: data.id,
          companyId: companyId,
          employeeId: employeeId,
        }),
      });
      onSuccses();
    });
  } catch (error) {
    console.error(error);
  }
};

export function getEmployeeMatches(token, jobFilter, status, distanceFilter, scopeFilter, typeFilter) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_EMPLOYEE_MATCH_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${URL.EMPLOYEE_MATCH}?roleIds=[${jobFilter}]&jobLocationIds=[${scopeFilter}]&jobEmploymentTypeIds=[${typeFilter}]&interview=${status}&distance=${distanceFilter}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          const employees = res.data.result;
          employees?.matches?.map(
            (match) => match?.company?.video !== null && match?.company?.companyFiles.unshift(match?.company?.video)
          );
          dispatch({ type: GET_EMPLOYEE_MATCH_SUCCESS, payload: employees });
          resolve(employees);
        })
        .catch((err) => {
          dispatch({ type: GET_EMPLOYEE_MATCH_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getCompanyMatches(
  token,
  companyId,
  jobFilter,
  statusFilter,
  experienceFilter,
  educationFilter,
  distanceFilter
) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };
  console.log(statusFilter);

  return async (dispatch) => {
    dispatch({ type: GET_COMPANY_MATCH_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${URL.COMPANY_MATCH}?companyId=${companyId}&roleIds=[${jobFilter}]&stateFilter=${
            statusFilter !== null ? `[${statusFilter}]` : null
          }&experienceIds=[${experienceFilter}]
            &educationIds=[${educationFilter}]&distance=${distanceFilter}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          dispatch({ type: GET_COMPANY_MATCH_SUCCESS, payload: res.data.result });
          resolve(res.data.result);
        })
        .catch((err) => {
          dispatch({ type: GET_COMPANY_MATCH_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getEmployeeMaybes(token, jobFilter, distanceFilter, scopeFilter, typeFilter) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_EMPLOYEE_MAYBE_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${URL.EMPLOYEE_MAYBE}?roleIds=[${jobFilter}]&jobLocationIds=[${scopeFilter}]&jobEmploymentTypeIds=[${typeFilter}]&distance=${distanceFilter}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          const employees = res.data.result;
          employees?.employeeJobLikes?.map(
            (like) =>
              like?.job?.company?.video !== null && like?.job?.company?.companyFiles.unshift(like?.job?.company?.video)
          );
          dispatch({ type: GET_EMPLOYEE_MAYBE_SUCCESS, payload: employees });
          resolve(employees);
        })
        .catch((err) => {
          dispatch({ type: GET_EMPLOYEE_MAYBE_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getCompanyMaybes(token, companyId, jobFilter, experienceFilter, educationFilter, distanceFilter) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_COMPANY_MAYBE_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${URL.COMPANY_MAYBE}?companyId=${companyId}&roleIds=[${jobFilter}]&experienceIds=[${experienceFilter}]
            &educationIds=[${educationFilter}]&distance=${distanceFilter}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          dispatch({ type: GET_COMPANY_MAYBE_SUCCESS, payload: res.data.result });
          resolve(res.data.result);
        })
        .catch((err) => {
          dispatch({ type: GET_COMPANY_MAYBE_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getList() {
  return async (dispatch) => {
    dispatch({ type: GET_LIST_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(URL.GET_LIST, {})
        .then((res) => {
          dispatch({ type: GET_LIST_SUCCESS, payload: res.data.result });
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: GET_LIST_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getLegals() {
  return async (dispatch) => {
    dispatch({ type: GET_LEGALS_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(URL.GET_LEGALS, {})
        .then((res) => {
          dispatch({ type: GET_LEGALS_SUCCESS, payload: res.data.result });
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: GET_LEGALS_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function validateToken(token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };
  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(URL.VALID_TOKEN, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function getInterviews(token, id) {
  const headers = {
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_INTERVIEWS_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.GET_INTERVIEWS}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: GET_INTERVIEWS_SUCCESS, payload: res.data.result });
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: GET_INTERVIEWS_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getEmployeeInterviews(token, id) {
  const headers = {
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_EMPLOYEE_INTERVIEWS_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.GET_EMPLOYEE_INTERVIEWS}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: GET_EMPLOYEE_INTERVIEWS_SUCCESS, payload: res.data.result });
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: GET_EMPLOYEE_INTERVIEWS_FAIL, error: err });
          reject(err);
        });
    });
  };
}

export function getComments(token, formData) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.GET_COMMENTS}`, formData, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function addComment(token, formData) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL.ADD_COMMENT}`, formData, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function getEmployeeDetails(id, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    dispatch({ type: GET_EMPLOYEE_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.GET_EMPLOYEE_DETAILS}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          dispatch({ type: GET_EMPLOYEE_SUCCESS, payload: res.data.result });
          resolve(res);
        })
        .catch((err) => {
          dispatch({ type: GET_EMPLOYEE_FAIL, payload: err });
          reject(err);
        });
    });
  };
}

export function getEmployeeDetailsCompany(id, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async (dispatch) => {
    // dispatch({ type: GET_EMPLOYEE_REQUEST });

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL.GET_EMPLOYEE_DETAILS_COMPANY}/${id}`, {
          headers: headers,
        })
        .then((res) => {
          // dispatch({ type: GET_EMPLOYEE_SUCCESS, payload: res.data.result });
          resolve(res);
        })
        .catch((err) => {
          // dispatch({ type: GET_EMPLOYEE_FAIL, payload: res.data.result });
          reject(err);
        });
    });
  };
}

export function updateUserDevice(body, token) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .put(URL.UPDATE_USER_DEVICE, body, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function addView(data, token) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(URL.ADD_VIEW, data, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

// export function createTeamMember(body, token, id) {

//     const headers = {
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer ' + token
//     }

//     return async () => {
//         return new Promise((resolve, reject) => {
//             axios.post(`${URL.CREATE_TEAM_MEMBER}/${id}`, body, {
//                 headers: headers
//             })
//                 .then((res) => {
//                     resolve(res)
//                 })
//                 .catch((err) => {
//                     reject(err)
//                 });
//         })
//     };

// }

export function updateTeamMember(userDetails, id, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.UPDATE_USER_DETAILS}/${id}`, userDetails, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
          console.log(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function deleteImagesById(ids, token) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return async () => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL.DELETE_IMAGES}`, ids, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
          console.log(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

const uploadFile = async (file, token, companyId) => {
  if (!file) {
    console.log('No file selected');
    return;
  }
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };

    const presignedPostUrlData = await axios.post(
      `${URL.FILE_SIGNED_UPLOAD_URL}`,
      [
        {
          companyId: companyId,
          fileName: file.name,
        },
      ],
      {
        headers: headers,
      }
    );
    const result = presignedPostUrlData.data.result[0];

    // Use the obtained pre-signed URL to upload the file directly to Azure Blob Storage
    await axios.put(result.result, file, {
      headers: {
        'x-ms-blob-type': 'BlockBlob', // Specify the blob type
        'Content-Type': file.type, // Set the Content-Type header based on the file type
      },
    });
    await setFileDetails(token, companyId, file.name, result.timestamp, file.isVideo);

    // console.log("File uploaded successfully");
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

/**
 *
 * @param {string} token
 * @param {number} companyId
 * @param {string} fileName
 * @param {number} timestamp
 */
const setFileDetails = async (token, companyId, fileName, timestamp, isVideo) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  await axios.post(
    `${URL.FILE_DETAILS}`,
    {
      companyId: companyId,
      isCompanyVideoFile: isVideo,
      fileName: fileName,
      timestamp,
    },
    {
      headers: headers,
    }
  );
};

export const uploadFiles = async (files, token, companyId = null, employeeId = null) => {
  for (const file of files) {
    await uploadFile(file, token, companyId);
  }

  return {
    success: true,
  };
};
