import React from 'react';

export default function TextArea({ style, value, onChange, label, security, className, maxLength }) {
    return (
        <textarea
            maxLength={maxLength}
            type={security ? "password" : "text"}
            style={style}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={className} placeholder={label} />
    );
}

