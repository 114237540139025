import React, { useCallback, useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { homeRoutesEmployee } from './routes'
import { Header, MobileHeader, MobileSideMenu, SideMenu } from '../components'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'

const HomeRouterEmployee = () => {


    const location = useLocation()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
    const currentUser = useSelector(state => state.userData)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        const { pathname } = location

        if (currentUser !== undefined && currentUser !== null) {
            if (currentUser?.type?.id === 3) {
                dispatch({ type: 'signOut' })
                navigate('/employee')
            }
            else {
                if (!currentUser) {
                    navigate('/employee-login', { state: { redirect: pathname } })
                }
                if (currentUser && (pathname === '/employee-login')) {
                    navigate(pathname)

                }
            }

        }
        else {
            navigate('/employee')
        }

    }, [])

    return (
        <React.Fragment>
            {isTabletOrMobile ? <MobileHeader /> : <Header />}
            <div style={{ position: 'relative' }} className='d-flex home-container'>
                {!isTabletOrMobile && <SideMenu type={'employee'} />}
                <Routes>
                    {homeRoutesEmployee.map((route) => (
                        <Route key={route.path} path={route.path} element={<route.component />} />
                    ))}
                </Routes>
            </div>
        </React.Fragment>
    )
}

export default HomeRouterEmployee
