import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getList } from '../store/actions';

const useFetchList = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.listDetails);

  useEffect(() => {
    if (list === null || list.length === 0) dispatch(getList());
  }, [dispatch, list]);
};

export default useFetchList;
