import React, { useState } from 'react';
import { icError, icEye, icIllustrations, icStepper, loginLogo } from '../../assets/images/images';
import { Button, Header, TextInput, Checkbox, TwoFactor, Dialog, MobileHeader, LoadingModal } from '../../components';
import { validateEmail, getOS } from '../../utils';
import { getEmployessGuest, getUserDetails, login, saveToFavCompany, updateUserDevice } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import useReCaptcha from '../../hooks/useReCaptcha';
import trim from 'validator/es/lib/trim';
import useFetchList from '../../hooks/useFetchList';

export default function CompanyLogin() {
  const currentUser = useSelector((state) => state.userData);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const height = window.innerHeight;
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validDetails, setValidDetails] = useState(false);
  const [twoFactorVisible, setTwoFactorVisible] = useState(false);
  const [verified, setVerified] = useState(false);
  const likes = useSelector((state) => state.likes);
  const maybes = useSelector((state) => state.maybes);
  const [uniq, setUniq] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useFetchList();

  const handleLoginCompany = () => {
    const formData = new FormData();
    formData.append('page', 1);
    formData.append('limit', 20);
    dispatch(getEmployessGuest(formData)).then((res) => {
      navigate('/company');
    });
  };
  const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha();

  const handleLogin = async () => {
    const valid = validateEmail(email);
    if (valid || email === '' || password === '') {
      setValidDetails(true);
    } else {
      setIsFetching(true);
      try {
        const reCaptchaToken = await generateReCaptchaToken('login');
        localStorage.setItem('reCaptchaToken', reCaptchaToken);
        const res = await dispatch(login(email, password, false, 3));
        if (res.status === 200) {
          setIsFetching(false);
          //const response = await axios.get('https://ifconfig.co/json');
          setUniq(res.data.result.uniq);
          const formData = new FormData();
          formData.append('os', getOS());
          formData.append('ip', null);
          formData.append('deviceId', '1');
          formData.append('pushToken', 'sometoken');
          setUserType(res.data.result?.userType);
          dispatch(updateUserDevice(formData, res.data.result?.accessToken));
          dispatch(getUserDetails(res.data.result?.id, res.data.result?.accessToken))
            .then((response) => {
              dispatch({ type: 'GET_EMPLOYEES_REQUEST' });
              if (likes.length > 0 || maybes.length > 0) {
                likes.forEach((like) => {
                  const formData = new FormData();
                  formData.append('companyId', response.data.result.companyTeams[0].company.id);
                  formData.append('employeeId', like.id);
                  formData.append('like', true);
                  formData.append('companyUserId', res.data.result?.id);

                  dispatch(saveToFavCompany(formData, res.data.result?.accessToken, true));
                });
                maybes.forEach((maybe) => {
                  const formData = new FormData();
                  formData.append('companyId', response.data.result.companyTeams[0].company.id);
                  formData.append('employeeId', maybe.id);
                  formData.append('like', true);
                  formData.append('companyUserId', res.data.result?.id);

                  dispatch(saveToFavCompany(formData, res.data.result?.accessToken, true));
                });
              }

              setVerified(true);
              const user = {
                ...response.data.result,
                token: res.data.result?.accessToken,
              };
              localStorage.setItem('userData', JSON.stringify(user));
              const candidateId = sessionStorage.getItem('candidateId');
              if (candidateId) {
                sessionStorage.removeItem('candidateId');
                navigate(`/company/employee-profile/${candidateId}`);
              }
            })
            .catch((err) => console.log(err));
        }
      } catch (err) {
        if (err.response && err.response.data.message === 'User disabled.') {
          setError('פרופיל חסום, פנה לנונה');
        } else {
          setError('אחד מהנתונים שהקלדת שגוי');
        }
        setIsFetching(false);
        setValidDetails(true);
      }
    }
  };

  return (
    <div>
      {isTabletOrMobile ? <MobileHeader type={'login'} /> : <Header />}
      <div style={{ position: 'relative' }} className='d-flex justify-content-start'>
        <img alt='' className='cover' style={{ height: `${height - 64}px` }} src={loginLogo} />

        <div className='login-screen'>
          {isTabletOrMobile && <img alt='' className='step' src={icStepper} />}
          <p style={{ fontSize: isTabletOrMobile ? 24 : 32, color: '#1F0039', fontWeight: 'bold' }}>התחברות מעסיק</p>
          <div className={`row justify-content-start`}>
            <TextInput
              className='text-input'
              value={email}
              onChange={(value) => {
                setEmail(trim(value));
                setValidDetails(false);
              }}
              label={'* אימייל'}
            />
            <div className='position-relative'>
              <TextInput
                on
                className='text-input'
                security={!showPass}
                value={password}
                onChange={(value) => {
                  setPassword(value);
                  setValidDetails(false);
                }}
                label={'* סיסמה'}
              />
              <div
                style={{ cursor: 'pointer', position: 'absolute', left: 20, top: 20 }}
                onClick={() => setShowPass(!showPass)}
              >
                <img src={icEye} alt='icEye' />
              </div>
            </div>
          </div>
          {validDetails && (
            <div
              style={{
                display: 'flex',
                marginTop: 10,
                fontWeight: '500',
                alignItems: 'center',
                textAlign: 'right',
                width: '100%',
                height: '41px',
                color: '#ff2525',
                border: '1px solid',
                borderColor: '#ff2525',
                borderRadius: 4,
                padding: '10px 12px',
              }}
            >
              <img alt='' src={icError} />
              <span style={{ paddingRight: 10 }}>{error}</span>
            </div>
          )}
          <p
            onClick={() => navigate('/forgot-password', { state: 'company' })}
            className='pass'
            style={{
              cursor: 'pointer',
              fontSize: 14,
              marginTop: 24,
              textDecoration: 'underline',
              color: '#36C0B0',
              fontWeight: 'bold',
            }}
          >
            שכחתי סיסמה
          </p>
          <div>
            <Checkbox checked={checked} onChange={() => setChecked(!checked)} label='זכור אותי' />
          </div>
          {/* <GoogleReCaptcha
                        className="google-recaptcha-custom-class"
                        onVerify={(getToken) => setTokenFunc(getToken)}
                        refreshReCaptcha={refreshReCaptcha}
                    /> */}
          <div
            style={{ marginTop: '40px', position: 'relative', marginRight: 0, marginBottom: 20 }}
            className='d-flex row'
          >
            <Button onPress={() => handleLogin()} text={'התחברות'} disabled={!reCaptchaLoaded} />
            <Button
              onPress={() => handleLoginCompany()}
              style={{
                marginTop: isTabletOrMobile ? 10 : 0,
                paddingRight: '2px',
                paddingLeft: '2px',
                backgroundColor: 'white',
                border: '1px solid #4f35be',
                color: '#4f35be',
                fontWeight: 'normal',
              }}
              text={'המשך כאורח '}
            />
          </div>
        </div>
      </div>
      {twoFactorVisible && (
        <>
          <div className='mask' />
          <TwoFactor
            setSuccess={() => {
              setTwoFactorVisible(false);
              setTimeout(() => {
                setVerified(true);
              }, 500);
            }}
            checked={checked}
            email={email}
            handleSend={() => handleLogin()}
            onClose={() => setTwoFactorVisible(false)}
            password={password}
            uniq={uniq}
          />
        </>
      )}
      {verified && (
        <Dialog
          image={icIllustrations}
          title={'החשבון אומת בהצלחה!'}
          firstButtonText={'אישור'}
          onClose={() => setVerified(false)}
          firstButtonHandler={() => {
            if (userType === 3) {
              navigate('/company');
              dispatch({ type: 'setCompany', payload: currentUser?.companyTeams[0] });
            } else navigate('/employee');
          }}
        />
      )}
      <LoadingModal visible={isFetching} />
    </div>
  );
}
