import React from 'react';
import { useSelector } from 'react-redux';
import { icDefault, icSideMenu } from '../assets/images/images';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import { useMediaQuery } from 'react-responsive';
const _ = require('lodash');

export default function SideBarGuest({ likes }) {

    const navigate = useNavigate()
    const list = useSelector(state => state.listDetails)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })

    const toolTip = list?.tooltip.filter(tip => tip.name === "company_guest_home_liked")[0]

    return (
        !isTabletOrMobile && 
        <div style={{ marginBottom: 20, }} className='side-bar'>
            <main>

                <img style={{ alignSelf: 'center' }} alt="menu" width={180} height={180} src={icSideMenu} />
                <span>
                    <b>
                        הרשם בכדי להעלות משרות, לצפות במועמדים מתאימים ויצירת התאמות.
                    </b>
                </span>
                <div style={{ width: 240, margin: '0 auto', marginTop: 30, marginBottom: 17 }} className='d-flex row justify-content-between'>
                    <Button onPress={() => window.open('https://nona-jobs.com/partners/')} style={{ paddingBottom:10, width: 110, height: 33, borderRadius: 4 }} text={'הרשמה'} />
                    <Button onPress={() => navigate('/company-login')} style={{ paddingBottom:10,  borderRadius: 4, height: 33, width: 110, backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} text={'התחברות'} />
                </div>

            </main>
            <main>
                <div style={{ height: 1, backgroundColor: '#d4daf8', width: '100%', }} />
                <div style={{ margin: '20px 20px 0px 20px', display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: 16, fontWeight: 'normal', marginBottom: 20, textAlign: 'right' }} >מועמדים שאהבת</span>
                    {_.takeRight(likes, 5).map(like =>
                        <div
                            data-tooltip-id="my-tooltip" data-tooltip-content={toolTip?.itemText}
                            className='candidate'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt="default" style={{objectFit:'cover', borderRadius: '50%' }} width="30" height="30" src={like?.user?.image?.url ?? icDefault} />
                                <div style={{ justifyContent: 'center', paddingRight: 14, height: '80%', flexDirection: 'column', display: 'flex' }}>
                                    <span style={{ padding: '0px 10px 0px 0px', fontWeight: 'normal', fontSize: 14, textAlign: 'right', }}>{`${like?.user?.firstName} ${like?.user?.lastName}`}</span>
                                    {like?.employeeJobRoleExperiences[0] &&
                                        <span style={{ padding: '0px 10px 0px 0px', textAlign: 'right', fontWeight: 'normal', color: '#646464', fontSize: 12 }}>{`${like?.employeeJobRoleExperiences[0]?.jobRole?.itemText}`}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </main>
            {toolTip?.isEnabled &&
                <Tooltip
                    place="top"
                    style={{
                        boxShadow: '0 4px 20px 0 rgba(74, 71, 113, 0.2)',
                        textAlign: 'right', whiteSpace: 'pre-wrap', backgroundColor: '#fff', opacity: 1, fontSize: 14, borderRadius: 8, color: '#1f0039'
                    }}
                    id="my-tooltip" />}
        </div>
    );

}
