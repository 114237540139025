import React from 'react';

export default function Card({ title, image, onPress, clicked, style, error }) {
  return (
    <div
      onClick={onPress}
      style={Object.assign(
        { cursor: 'pointer', border: `1px solid ${error ? 'red' : clicked ? '#5233D9' : 'transparent'}` },
        style
      )}
      className='menu-card'
    >
      <img alt='' src={image} />
      <p style={{ position: 'relative', top: 12, color: '#2f2072', textAlign: 'center' }}>
        {title}
      </p>
    </div>
  );
}
