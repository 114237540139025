import React, { useState } from 'react';
import { icError, icIllustrations, icStepper, loginLogo } from '../assets/images/images';
import { Button, Header, TextInput, Dialog, MobileHeader, LoadingModal } from '../components';
import { forgotPassword } from '../store/actions';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import trim from 'validator/es/lib/trim';
import isEmail from 'validator/es/lib/isEmail';

export default function ForgotPassword() {
  const location = useLocation();

  const { state } = location;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  // const isLandScape = useMediaQuery({ query: '(max-width: 915px)', orientation: 'landscape' })

  const height = window.innerHeight;
  const [email, setEmail] = useState(state.email ?? '');
  const [validEmail, setValidEmail] = useState(false);
  const [error, setError] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePassword = () => {
    let valid = !isEmail(email);

    setValidEmail(valid);

    if (valid === false && email.length !== 0) {
      setIsFetching(true);
      dispatch(forgotPassword(email))
        .then(async (res) => {
          if (res.status === 200) {
            setIsVisible(true);
            setIsFetching(false);
          }
        })
        .catch((err) => {
          setIsFetching(false);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
        });
    } else {
      setValidEmail(true);
      setTimeout(() => {
        setValidEmail(false);
      }, 1000);
    }
  };

  return (
    <div>
      {isTabletOrMobile ? <MobileHeader /> : <Header />}
      <div style={{ position: 'relative' }} className='d-flex justify-content-start'>
        <img alt='' className='cover' style={{ height: `${height - 64}px` }} src={loginLogo} />

        <div className='login-screen'>
          {isTabletOrMobile && <img alt='' className='step' src={icStepper} />}
          <p style={{ fontSize: isTabletOrMobile ? 24 : 32, color: '#1F0039', fontWeight: 'bold' }}>שכחתי סיסמה</p>
          <div className={`row justify-content-start`}>
            <TextInput
              style={{ border: validEmail ? '1px solid red' : '1px solid #D4DAF8' }}
              className='text-input'
              value={email}
              onChange={(value) => {
                setEmail(trim(value));
              }}
              label={'* אימייל'}
            />
          </div>
          {error && (
            <div
              style={{
                display: 'flex',
                marginTop: 10,
                fontWeight: '500',
                alignItems: 'center',
                textAlign: 'right',
                width: '100%',
                height: '41px',
                color: '#ff2525',
                border: '1px solid',
                borderColor: '#ff2525',
                borderRadius: 4,
                padding: '10px 12px',
              }}
            >
              <img alt='' src={icError} />
              <span style={{ paddingRight: 10 }}>האימייל לא קיים במערכת</span>
            </div>
          )}

          <div style={{ marginTop: '40px', position: 'relative', marginRight: 0 }} className='d-flex row'>
            <Button onPress={() => handlePassword()} text={'איפוס סיסמה'} />
            <Button
              onPress={() => {
                navigate(-1);
              }}
              style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }}
              text={'ביטול'}
            />
          </div>
        </div>
      </div>
      {isVisible && (
        <Dialog
          image={icIllustrations}
          title={'הסיסמה אופסה בהצלחה!'}
          text={'נשלח לתיבת הדואר שלך לינק להגדרת סיסמה חדשה'}
          firstButtonText={'אישור'}
          onClose={() => setIsVisible(false)}
          firstButtonHandler={() =>
            state.type === 'company' ? navigate('/company-login') : navigate('/employee-login')
          }
        />
      )}
      <LoadingModal visible={isFetching} />
    </div>
  );
}
