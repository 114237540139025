import React, { useState, useRef } from 'react';

const DateMask = ({ onChange, isError, date }) => {
  console.log('date', date);
  const tmpDate = date?.split('-');
  const [day, setDay] = useState(tmpDate !== undefined ? tmpDate[2] : '');
  const [month, setMonth] = useState(tmpDate !== undefined ? tmpDate[1] : '');
  const [year, setYear] = useState(tmpDate !== undefined ? tmpDate[0] : '');

  const dayRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  return (
    <div
      onBlur={() => onChange(day, month, year)}
      style={{ border: `1px solid ${isError ? 'red' : '#D4DAF8'}` }}
      className='date-mask'
    >
      <input
        dir='ltr'
        type='text'
        ref={dayRef}
        placeholder='dd'
        onChange={(e) => setDay(e.target.value)}
        maxLength='2'
        onKeyUp={() => {
          if (day.length === 2) {
            monthRef?.current.focus();
          }
        }}
        value={day}
      />
      <div className='line' />
      <input
        value={month}
        dir='ltr'
        type='text'
        placeholder='mm'
        ref={monthRef}
        onChange={(e) => setMonth(e.target.value)}
        maxLength='2'
        onKeyUp={() => {
          if (month.length === 2) {
            yearRef?.current.focus();
          }
        }}
      />
      <div className='line' />
      <input
        value={year}
        style={{ width: '50%', borderRight: 0 }}
        dir='ltr'
        type='text'
        placeholder='yyyy'
        ref={yearRef}
        onKeyUp={() => {
          if (
            year.length === 4 &&
            (day.length === 2 || day.legnth === 1) &&
            (month.length === 2 || month.length === 1)
          ) {
            onChange(day, month, year);
          }
        }}
        onChange={(e) => setYear(e.target.value)}
        maxLength='4'
      />
    </div>
  );
};

export default DateMask;
