import React, { useEffect, useState } from 'react';
import { icError, icEye, icIllustrations, icStepper, loginLogo } from '../assets/images/images';
import { Button, Header, TextInput, Dialog, MobileHeader, LoadingModal } from '../components';
import { resetPass, validateToken } from '../store/actions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { checkStrongPassword } from '../utils/validator';
import PwdConstrains from '../components/PwdConstrains';


export default function ResetPassword() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
    // const isLandScape = useMediaQuery({ query: '(max-width: 915px)', orientation: 'landscape' })
    let { userId } = useParams();
    const height = window.innerHeight;
    const [queryParameters] = useSearchParams()

    const [password, setPassword] = useState("")
    const [validatePassword, setValidatePassword] = useState("")

    const [isFetching, setIsFetching] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    const [id] = useState(userId ?? null)
    const [validPass, setValidPass] = useState(false)
    const [validLink, setValidLink] = useState(false)
    const [type, setType] = useState(null)
    const [showPass, setShowPass] = useState(false)
    const [showPassValid, setShowPassValid] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const linkToken = queryParameters.get("resetPasswordToken")
        dispatch(validateToken(linkToken)).catch(err => {
            setValidLink(true)
        })
    }, [dispatch, queryParameters])

    const handlePassword = () => {

        if (!checkStrongPassword(password) || (password !== validatePassword) || (password === "" && validatePassword === "")) {
            setValidPass(true)
            setTimeout(() => {
                setValidPass(false)
            }, 5000)
        }
        else {
            // setIsFetching(true)
            const formData = new FormData()
            formData.append('userId', id)
            formData.append('newPassword', password)
            formData.append('token', queryParameters.get("resetPasswordToken"))

            dispatch(resetPass(formData)).then(async res => {
                if (res.status === 200) {
                    setType(res.data.result.type.id);
                    setIsVisible(true)
                    setIsFetching(false)
                }
            }).catch(err => {
                setValidLink(true)
                setTimeout(() => {
                    setValidLink(false)
                }, 5000)
                setIsFetching(false)
            })
        }
    }

    return (
        <div>
            {isTabletOrMobile ? <MobileHeader /> : <Header />}
            <div style={{ position: 'relative', }} className='d-flex justify-content-start'>
                <img alt="" className='cover' style={{ height: `${height - 64}px` }} src={loginLogo} />

                <div className='login-screen'>
                    {isTabletOrMobile && <img alt="" className='step' src={icStepper} />}
                    <p style={{ fontSize: isTabletOrMobile ? 24 : 32, color: '#1F0039', fontWeight: 'bold' }}>איפוס סיסמה</p>
                    <PwdConstrains/>
                    {validLink ?
                        < div
                            style={{ display: 'flex', marginTop: 10, fontWeight: '500', alignItems: 'center', textAlign: 'right', height: '41px', color: '#ff2525', border: '1px solid', borderColor: '#ff2525', borderRadius: 4, padding: '10px 12px' }}>
                            <img alt="" src={icError} />
                            <span style={{ paddingRight: 10 }}>הקישור פג תוקף, אנא בצע איפוס סיסמה מחדש.</span>
                        </div>
                        :
                        <>
                            <div className={`mt-3 row justify-content-start position-relative`}>
                                <div className={`position-relative`}>
                                    <TextInput style={{ border: validPass ? '1px solid red' : '1px solid #D4DAF8' }} className="text-input" security={!showPass} value={password} onChange={setPassword} label={'* סיסמה'} />
                                    <div style={{ cursor: "pointer", position: 'absolute', left: 20, top: 20 }} onClick={() => setShowPass(!showPass)}>
                                        <img src={icEye} />
                                    </div>
                                </div>
                                <div className={`position-relative`}>
                                    <TextInput style={{ border: validPass ? '1px solid red' : '1px solid #D4DAF8' }} className="text-input" security={!showPassValid} value={validatePassword} onChange={setValidatePassword} label={'* אימות סיסמה'} />
                                    <div style={{ cursor: "pointer", position: 'absolute', left: 20, top: 20 }} onClick={() => setShowPassValid(!showPassValid)}>
                                        <img src={icEye} />
                                    </div>
                                </div>
                            </div>
                            {validPass && <div
                                style={{ display: 'flex', marginTop: 20, fontWeight: '500', alignItems: 'center', textAlign: 'right', color: '#ff2525', border: '1px solid', borderColor: '#ff2525', borderRadius: 4, padding: '10px 12px' }}>
                                <img alt="error" src={icError} />
                                <span style={{ paddingRight: 10, fontSize: 14 }}>הסיסמה חייבת להיות באורך של לפחות 8 תווים, עם לפחות אות אחת גדולה, אות אחת קטנה, וספרה אחת</span>
                            </div>}

                            <div style={{ marginTop: '40px', position: 'relative', marginRight: 0 }} className='d-flex row'>
                                <Button onPress={() => handlePassword()} text={'איפוס סיסמה'} />
                                <Button onPress={() => { navigate(-1) }} style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }} text={'ביטול'} />
                            </div>

                        </>}
                </div>

            </div>
            {
                isVisible &&
                <Dialog
                    image={icIllustrations}
                    title={'הסיסמה אופסה בהצלחה!'}
                    text={''}
                    firstButtonText={'אישור'}
                    onClose={() => setIsVisible(false)}
                    firstButtonHandler={() => type === 3 ? navigate('/company-login') : navigate('/employee-login')}
                />
            }
            <LoadingModal visible={isFetching} />
        </div >
    );

}
