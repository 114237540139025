import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { icArrowDown, icDefault, icErrorContract, icFilter, icRadio, icRadioColor, icSearch } from '../assets/images/images';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import Autocomplete from 'react-autocomplete';
import { MenuItem, Select } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
const _ = require('lodash');

export default function SideBarUser({ likes, activeJobs, handleJobFilter, handleFilterCity, handleFilterDistance }) {

    const navigate = useNavigate()
    const company = useSelector(state => state.company)
    const list = useSelector(state => state.listDetails)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' })
 
    const [filterBy, setFilterBy] = useState(false)
    const [cityList, setCityList] = useState([])
    const [distanceList, setDistanceList] = useState([])
    const [city, setCity] = useState({ id: null, label: "" })
    const [distance, setDistance] = useState({ id: null, label: "" })

    const [openFilter, setOpenFilter] = useState(false)
    const [chosenJob, setChosenJob] = useState(null)
    const [jobRole, setJobRole] = useState({ id: null, label: "" })
    const initialLoad = useRef(true);

    const jobs = useMemo(() => activeJobs?.map(item => ({
        id: item?.id,
        label: item.jobRolesJob[0]?.jobRole?.itemText ?? ""
    })), [activeJobs]);

    useEffect(() => {
        const tmpDistanceList = [];
        const tmpList = list?.distanceScore?.sort((a, b) => a.minDistance - b.minDistance);
        tmpList?.forEach(item => tmpDistanceList.push({ id: item.id, label: `${item.minDistance} - ${item.maxDistance} ק״מ` }));
        setDistanceList(tmpDistanceList);

        const tmpCity = [];
        list?.city?.forEach(city => tmpCity.push({ id: city.id, label: city.itemTextHeb }));
        setCityList(tmpCity);
    }, [list]);

    useEffect(() => {
        if (initialLoad.current && jobs.length > 0) {
            setJobRole(jobs[0]);
            handleJobFilter(jobs[0]);
            initialLoad.current = false;
        }
    }, [jobs, handleJobFilter]);





    return (
        isTabletOrMobile ?
            company?.company?.status.id !== 3 ?
                null
                :
                <>
                    <div style={{ width: '90%', alignSelf: 'center', }} className='d-flex align-items-center position-relative mt-2'>

                        <Autocomplete
                            inputProps={{
                                style: {
                                    border: `1px solid '#D4DAF8`,
                                    width: '80vw',
                                    height: '44px',
                                    marginLeft: 5,
                                    marginTop: 0,

                                },
                                className: 'auto-complete icon', placeholder: 'חפש לפי תפקיד'

                            }}
                            value={jobRole?.label}
                            items={jobs}
                            shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
                            wrapperStyle={{ position: 'relative' }}
                            getItemValue={(item) => item.label}
                            onSelect={(value, item) => {
                                handleJobFilter(item);
                                setJobRole(item)
                            }}
                            onChange={(event, value) => {
                                if (value.length === 0) {
                                    handleJobFilter(jobs.map(item => { return { id: item.id, label: item.label } }))
                                }
                                setJobRole({ id: null, label: value })
                            }}

                            // onSelect={(value, item) => {
                            //     handleSelect(item);
                            // }}
                            // onChange={(event, value) => {
                            //     setJobRole({ id: null, label: value })
                            //     if (value.length == 0) {
                            //         handleJobFilter(jobs.map(item => { return { id: item.id, label: item.label } }))
                            //     }
                            // }}
                            renderMenu={(items) => (
                                <div style={{ width: '100%' }} className="menu">
                                    {items}
                                </div>
                            )}
                            renderItem={(item) => (
                                <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}
                                </p>

                            )}
                            renderInput={(props) => (
                                <>
                                    {jobRole?.label === "" && <img alt="" style={{ zIndex: 1, position: 'absolute', top: 14, right: 12 }} src={icSearch} />}
                                    <input {...props} />
                                </>
                            )}
                        />
                        <img alt="icon" style={{ cursor: 'pointer', }} onClick={() => setOpenFilter(!openFilter)} src={icFilter} />
                        {openFilter &&
                            <div className="filters">
                                <div style={{ position: 'relative' }}>
                                    <div className="triangle" />
                                    <div className="rectangle">
                                        <span>פילטור נוסף</span>
                                        <div className='d-flex justify-content-between'>
                                            <div style={{ cursor: 'pointer', }} onClick={() => setFilterBy(false)}>
                                                <img alt="icon" src={filterBy ? icRadio : icRadioColor} />
                                                <span className='pr-1'>חיפוש לפי עיר</span>
                                            </div>
                                            <div style={{ cursor: 'pointer', }} onClick={() => setFilterBy(true)}>
                                                <img alt="icon" src={!filterBy ? icRadio : icRadioColor} />
                                                <span className='pr-1'>חיפוש לפי מרחק</span>
                                            </div>
                                        </div>
                                        {!filterBy ?
                                            <Autocomplete
                                                inputProps={{

                                                    style: {
                                                        width: '100%',
                                                        marginRight: 0,
                                                        border: `1px solid #D4DAF8`,
                                                        height: '40px',
                                                        marginLeft: 16,
                                                    },
                                                    className: 'auto-complete', placeholder: 'עיר',
                                                }}
                                                value={city.label}
                                                items={cityList}
                                                shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
                                                getItemValue={(item) => item.label}
                                                wrapperStyle={{ position: 'relative', }}

                                                onSelect={(value, item) => {
                                                    setCity(item)
                                                    handleFilterCity(item.id)
                                                }}
                                                onChange={(event, value) => {
                                                    setCity({ id: null, label: value })
                                                    if (value.length === 0) {
                                                        handleFilterCity(null)
                                                    }
                                                }}
                                                renderMenu={(items, value, style) => (

                                                    <div style={{ width: '100%' }} className="menu">
                                                        {items}
                                                    </div>
                                                )}
                                                renderItem={(item, isHighlighted) => (
                                                    <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}</p>

                                                )}
                                            /> :
                                            <Select
                                                inputProps={{
                                                    IconComponent: () => null,
                                                    sx: { padding: '0 !important' }
                                                }
                                                }
                                                renderValue={(value) => {
                                                    if (distance.label === "") {
                                                        return <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                                                            <span style={{ textAlign: 'right', fontWeight: 'normal' }}>באיזה טווח נחפש לך משרות?</span>
                                                            <img alt="arrow" src={icArrowDown} />
                                                        </div>

                                                    }

                                                    return (
                                                        <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                                                            <span style={{ textAlign: 'right', fontWeight: 'normal' }}>{value.label}</span>
                                                            <img alt="arrow" src={icArrowDown} />
                                                        </div>
                                                    );
                                                }}

                                                displayEmpty
                                                value={distance.label === "" ? "" : distance}
                                                variant='outlined'
                                                onChange={({ target }) => {
                                                    if (target.value.id === null)
                                                        setDistance({ id: null, label: target.value.label })
                                                    else
                                                        setDistance(target.value);
                                                    handleFilterDistance(target.value.id)
                                                }}
                                                MenuProps={{

                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    },
                                                    PaperProps: {
                                                        sx: {
                                                            "&& .Mui-selected:focus": {
                                                                backgroundColor: "#f8f8ff"
                                                            },
                                                            "&& .Mui-selected:hover": {
                                                                backgroundColor: "#f8f8ff"
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: '#f8f8ff',
                                                            },
                                                            "& .MuiMenuItem-root:focus": {
                                                                backgroundColor: '#ffff',
                                                            },

                                                        },
                                                    },
                                                }}
                                                sx={{
                                                    marginTop: 1.3,
                                                    fontSize: 16,
                                                    fontFamily: 'Heebo',
                                                    color: '#1f0039',
                                                    height: '40px',
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    border: `1px solid #D4DAF8`,
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '0px',
                                                        textAlign: 'right'
                                                    },

                                                }}
                                            >
                                                {[...distanceList, { id: null, label: 'ללא הגבלה' }].map(item => {
                                                    return (
                                                        <MenuItem
                                                            sx={{
                                                                fontSize: 16,
                                                                fontFamily: 'Heebo',
                                                                color: '#1f0039',
                                                                direction: 'rtl'
                                                            }}
                                                            value={item}>
                                                            <span>{item.label}</span>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>}
                                    </div>
                                </div>
                            </div>}

                    </div>
                    <div style={{ overflowY: 'scroll', padding: 24, gap: 7 }} className='d-flex flex-row'>
                        {jobs?.slice(0, 5).map((item) => (
                            <div
                                key={item.id} style={{ cursor: 'pointer', border: jobRole.id === item.id ? 'solid 1px #4f35be' : '0px' }} className="filter-tag" onClick={() => {
                                    if (jobRole.id === item.id) {
                                        setJobRole({ id: null, label: "" })
                                        handleJobFilter(jobs.map(item => { return { id: item.id, label: item.label } }))

                                    }
                                    else {
                                        handleJobFilter(item);
                                        setJobRole(item)
                                    }

                                }}>
                                {item.label}
                            </div>
                        ))}
                    </div>
                </>
            :
            <div style={{ marginBottom: 20, }} className='side-bar-user'>
                {company?.company?.status.id === 4 ? <main>
                    <span>לצערנו החוזה שנשלח אלינו <b>לא אושר</b>, ונשלח אליך מייל עם פירוט הבעיה. יש לחתום על החוזה בשנית ולשלוח אלינו.</span>
                    <div style={{ fontSize: 14, textAlign: 'right', marginTop: 16 }}>
                        לא קיבלת?&nbsp;
                        <span onClick={() => { }} style={{ cursor: 'pointer', color: '#36c0b0', fontWeight: 500, textDecoration: 'underline' }}>שלח שוב</span>
                    </div>
                    <div style={{ fontSize: 14, textAlign: 'right', marginTop: 16 }}>
                        נתקלת בבעיה?&nbsp;
                        <span style={{ color: '#36c0b0', fontWeight: 500, textDecoration: 'underline' }}>צור קשר</span>
                    </div>
                    <Button style={{ marginTop: 13 }} onPress={() => { navigate('/company/create-job') }} text={'צור משרה חדשה +'} />
                    <div style={{ marginTop: 20, height: 1, backgroundColor: '#d4daf8', width: '100%', }} />

                    {activeJobs?.slice(0, 6).map((item, index) =>
                        <div
                            style={{
                                marginTop: 20, border: `1px solid ${chosenJob === item.id ? '#4f35be' : 'transparent'}`
                            }}
                            className='tag decline-contract'>
                            <div
                                style={{ pointerEvents: company?.company?.status.id === 4 ? 'none' : 'all', }}
                                onClick={() => { handleJobFilter(item); setChosenJob(item.id) }}

                            >
                                {item.jobRolesJob[0]?.jobRole.itemText}
                            </div>
                            <img alt="icon"
                                
                                style={{ paddingRight: 12 }} src={icErrorContract} />
                        </div>
                    )}
                </main>
                    : company?.company?.status.id !== 3 ?
                        <main>
                            <span><b>יש לחתום על החוזה בכדי לפרסם משרות, לצפות במועמדים מתאימים וליצור התאמות.</b></span>
                            <div style={{ fontSize: 14, textAlign: 'right', marginTop: 16 }}>
                                לא קיבלת?&nbsp;
                                <span onClick={() => { }} style={{ cursor: 'pointer', color: '#36c0b0', fontWeight: 500, textDecoration: 'underline' }}>שלח שוב</span>
                            </div>
                            <div style={{ fontSize: 14, textAlign: 'right', marginTop: 16 }}>
                                נתקלת בבעיה?&nbsp;
                                <span style={{ color: '#36c0b0', fontWeight: 500, textDecoration: 'underline' }}>צור קשר</span>
                            </div>
                            <Button style={{ marginTop: 13 }} onPress={() => { navigate('/company/create-job') }} text={'צור משרה חדשה +'} />
                            <div style={{ marginTop: 20, height: 1, backgroundColor: '#d4daf8', width: '100%', }} />

                            {activeJobs?.slice(0, 6).map((item, index) =>
                                <div
                                    onClick={() => { handleJobFilter(item); setChosenJob(item.id) }}
                                    style={{ marginTop: 20, border: `1px solid ${chosenJob === item.id ? '#4f35be' : 'transparent'}` }}
                                    className='tag'>
                                    {item.jobRolesJob[0]?.jobRole.itemText}

                                </div>
                            )}
                        </main>
                        :
                        <main>
                            <Button style={{ marginTop: 13 }} onPress={() => { navigate('/company/create-job') }} text={'צור משרה חדשה +'} />
                            <div style={{ marginTop: 20, height: 1, backgroundColor: '#d4daf8', width: '100%', }} />
                            <span style={{ fontSize: 18, marginTop: 10 }}>הצג מועמדים לפי המשרה:</span>

                            <div className='d-flex align-items-center position-relative mt-2'>

                                <Autocomplete
                                    inputProps={{
                                        style: {
                                            border: `1px solid '#D4DAF8`,
                                            width: '100%',
                                            height: '44px',
                                            marginTop: 0,

                                        },
                                        className: 'auto-complete icon', placeholder: 'שם משרה'

                                    }}
                                    value={jobRole?.label}
                                    items={jobs ?? []}
                                    shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
                                    wrapperStyle={{ position: 'relative' }}
                                    getItemValue={(item) => item.label}
                                    onSelect={(value, item) => {
                                        handleJobFilter(item);
                                        setJobRole(item)
                                    }}
                                    onChange={(event, value) => {
                                        if (value.length === 0) {
                                            handleJobFilter(jobs.map(item => { return { id: item.id, label: item.label } }))
                                        }
                                        setJobRole({ id: null, label: value })
                                    }}
                                    renderMenu={(items) => (
                                        <div style={{ width: '100%' }} className="menu">
                                            {items}
                                        </div>
                                    )}
                                    renderItem={(item) => (
                                        <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}
                                        </p>

                                    )}
                                    renderInput={(props) => (
                                        <>
                                            {jobRole?.label === "" && <img alt="" style={{ zIndex: 1, position: 'absolute', top: 14, right: 12 }} src={icSearch} />}
                                            <input {...props} />
                                        </>
                                    )}
                                />
                                <img alt="icon" style={{ cursor: 'pointer', }} onClick={() => setOpenFilter(!openFilter)} src={icFilter} />
                                {openFilter && <div className="filters">
                                    <div className="triangle" />
                                    <div className="rectangle">
                                        <span>פילטור נוסף</span>
                                        <div style={{ cursor: 'pointer', }} className='d-flex justify-content-between'>
                                            <div onClick={() => setFilterBy(false)}>
                                                <img alt="icon" src={filterBy ? icRadio : icRadioColor} />
                                                <span className='pr-1'>חיפוש לפי עיר</span>
                                            </div>
                                            <div style={{ cursor: 'pointer', }} onClick={() => setFilterBy(true)}>
                                                <img alt="icon" src={!filterBy ? icRadio : icRadioColor} />
                                                <span className='pr-1'>חיפוש לפי מרחק</span>
                                            </div>
                                        </div>
                                        {!filterBy ?
                                            <Autocomplete
                                                inputProps={{

                                                    style: {
                                                        width: '100%',
                                                        marginRight: 0,
                                                        border: `1px solid #D4DAF8`,
                                                        height: '40px',
                                                        marginLeft: 16,
                                                    },
                                                    className: 'auto-complete', placeholder: 'עיר',
                                                }}
                                                value={city.label}
                                                items={cityList}
                                                shouldItemRender={(item, value) => item.label.toLowerCase().startsWith(value.toLowerCase())}
                                                getItemValue={(item) => item.label}
                                                wrapperStyle={{ position: 'relative', }}

                                                onSelect={(value, item) => {
                                                    setCity(item)
                                                    handleFilterCity(item.id)
                                                }}
                                                onChange={(event, value) => {
                                                    setCity({ id: null, label: value })
                                                    if (value.length === 0) {
                                                        handleFilterCity(null)
                                                    }
                                                }}
                                                renderMenu={(items, value, style) => (

                                                    <div style={{ width: '100%' }} className="menu">
                                                        {items}
                                                    </div>
                                                )}
                                                renderItem={(item, isHighlighted) => (
                                                    <p style={{ cursor: 'pointer', color: '#2f2072' }}>{item.label}</p>

                                                )}
                                            /> :
                                            <Select
                                                inputProps={{
                                                    IconComponent: () => null,
                                                    sx: { padding: '0 !important' }
                                                }
                                                }
                                                renderValue={(value) => {
                                                    console.log(value);
                                                    if (distance.label === "") {
                                                        return <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                                                            <span style={{ textAlign: 'right', fontWeight: 'normal' }}>באיזה טווח נחפש לך משרות?</span>
                                                            <img alt="arrow" src={icArrowDown} />
                                                        </div>

                                                    }

                                                    return (
                                                        <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                                                            <span style={{ textAlign: 'right', fontWeight: 'normal' }}>{value.label}</span>
                                                            <img alt="arrow" src={icArrowDown} />
                                                        </div>
                                                    );
                                                }}

                                                displayEmpty
                                                value={distance.label === "" ? "" : distance}
                                                variant='outlined'
                                                onChange={({ target }) => {
                                                    if (target.value.id === null)
                                                        setDistance({ id: null, label: target.value.label })
                                                    else
                                                        setDistance(target.value);
                                                    handleFilterDistance(target.value.id)
                                                }}
                                                MenuProps={{

                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    },
                                                    PaperProps: {
                                                        sx: {
                                                            "&& .Mui-selected:focus": {
                                                                backgroundColor: "#f8f8ff"
                                                            },
                                                            "&& .Mui-selected:hover": {
                                                                backgroundColor: "#f8f8ff"
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: '#f8f8ff',
                                                            },
                                                            "& .MuiMenuItem-root:focus": {
                                                                backgroundColor: '#ffff',
                                                            },

                                                        },
                                                    },
                                                }}
                                                sx={{
                                                    marginTop: 1.3,
                                                    fontSize: 16,
                                                    fontFamily: 'Heebo',
                                                    color: '#1f0039',
                                                    height: '40px',
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    border: `1px solid #D4DAF8`,
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '0px',
                                                        textAlign: 'right'
                                                    },

                                                }}
                                            >
                                                {[...distanceList, { id: null, label: 'ללא הגבלה' }].map(item => {
                                                    return (
                                                        <MenuItem
                                                            sx={{
                                                                fontSize: 16,
                                                                fontFamily: 'Heebo',
                                                                color: '#1f0039',
                                                                direction: 'rtl'
                                                            }}
                                                            value={item}>
                                                            <span>{item.label}</span>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>}

                                    </div>
                                </div>}

                            </div>
                            <div style={{ marginRight: 0, gap: 12, marginTop: 24 }} className='d-flex flex-column'>
                                {jobs?.slice(0, 5).map((item) => (
                                    <div
                                        key={item.id} style={{ cursor: 'pointer', border: jobRole.id === item.id ? 'solid 1px #4f35be' : '0px' }} className="filter-tag" onClick={() => {
                                            if (jobRole.id === item.id) {
                                                setJobRole({ id: null, label: "" })
                                                handleJobFilter(jobs.map(item => { return { id: item.id, label: item.label } }))

                                            }
                                            else {
                                                handleJobFilter(item);
                                                setJobRole(item)
                                            }

                                        }}>
                                        {item.label}
                                    </div>
                                ))}
                            </div>
                        </main>
                }
                <main>
                    <div style={{ height: 1, backgroundColor: '#d4daf8', width: '100%', }} />
                    <div style={{ margin: '20px 20px 0px 20px', display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 16, fontWeight: 'normal', marginBottom: 20, textAlign: 'right' }} >מועמדים שאהבת</span>
                        {_.takeRight(likes, 5).map(like =>
                            <div
            
                                className='candidate'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img alt="default" style={{ objectFit: 'cover', borderRadius: '50%' }} width="30" height="30" src={like?.user?.image?.url ?? icDefault} />
                                    <div style={{ justifyContent: 'center', paddingRight: 14, height: '80%', flexDirection: 'column', display: 'flex' }}>
                                        <span style={{ padding: '0px 10px 0px 0px', fontWeight: 'normal', fontSize: 14, textAlign: 'right', }}>{`${like?.user?.firstName} ${like?.user?.lastName}`}</span>
                                        {like?.employeeJobRoleExperiences[0] &&
                                            <span style={{ padding: '0px 10px 0px 0px', textAlign: 'right', fontWeight: 'normal', color: '#646464', fontSize: 12 }}>{`${like?.employeeJobRoleExperiences[0]?.jobRole?.itemText}`}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </main>
        
            </div >
    );

}
