import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducer';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'
import createSagaMiddleware from 'redux-saga';
import { initialState } from './reducer';

export const sagaMiddleware = createSagaMiddleware()
const persistConfig = {
  key: 'nona',
  storage: sessionStorage,
  blacklist: ['employeeVideos', 'employees', 'jobs', 'isDrawerOpen']

}

const middleware = [thunk];
const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(
  persistedReducer,
  initialState,
  compose(applyMiddleware(...middleware)),
);

export default store;
